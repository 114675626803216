import React from "react";
import styled from "@emotion/styled";
import {
  Typography,
  Card as MuiCard,
  CardActionArea,
  List,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
  CardContent,
  IconButton,
  InputBase,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { fetchAllcompanyAttributes } from "../../../../../redux/slices/conversationalActions";
const Card = styled(MuiCard)(spacing);
const ListItemButton = styled(MuiListItemButton)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);

const Search = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e7f2ff;
`;

function createData(
  srno,
  id,
  display_name,
  key,
  display_type,
  mode,
  description
) {
  return { srno, id, display_name, key, display_type, mode, description };
}
var rows = [];
var rowsData = [];

const CompanyAttributes = ({ socket }) => {
  const [search, setSearch] = React.useState("");
  const { conversationId, botId, orgId, channelId, contactId, contactData } =
    useSelector((state) => state.chatSettings);
  const dispatch = useDispatch();
  const { companyAttribute } = useSelector(
    (state) => state.conversationalAction
  );

  React.useEffect(() => {
    dispatch(fetchAllcompanyAttributes());
  }, [dispatch]);

  var [agent, setAgent] = React.useState("");
  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
  }, []);

  rowsData =
    !!companyAttribute && !!companyAttribute.data
      ? companyAttribute.data.map((item, i) =>
          createData(
            ++i,
            item._id,
            item.display_name,
            item.key,
            item.display_type,
            item.mode,
            item.description
          )
        )
      : [];

  rows =
    search === ""
      ? rowsData
      : rowsData.filter(
          (i) =>
            i.display_name.toLowerCase().includes(search.toLowerCase()) ||
            i.key.toLowerCase().includes(search.toLowerCase())
        );

  const sendCompanyAttributeMessage = async (description) => {
    var msg = `${description}`;

    var messagePayload = {
      data: {
        flag: "note",
        channel_id: channelId,
        bot_id: botId,
        org_id: orgId,
        account_id: localStorage.getItem("atua"),
        conversation_id: conversationId,
        agent_id: agent.uid,
        agentName: agent.displayname,
        assign_id: "",
        contact_id: contactId,
        contact: contactData,
        label_id: "",
        message: [
          {
            content_type: 0,
            content: msg,
            media: {
              media_type: "",
              media_path: "",
            },
            message_type: "text",
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };

    socket.current.emit("chatSentFromApp", messagePayload);
  };

  return (
    <>
      <Card mb={6}>
        <CardActionArea>
          <CardContent>
            <Search>
              <IconButton type="button" sx={{ p: "4px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Search>
            <List component="nav" dense={true}>
              {!!rows && !!rows.length > 0 ? (
                rows.map((item, i) => (
                  <ListItemButton
                    key={i}
                    onClick={() => {
                      sendCompanyAttributeMessage(item.description);
                    }}
                  >
                    <ListItemText primary={item.display_name} />
                  </ListItemButton>
                ))
              ) : (
                <Typography>Company attributes are not found.</Typography>
              )}
            </List>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default CompanyAttributes;
