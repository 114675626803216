/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./ZoomableImage.css";
const ImageZoom = ({ src, zoom }) => {
  return (
    <div className="zoomable-image">
      <div className="image-container" style={{ transform: `scale(${zoom})` }}>
        <img src={src} alt="Zoomable" />
      </div>
    </div>
  );
};

export default ImageZoom;
