import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  FormControl as MuiFormControl,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Toolbar,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { settingsMenu } from "../../../../../redux/slices/settings";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  editCompanyAttributes,
  reset,
} from "../../../../../redux/slices/companyAttributes";
import { isValidToken } from "../../../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../../redux/slices/login";
import { useNavigate } from "react-router-dom";
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Card = styled(MuiCard)(spacing);
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Ptypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
`;

const Spacer = styled.div`
  flex: 1 1 20%;
`;
const timeOut = (time) => new Promise((res) => setTimeout(res, time));

var initialValues = {
  id: "",
  display_name: "",
  key: "",
  description: "",
  display_type: 0,
};

const validationSchema = Yup.object().shape({
  display_name: Yup.string().max(20).required("Required"),
  key: Yup.string().max(10).required("Required"),
  description: Yup.string().required("Required"),
});
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const EditCompanyAttribute = ({ dispatch, data }) => {
  const { addData } = useSelector((state) => state.companyAttributes);
  const [displayType, setDisplayType] = React.useState(0);
  const accessToken = window.localStorage.getItem("accessToken");
  const navigate = useNavigate();
  initialValues = {
    display_name: data.display_name,
    key: data.key,
    description: data.description,
  };
  React.useEffect(() => {
    setDisplayType(data.display_type);
  }, [data.display_type]);
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    var payload = {
      id: data.id,
      display_name: values.display_name,
      key: values.key,
      display_type: displayType,
      mode: 0,
      description: values.description,
    };

    try {
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      } else {
        await timeOut(1500);
        await dispatch(editCompanyAttributes(payload));
        //resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
        await timeOut(1500);
        dispatch(
          settingsMenu({
            list: "companyattributes",
            listData: [],
          })
        );
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleBack = (row) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(
        settingsMenu({
          list: "companyattributes",
          listData: [],
        })
      );
    }
  };
  return (
    <>
      <div>
        <Paper>
          <Toolbar>
            <div>
              <Typography variant="h6" id="tableTitle">
                Edit Company Attributes
              </Typography>
            </div>
            <Spacer />
            <div>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                style={{ marginRight: "5px" }}
                // onClick={() =>
                //   dispatch(
                //     settingsMenu({
                //       list: "companyattributes",
                //       listData: [],
                //     })
                //   )
                // }

                onClick={() => {
                  handleBack();
                }}
              >
                <NavigateBeforeIcon />
                Back
              </Button>
            </div>
          </Toolbar>
        </Paper>
      </div>
      <Divider my={1} />
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {errors && errors.sent && (
                  <Alert severity="error" my={3}>
                    {errors.message}
                  </Alert>
                )}
                {!!addData &&
                  (!!addData.status && parseInt(addData.status) !== 201 ? (
                    <Alert severity="error" my={3}>
                      {!!addData && addData.message}
                    </Alert>
                  ) : (
                    status &&
                    status.sent && (
                      <Alert severity="success" my={3}>
                        Your data has been submitted successfully!
                      </Alert>
                    )
                  ))}
                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      name="display_name"
                      label="Display Name"
                      value={values.display_name}
                      error={Boolean(
                        touched.display_name && errors.display_name
                      )}
                      fullWidth
                      helperText={touched.display_name && errors.display_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                      my={2}
                    />
                    <TextField
                      name="key"
                      label="Key"
                      value={values.key}
                      error={Boolean(touched.key && errors.key)}
                      fullWidth
                      helperText={touched.key && errors.key}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                      my={2}
                    />
                    <FormControl my={2}>
                      <InputLabel id="demo-simple-select-label">
                        Display Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={displayType}
                        label="Display type"
                        onChange={(e) => {
                          setDisplayType(e.target.value);
                        }}
                      >
                        <MenuItem value={0}>Link</MenuItem>
                        <MenuItem value={1}>Text</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      name="description"
                      label="Description"
                      value={values.description}
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                      my={2}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                    >
                      Save changes
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </div>
    </>
  );
};

const CompanyAttributesEdit = ({ data }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(reset());
  }, [dispatch]);
  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <CodeIcon /> Company Attributes
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <EditCompanyAttribute dispatch={dispatch} data={data} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Company Attributes
                </Typography>
                <Ptypography component="p">
                  A custom attribute tracks facts about your
                  contacts/conversation — like the subscription plan, or when
                  they ordered the first item etc.
                </Ptypography>
                <Ptypography component="p">
                  For creating a Custom Attribute, just click on theAdd Custom
                  Attribute . You can also edit or delete an existing Custom
                  Attribute by clicking on the Edit or Delete button.
                </Ptypography>
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default CompanyAttributesEdit;
