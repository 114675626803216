import React, { useState } from "react";
import styled from "@emotion/styled";
import { Grid, TextField, Box, List, ListItem, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AttachmentIcon from "@mui/icons-material/Attachment";
import MicIcon from "@mui/icons-material/Mic";
import { green, yellow } from "@mui/material/colors";
import EmojiComponent from "./EmojiComponent";
import VoiceNote from "./VoiceNote";
const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const ChatInput = styled(Grid)`
  max-height: 60px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const ChatAction = styled(`span`)`
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
  border-radius: 10px;
  margin-right: 20px;
  cursor: pointer;
`;

var suggestions = [];

const ChatInputComponent = ({
  user,
  process,
  chatAssignee,
  chatAssigneeCount,
  handleSendMessage,
  callStateData,
  setFileSize,
  setFile,
  setSelectedFile,
  setIsFilePicked,
  channelId,
  uploadFileData,
  cannedResponses,
  isFilePicked,
  conversationId,
}) => {
  const dispatch = useDispatch();
  const [chatMsg, setChatMsg] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isOpen, setOpen] = React.useState(false);

  const [openMic, setOpenMic] = React.useState({
    Open: false,
  });

  !!cannedResponses &&
    !!cannedResponses?.data &&
    cannedResponses.data.length > 0 &&
    (suggestions = cannedResponses?.data);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // draft messages

  React.useEffect(() => {
    const savedDraft = localStorage.getItem(`draft-${conversationId}`) || "";
    setChatMsg(savedDraft);
  }, [conversationId]);

  // Save draft message
  const handleDraftChange = (value) => {
    //setDraft(value);
    setChatMsg(value);
    localStorage.setItem(`draft-${conversationId}`, value); // Save draft to localStorage
  };

  // Load draft when chatId changes
  React.useEffect(() => {
    const savedDraft = localStorage.getItem(`draft-${conversationId}`) || "";
    setChatMsg(savedDraft);
  }, [conversationId]);

  // draft messages

  // send Emojis
  function onClick(emojiData, event) {
    setChatMsg((e) => e !== null && e + emojiData.emoji);
    const existingDraft = localStorage.getItem(`draft-${conversationId}`) || ""; // Get existing draft or default to an empty string
    localStorage.setItem(
      `draft-${conversationId}`,
      existingDraft + emojiData.emoji // Append the emoji to the draft
    );
  }

  const sendVoiceNote = async (file, channelId) => {
    setFile("");
    setSelectedFile();
    setSelectedFile(file);
    setIsFilePicked(true);

    var obj = {
      file: file,
      channel_id: channelId,
    };
    await dispatch(uploadFileData(obj));
  };

  const changeAttachmentsHandler = async (event) => {
    if (parseInt(event.target.files[0].size) > 5242855) {
      console.log("1");

      setFileSize(true);
      await timeOut(2000);
      setFileSize(false);
    } else {
      // dispatch(reset_fileData());
      setFile("");
      setSelectedFile();
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);

      var obj = {
        file: event.target.files[0],
        channel_id: channelId,
      };

      //await dispatch(uploadFileData(event.target.files[0], "channel_id"));
      await dispatch(uploadFileData(obj));
    }
    event.target.value = "";
  };

  const handleChange = (value) => {
    //setChatMsg(value);
    handleDraftChange(value);
    // Show suggestions if the last character is '/'
    if (value.endsWith("/")) {
      setFilteredSuggestions(suggestions);
      setShowSuggestions(true);
    } else if (value.includes("/")) {
      const lastSlashIndex = value.lastIndexOf("/");
      const searchTerm = value.slice(lastSlashIndex + 1).trim();
      const filtered = suggestions.filter((suggestion) =>
        suggestion.short_code.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(filtered.length > 0);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    const lastSlashIndex = chatMsg.lastIndexOf("/");
    // Slice the chat message up to the last slash (excluding the slash itself)
    const newMessage = chatMsg.slice(0, lastSlashIndex) + suggestion + " ";
    // Update the chat message state
    setChatMsg(newMessage);
    // Hide the suggestions
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (!!process && process === "success") {
      if (e.key === "Enter" && !e.shiftKey) {
        // Send message on Enter without Shift
        e.preventDefault();
        // Ensure the message is not just whitespace
        if (chatMsg.trim() !== "") {
          if (chatMsg.includes("/")) {
            const lastSlashIndex = chatMsg.lastIndexOf("/");
            const searchTerm = chatMsg.slice(lastSlashIndex + 1).trim();
            const filtered = filteredSuggestions.filter((suggestion) =>
              suggestion.short_code
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            );
            // If a suggestion is found, replace the short_code with its content
            if (filtered.length > 0) {
              const newMessage =
                chatMsg.slice(0, lastSlashIndex).trim() +
                " " +
                filtered[0].content;

              callStateData(newMessage); // Send the concatenated message
            } else {
              // If no suggestion matches, send the entire chatMsg
              callStateData(chatMsg);
            }

            setChatMsg(""); // Clear the input field after sending
            setShowSuggestions(false);
          } else {
            callStateData(chatMsg);
            setChatMsg(""); // Clear the input field after sending
            setShowSuggestions(false);
          }
        } else {
          if (isFilePicked) {
            console.log("event.target.files[0]  == ");
            handleSendMessage(); // Call the send message function with the current chatMsg
            //callStateData(chatMsg);
            setChatMsg(""); // Clear the input field after sending
            setShowSuggestions(false);
          }
        }
      } else if (e.key === "Enter" && e.shiftKey) {
        // Add a newline character to the input value on Shift + Enter
        e.preventDefault();
        setChatMsg((prev) => prev + "\n");
        callStateData(chatMsg);
        handleSendMessage();
      }
    }
  };

  // on Button click
  const handleButtonEvent = () => {
    if (chatMsg.trim() !== "") {
      callStateData(chatMsg);
      setChatMsg(""); // Clear the input field after sending
      setShowSuggestions(false);
    } else {
      if (isFilePicked) {
        handleSendMessage(); // Call the send message function with the current chatMsg
        callStateData(chatMsg);
        setChatMsg(""); // Clear the input field after sending
        setShowSuggestions(false);
      }
    }
  };

  const handleClickOpenMic = () => {
    setOpenMic({
      Open: true,
    });
  };

  const handleClickCloseMic = () => {
    setOpenMic({
      Open: false,
    });
  };

  return (
    <>
      <EmojiComponent
        isOpen={isOpen}
        handleClose={handleClose}
        onClick={onClick}
      />

      <VoiceNote
        openAdd={openMic}
        handleClickClose={handleClickCloseMic}
        handleClickOpen={handleClickOpenMic}
        channelId={channelId}
        sendVoiceNote={sendVoiceNote}
      />

      <ChatInput container style={{ marginBottom: "15px" }}>
        <Grid container>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              variant="outlined"
              label="Type your message"
              multiline
              rows={2}
              fullWidth
              value={chatMsg}
              onChange={(e) => handleChange(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            {showSuggestions && filteredSuggestions.length > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                  maxHeight: "30vh",
                  overflowY: "auto",
                  bottom: "152px",
                  left: "0",
                }}
              >
                <List>
                  {filteredSuggestions.map((suggestion, index) => (
                    <ListItem
                      key={index}
                      onClick={() => handleSelectSuggestion(suggestion.content)}
                      className="suggestion-item"
                    >
                      <span className="canned-key">
                        {suggestion.short_code}
                      </span>
                      :
                      <span className="canned-content">
                        {suggestion.content}
                      </span>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Grid>
        </Grid>
      </ChatInput>
      <ChatInput container>
        <Grid item style={{ flexGrow: 1 }}>
          <Box ml={2} mt={2} className="chatButtons">
            <ChatAction>
              <EmojiEmotionsIcon
                onClick={handleOpen}
                sx={{ color: yellow[900] }}
              />
            </ChatAction>
            <ChatAction>
              <MicIcon
                sx={{ color: green[900] }}
                onClick={() => {
                  handleClickOpenMic();
                }}
              />
            </ChatAction>
            {!!process && process === "success" ? (
              <ChatAction>
                <input
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  // onChange={(e) => setFiles(e.target.files[0])}
                  onChange={(e) => {
                    changeAttachmentsHandler(e);
                  }}
                />
                <label htmlFor="raised-button-file">
                  <AttachmentIcon
                    htmlFor="raised-button-file"
                    color="primary"
                  />
                </label>
              </ChatAction>
            ) : (
              <ChatAction>
                <AttachmentIcon htmlFor="raised-button-file" color="primary" />
              </ChatAction>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box ml={2}>
            {!!chatAssigneeCount && chatAssigneeCount > 1 ? (
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  !!process && process === "success" && handleButtonEvent();
                }}
              >
                Send
              </Button>
            ) : (
              !!chatAssignee &&
              chatAssignee === user.uid && (
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => {
                    !!process && process === "success" && handleButtonEvent();
                  }}
                >
                  Send
                </Button>
              )
            )}
          </Box>
        </Grid>
      </ChatInput>
    </>
  );
};

export default ChatInputComponent;
