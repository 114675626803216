import React from "react";
import styled from "@emotion/styled";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
//import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css
//import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardMedia as MuiCardMedia,
  Typography,
} from "@mui/material";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";

import { red } from "@mui/material/colors";
import ChatMediaDialog from "./ChatMediaDialog";
import Rating from "@mui/material/Rating";

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageComponent = ({
  name,
  message,
  time,
  avatar,
  position = "left",
  sender_type,
  content_type,
  is_media,
  media_type,
  media_url,
}) => {
  const onDocDownload = (docURL) => {
    let alink = document.createElement("a");
    alink.href = docURL;
    alink.target = "_blank";
    alink.click();
  };
  const ChatMessageTime = styled(Typography)`
    text-align: right;
    opacity: 0.8;
  `;

  const ChatMessageBubble = styled.div`
    display: inline-block;
    margin-right: auto;
    text-align: left;
    background: ${(props) =>
      props.highlighted
        ? props.theme.palette.secondary.main
        : props.theme.palette.common.white};
    color: ${(props) =>
      props.highlighted
        ? props.theme.palette.common.white
        : props.theme.palette.text.primary};
    border-radius: 10px;
    padding: ${(props) => props.theme.spacing(2)};
    margin-bottom: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.shadows[1]};
  `;

  const ChatMessageBubblePrivate = styled.div`
    display: inline-block;
    text-align: left;
    margin-right: auto;
    background: ${(props) => props.theme.sidebar.header.chipNote.color};
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 10px;
    padding: ${(props) => props.theme.spacing(2)};
    margin-bottom: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.shadows[1]};
  `;

  const ChatMessageBubbleResolve = styled.div`
    display: inline-block;
    margin-right: auto;
    background: #acdbae;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 5px;
    padding: ${(props) => props.theme.spacing(3)};
    margin-bottom: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.shadows[1]};
  `;
  const ChatMessageBubbleResolvebyClient = styled.div`
    display: inline-block;
    margin-right: auto;
    background: #87cb8a;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 5px;
    padding: ${(props) => props.theme.spacing(3)};
    margin-bottom: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.shadows[1]};
  `;

  const ChatMessageBubbleLabel = styled.div`
    display: inline-block;
    margin-right: auto;
    background: #c0d9f5;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 5px;
    padding: ${(props) => props.theme.spacing(3)};
    margin-bottom: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.shadows[1]};
  `;

  const ChatMessageBubbleAssign = styled.div`
    display: inline-block;
    margin-right: auto;
    background: #ffb3ff;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 5px;
    padding: ${(props) => props.theme.spacing(3)};
    margin-bottom: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.shadows[1]};
  `;

  const ChatMessageBubbleJoin = styled.div`
    display: inline-block;
    margin-right: auto;
    background: #b05782;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 5px;
    padding: ${(props) => props.theme.spacing(3)};
    margin-bottom: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.shadows[1]};
  `;

  const ChatMessageBubbleJoinAgent = styled.div`
    display: inline-block;
    margin-right: auto;
    background: #ddb7c9;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 5px;
    padding: ${(props) => props.theme.spacing(3)};
    margin-bottom: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.shadows[1]};
  `;
  const CardMedia = styled(MuiCardMedia)`
    height: 220px;
    // cursor: pointer;
  `;
  const CardVedioMedia = styled(MuiCardMedia)`
    width: 100%;
    height: 120px;
    cursor: pointer;
  `;
  const CrdHeader = styled(CardHeader)`
    cursor: pointer;
  `;
  const ChatMessageBubbleName = styled(Typography)`
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  `;

  const [open, setOpen] = React.useState({
    Open: false,
  });

  const handleClickOpen = (mediaUrl, mediaType) => {
    setOpen({
      Open: true,
      MediaUrl: mediaUrl,
      MediaType: mediaType,
    });
  };

  const handleClickClose = () => {
    setOpen({
      Open: false,
    });
  };

  const [copied, setCopied] = React.useState(false);
  const handleCopyText = () => {
    navigator.clipboard.writeText(message).then(
      () => {
        //console.log("Text copied to clipboard");
        setCopied(true);
        setTimeout(() => {
          setCopied(false); // Hide the message after 1 second
        }, 1000); // 1000ms = 1 second
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  return (
    <>
      {parseInt(content_type) === 0 && (
        <ChatMessage position={position}>
          <ChatMessageInner>
            {/* <ChatMessageAvatar alt="Lucy Lavender" src={avatar} /> */}
            <ChatMessageBubble highlighted={position === "right"}>
              <Box sx={{ minWidth: "170px!important" }}>
                <ChatMessageBubbleName
                  variant="body1"
                  style={{
                    display: "flex",
                    justifyContent: "space-between", // Ensures that the content is spaced
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "10px" }}> {name}</span>

                  {copied ? (
                    <span className="copy-text-10">Copied!</span>
                  ) : (
                    <Avatar
                      sx={{
                        width: 16,
                        height: 16,
                        opacity: "0.7",
                        textAlign: "center",
                      }}
                      onClick={handleCopyText}
                    >
                      <ContentCopyIcon className="copy-text" />
                    </Avatar>
                  )}
                </ChatMessageBubbleName>
              </Box>
              {parseInt(is_media) === 1 && media_type === "image" ? (
                <Card style={{ minWidth: "250px" }}>
                  <CardMedia
                    image={media_url}
                    title="Contemplative Reptile"
                    onClick={() => {
                      handleClickOpen(media_url, media_type);
                    }}
                  />
                </Card>
              ) : null}
              {parseInt(is_media) === 1 && media_type === "application" ? (
                <Card>
                  <CrdHeader
                    avatar={
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <DownloadForOfflineSharpIcon />
                      </Avatar>
                    }
                    onClick={() => onDocDownload(media_url)}
                    title={media_url}
                    subheader={media_type}
                  />
                </Card>
              ) : null}
              {parseInt(is_media) === 1 && media_type === "text" ? (
                <Card>
                  <CrdHeader
                    avatar={
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <DownloadForOfflineSharpIcon />
                      </Avatar>
                    }
                    onClick={() => onDocDownload(media_url)}
                    title={media_url}
                    subheader={media_type}
                  />
                </Card>
              ) : null}
              {parseInt(is_media) === 1 && media_type === "video" ? (
                // <Card style={{ minWidth: "250px" }}>
                //   <Player
                //     playsInline
                //     // poster="/assets/poster.png"
                //     src={media_url}
                //     width="100%"
                //     height="220px"
                //   />
                // </Card>
                <Card style={{ minWidth: "250px" }}>
                  <CardVedioMedia
                    image="static/img/samples/videoPoster.jpg"
                    title=""
                    onClick={() => {
                      handleClickOpen(media_url, media_type);
                    }}
                  />
                </Card>
              ) : null}
              {parseInt(is_media) === 1 && media_type === "audio" ? (
                <Card
                  style={{
                    width: "300px",
                    height: "28px",
                  }}
                >
                  {/* <AudioPlayer
                    autoPlay={false}
                    src={media_url}
                    onPlay={(e) => console.log("onPlay")}
                  /> */}
                  <CardVedioMedia
                    image="static/img/samples/audio1.png"
                    title=""
                    style={{
                      width: "300px",
                      height: "100%",
                      backgroundSize: "cover",
                    }}
                    onClick={() => {
                      handleClickOpen(media_url, media_type);
                    }}
                  />
                </Card>
              ) : null}
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {message}
              </Typography>
            </ChatMessageBubble>
            <ChatMessageTime variant="body2">{time}</ChatMessageTime>
          </ChatMessageInner>
        </ChatMessage>
      )}

      {parseInt(content_type) === 11 && (
        <ChatMessage position={position}>
          <ChatMessageInner>
            {/* <ChatMessageAvatar alt="Lucy Lavender" src={avatar} /> */}
            <ChatMessageBubblePrivate highlighted={position === "right"}>
              <Box>
                <ChatMessageBubbleName variant="body1">
                  {name}
                </ChatMessageBubbleName>
              </Box>
              {parseInt(is_media) === 1 && media_type === "image" ? (
                <Card style={{ minWidth: "250px" }}>
                  <CardMedia
                    image={media_url}
                    title="Contemplative Reptile"
                    onClick={() => {
                      handleClickOpen(media_url, media_type);
                    }}
                  />
                </Card>
              ) : null}
              {parseInt(is_media) === 1 && media_type === "application" ? (
                <Card>
                  <CrdHeader
                    avatar={
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <DownloadForOfflineSharpIcon />
                      </Avatar>
                    }
                    onClick={() => onDocDownload(media_url)}
                    title={media_url}
                    subheader={media_type}
                  />
                </Card>
              ) : null}
              {parseInt(is_media) === 1 && media_type === "text" ? (
                <Card>
                  <CrdHeader
                    avatar={
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <DownloadForOfflineSharpIcon />
                      </Avatar>
                    }
                    onClick={() => onDocDownload(media_url)}
                    title={media_url}
                    subheader={media_type}
                  />
                </Card>
              ) : null}
              {parseInt(is_media) === 1 && media_type === "video" ? (
                // <Card style={{ minWidth: "250px" }}>
                //   <Player
                //     playsInline
                //     // poster="/assets/poster.png"
                //     src={media_url}
                //     width="100%"
                //     height="220px"
                //   />
                // </Card>
                <Card style={{ minWidth: "250px" }}>
                  <CardVedioMedia
                    image="static/img/samples/videoPoster.jpg"
                    title=""
                    onClick={() => {
                      handleClickOpen(media_url, media_type);
                    }}
                  />
                </Card>
              ) : null}
              {parseInt(is_media) === 1 && media_type === "audio" ? (
                <Card
                  style={{ minWidth: "350px", width: "100%", height: "35px" }}
                >
                  {/* <AudioPlayer
                    autoPlay={false}
                    src={media_url}
                    onPlay={(e) => console.log("onPlay")}
                  /> */}
                  <CardVedioMedia
                    image="static/img/samples/audio1.png"
                    title=""
                    style={{
                      width: "300px",
                      height: "100%",
                      backgroundSize: "cover",
                    }}
                    onClick={() => {
                      handleClickOpen(media_url, media_type);
                    }}
                  />
                </Card>
              ) : null}
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {message}
              </Typography>
            </ChatMessageBubblePrivate>
            <ChatMessageTime variant="body2">{time}</ChatMessageTime>
          </ChatMessageInner>
        </ChatMessage>
      )}

      {parseInt(content_type) === 1 && (
        <ChatMessage position={"center"}>
          <ChatMessageInner>
            <ChatMessageBubbleResolve>
              <Typography>{message}</Typography>
              <ChatMessageTime variant="body2">{time}</ChatMessageTime>
            </ChatMessageBubbleResolve>
          </ChatMessageInner>
        </ChatMessage>
      )}

      {parseInt(content_type) === 2 && (
        <ChatMessage position={"center"}>
          <ChatMessageInner>
            <ChatMessageBubbleResolvebyClient>
              <Typography>{message}</Typography>
              <ChatMessageTime variant="body2">{time}</ChatMessageTime>
            </ChatMessageBubbleResolvebyClient>
          </ChatMessageInner>
        </ChatMessage>
      )}

      {parseInt(content_type) === 4 && (
        <ChatMessage position={"left"}>
          <ChatMessageInner>
            <ChatMessageBubble>
              <Box>
                <ChatMessageBubbleName variant="body1" color={"#1180FF"}>
                  {`Feedback from ${name}`}
                </ChatMessageBubbleName>
              </Box>
              <Typography>
                <Rating
                  name="simple-controlled"
                  value={message.split("┴")[0]}
                  style={{ fontSize: "35px" }}
                  readOnly={true}
                />
              </Typography>
              <Typography
                style={{ backgroundColor: "#F0F0F0", padding: "5px" }}
              >
                {message.split("┴")[1]}
              </Typography>

              <Typography></Typography>
              <ChatMessageTime variant="body2">{time}</ChatMessageTime>
            </ChatMessageBubble>
          </ChatMessageInner>
        </ChatMessage>
      )}
      {parseInt(content_type) === 6 && (
        <ChatMessage position={"center"}>
          <ChatMessageInner>
            <ChatMessageBubbleAssign>
              <Typography>{message}</Typography>
              <ChatMessageTime variant="body2">{time}</ChatMessageTime>
            </ChatMessageBubbleAssign>
          </ChatMessageInner>
        </ChatMessage>
      )}
      {parseInt(content_type) === 9 && (
        <ChatMessage position={"center"}>
          <ChatMessageInner>
            <ChatMessageBubbleJoin>
              <Typography>{message}</Typography>
              <ChatMessageTime variant="body2">{time}</ChatMessageTime>
            </ChatMessageBubbleJoin>
          </ChatMessageInner>
        </ChatMessage>
      )}
      {parseInt(content_type) === 10 && (
        <ChatMessage position={"center"}>
          <ChatMessageInner>
            <ChatMessageBubbleJoinAgent>
              <Typography>{message}</Typography>
              <ChatMessageTime variant="body2">{time}</ChatMessageTime>
            </ChatMessageBubbleJoinAgent>
          </ChatMessageInner>
        </ChatMessage>
      )}
      {parseInt(content_type) === 12 && (
        <ChatMessage position={"center"}>
          <ChatMessageInner>
            <ChatMessageBubbleLabel>
              <Typography>{message}</Typography>
              <ChatMessageTime variant="body2">{time}</ChatMessageTime>
            </ChatMessageBubbleLabel>
          </ChatMessageInner>
        </ChatMessage>
      )}
      {parseInt(content_type) === 14 && (
        <ChatMessage position={"center"}>
          <ChatMessageInner>
            <ChatMessageBubbleLabel>
              <Typography>{message}</Typography>
              <ChatMessageTime variant="body2">{time}</ChatMessageTime>
            </ChatMessageBubbleLabel>
          </ChatMessageInner>
        </ChatMessage>
      )}
      <ChatMediaDialog
        openAdd={open}
        handleClickClose={handleClickClose}
        handleClickOpen={handleClickOpen}
      />
    </>
  );
};

export default ChatMessageComponent;
