import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography as MuiTypography,
} from "@mui/material";
//import { Edit, Mail, Phone, Share } from "@mui/icons-material";
import { Edit, Mail, Phone } from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useDispatch, useSelector } from "react-redux";
import EditContactDetails from "./conversationalActions/EditContactDetails";
import { fun_LoginLogout } from "../../../../redux/slices/socketsSlice";
import { chatConversation } from "../../../../redux/slices/chatSettings";

const Typography = styled(MuiTypography)(spacing);
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  color: ${(props) => props.theme.palette.secondary.main};
`;
const ChatAction = styled(`span`)`
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
  border-radius: 10px;
  margin-right: 20px;
  cursor: pointer;
`;

const ContactDetails = ({ socket }) => {
  const dispatch = useDispatch();
  // conversationId
  const { contactData, conversationId } = useSelector(
    (state) => state.chatSettings
  );
  const { contactUpdateData, todaysChat } = useSelector(
    (state) => state.socketsSlice
  );

  const [contact, setContact] = React.useState([]);

  React.useEffect(() => {
    dispatch(fun_LoginLogout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    !!contactUpdateData && setContact(contactUpdateData[0]);
  }, [contactUpdateData]);
  React.useEffect(() => {
    !!contactData && setContact(contactData);
  }, [contactData]);

  React.useEffect(() => {
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) => item._id === conversationId && getConversationDetails(item)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, todaysChat]);

  const getConversationDetails = (item) => {
    !!item &&
      dispatch(
        chatConversation({
          conStatus: item.status,
          conversationId: item._id,
          botId: !!item.bot_id && item.bot_id,
          orgId: !!item.org_id && item.org_id,
          agentId:
            !!item.chatAssign[0].assignee_id && item.chatAssign[0].assignee_id,
          channelId: !!item.channel_id && item.channel_id,
          contactId: !!item.contact_id && item.contact_id,
          contactData: item.contactDetails[0],
        })
      );
  };
  // Open Dialogbox
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <List dense={true}>
        <ListItem>
          <ListItemIcon>
            <Avatar
              alt={!!contact && contact.name}
              src={!!contact && contact.photo}
              sx={{ width: 57, height: 57 }}
            />
          </ListItemIcon>
        </ListItem>
        <ListItem>
          <TitleTypography
            variant="h4"
            style={{ wordWrap: "break-word", maxWidth: "300px" }}
            gutterBottom
          >
            {!!contact && contact.name}
          </TitleTypography>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Mail />
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-wifi"
            primary={!!contact && contact.email}
            style={{ wordWrap: "break-word", maxWidth: "300px" }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Phone />
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-bluetooth"
            primary={!!contact && contact.phone_number}
            style={{ wordWrap: "break-word", maxWidth: "300px" }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-bluetooth"
            primary={!!contact && contact.company_name}
            style={{ wordWrap: "break-word", maxWidth: "300px" }}
          />
        </ListItem>
        <ListItem>
          <ChatAction onClick={handleClickOpen}>
            <Edit color="secondary" style={{ fontSize: "14px" }} />
          </ChatAction>
          {/* <ChatAction>
            <Share color="success" style={{ fontSize: "14px" }} />
          </ChatAction> */}
        </ListItem>
      </List>
      <EditContactDetails
        selectedValue={!!contact && contact}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default ContactDetails;
