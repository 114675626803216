import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Box,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
} from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import CompanyAttributesTable from "./companyAttributes/CompanyAttributesTable";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Ptypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
`;

const CompanyAttributes = () => {
  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <CodeIcon /> Company Attributes
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <CompanyAttributesTable />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Company Attributes
                </Typography>
                <Ptypography component="p">
                  A custom attribute tracks facts about your
                  contacts/conversation — like the subscription plan, or when
                  they ordered the first item etc.
                </Ptypography>
                <Ptypography component="p">
                  For creating a Custom Attribute, just click on theAdd Custom
                  Attribute . You can also edit or delete an existing Custom
                  Attribute by clicking on the Edit or Delete button.
                </Ptypography>
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default CompanyAttributes;
