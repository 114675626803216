import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch report for overview Data
export const fetchLabelsReport = createAsyncThunk(
  "labelsReport/fetch",
  async (PayloadData) => {
    // console.log(
    //   "fetchLabelsReport PayloadData == ",
    //   JSON.stringify(PayloadData)
    // );
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const data = {
          account_id: accountID.toString(),
          label_id: PayloadData.labelId,
          day_label: PayloadData.dayID,
          start_date: PayloadData.start_date,
          end_date: PayloadData.end_date,
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/label-reports`,
          data,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);
export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});
export const reportLabelsSlice = createSlice({
  name: "reportLabels",
  initialState: {
    labelReportdata: [],
    labelsStatus: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLabelsReport.pending, (state, action) => {
        state.labelReportdata = [];
        state.labelsStatus = STATUSES.LOADING;
      })
      .addCase(fetchLabelsReport.fulfilled, (state, action) => {
        state.labelReportdata = action.payload;
        state.labelsStatus = STATUSES.SUCCESS;
      })
      .addCase(fetchLabelsReport.rejected, (state, action) => {
        state.labelReportdata = [];
        state.labelsStatus = STATUSES.ERROR;
      });
  },
});

export const { setLabelReportdata, setLabelsStatus } =
  reportLabelsSlice.actions;
export default reportLabelsSlice.reducer;
