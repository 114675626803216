import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Box,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CannedResponsesTable from "./cannedResponses/CannedResponsesTable";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Ptypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
`;

const CannedResponses = () => {
  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <QuestionAnswerIcon /> Canned Responses
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                {/* <Search>
                  <IconButton
                    type="button"
                    sx={{ p: "4px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
                </Search>
                <Divider my={2} sx={{ borderBottomWidth: 2 }} /> */}
                <CannedResponsesTable />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Canned Responses
                </Typography>
                <Ptypography component="p">
                  Canned Responses are saved reply templates which can be used
                  to quickly send out a reply to a conversation
                </Ptypography>
                <Ptypography component="p">
                  For creating a Canned Response, just click on theAdd Canned
                  Response. You can also edit or delete an existing Canned
                  Response by clicking on the Edit or Delete button.
                </Ptypography>
                <Ptypography component="p">
                  Canned responses are employed with the assistance of short
                  codes, enabling agents to access pre-defined responses by
                  simply writing the corresponding short code.
                </Ptypography>
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default CannedResponses;
