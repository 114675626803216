import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import {
  Alert as MuiAlert,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Toolbar,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { settingsMenu } from "../../../../../redux/slices/settings";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCannedResponces,
  reset,
} from "../../../../../redux/slices/cannedResponses";
import { isValidToken } from "../../../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../../redux/slices/login";
import { useNavigate } from "react-router-dom";
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Ptypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
`;

const Spacer = styled.div`
  flex: 1 1 20%;
`;
const timeOut = (time) => new Promise((res) => setTimeout(res, time));

var initialValues = {
  id: "",
};

const validationSchema = Yup.object().shape({});

const DeleteCannedResponses = ({ dispatch, data }) => {
  const { deleteData } = useSelector((state) => state.cannedResponses);
  const accessToken = window.localStorage.getItem("accessToken");
  const navigate = useNavigate();
  initialValues = {
    id: data.id,
  };
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      } else {
        await timeOut(1500);
        await dispatch(deleteCannedResponces(values));
        setStatus({ sent: true });
        setSubmitting(false);
        await timeOut(1500);
        dispatch(
          settingsMenu({
            list: "canned_responses",
            listData: [],
          })
        );
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleBack = (row) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(
        settingsMenu({
          list: "canned_responses",
          listData: [],
        })
      );
    }
  };

  return (
    <>
      <div>
        <Paper>
          <Toolbar>
            <div>
              <Typography variant="h6" id="tableTitle">
                Delete Canned Responses
              </Typography>
            </div>
            <Spacer />
            <div>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                style={{ marginRight: "5px" }}
                // onClick={() =>
                //   dispatch(
                //     settingsMenu({
                //       list: "canned_responses",
                //       listData: [],
                //     })
                //   )
                // }

                onClick={() => {
                  handleBack();
                }}
              >
                <NavigateBeforeIcon />
                Back
              </Button>
            </div>
          </Toolbar>
        </Paper>
      </div>
      <Divider my={1} />
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {errors && errors.sent && (
                  <Alert severity="error" my={3}>
                    {errors.message}
                  </Alert>
                )}
                {!!deleteData &&
                  (!!deleteData.status &&
                  parseInt(deleteData.status) !== 201 ? (
                    <Alert severity="error" my={3}>
                      {!!deleteData && deleteData.message}
                    </Alert>
                  ) : (
                    status &&
                    status.sent && (
                      <Alert severity="success" my={3}>
                        Your data has been Deleted successfully!
                      </Alert>
                    )
                  ))}
                {/* {!!deleteData &&
                  !!deleteData.status &&
                  parseInt(deleteData.status) !== 201 && (
                    <Alert severity="error" my={3}>
                      {!!deleteData && deleteData.message}
                    </Alert>
                  )}

                {!!deleteData &&
                  !!deleteData.status &&
                  parseInt(deleteData.status) === 201 && (
                    <Alert severity="error" my={3}>
                      {!!deleteData && deleteData.message}
                    </Alert>
                  )} */}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <CardContent>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{
                                  fontWeight: "bold",
                                  width: "150px",
                                  color: "#376FD0",
                                }}
                              >
                                Short Code
                              </TableCell>
                              <TableCell align="left">
                                {data.short_code}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{
                                  fontWeight: "bold",
                                  width: "150px",
                                  color: "#376FD0",
                                }}
                              >
                                Content
                              </TableCell>
                              <TableCell align="left">{data.content}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Card>
                    <Alert mb={4} severity="info">
                      Do you want to delete this record?
                    </Alert>
                    <TextField
                      name="id"
                      value={values.id}
                      error={Boolean(touched.id && errors.id)}
                      helperText={touched.id && errors.id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="hidden"
                      my={2}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                    >
                      Yes
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      style={{ marginLeft: "5px" }}
                      // onClick={() =>
                      //   dispatch(
                      //     settingsMenu({
                      //       list: "canned_responses",
                      //       listData: [],
                      //     })
                      //   )
                      // }
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      No
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </div>
    </>
  );
};

const CannedResponsesDelete = ({ data }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(reset());
  }, [dispatch]);
  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <QuestionAnswerIcon /> Canned Responses
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                {/* add  */}
                <DeleteCannedResponses dispatch={dispatch} data={data} />
                {/* close add */}
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Canned Responses
                </Typography>
                <Ptypography component="p">
                  Canned Responses are saved reply templates which can be used
                  to quickly send out a reply to a conversation
                </Ptypography>
                <Ptypography component="p">
                  For creating a Canned Response, just click on theAdd Canned
                  Response. You can also edit or delete an existing Canned
                  Response by clicking on the Edit or Delete button.
                </Ptypography>
                <Ptypography component="p">
                  Canned responses are used with the help of Short Codes. Agents
                  can access canned responses while on a chat by typing '/'
                  followed by the short code.
                </Ptypography>
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default CannedResponsesDelete;
