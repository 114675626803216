import React from "react";
import styled from "@emotion/styled";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import TheamChartForTime from "../../TheamChartForTime";
const Typography = styled(MuiTypography)(spacing);

const ChartOverview = ({ openAdd, handleAddClose, handleAddClickOpen }) => {
  return (
    <Dialog
      open={openAdd.Open}
      onClose={handleAddClose}
      fullWidth={true}
      maxWidth={"xl"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="form-dialog-title">
        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            <CloseIcon onClick={handleAddClose} />
          </Typography>
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <TheamChartForTime RowData={openAdd.data} reportName={openAdd.report} />
      </DialogContent>
    </Dialog>
  );
};

export default ChartOverview;
