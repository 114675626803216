import React from "react";
//import styled from "@emotion/styled";
import {
  Button,
  Card,
  //CardMedia as MuiCardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Player } from "video-react";
import AudioPlayer from "react-h5-audio-player";
import ImageZoom from "./ImageZoom";
//import html2canvas from "html2canvas";
const ChatMediaDialog = ({ openAdd, handleClickClose }) => {
  const [scale, setScale] = React.useState(1);
  const handleClose = () => {
    setScale(1);
    handleClickClose();
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > 0.2) {
      setScale(scale - 0.1);
    }
  };
  return (
    <>
      <Dialog
        open={openAdd.Open}
        //onClose={handleClickClose}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Card>
              {openAdd.MediaType === "image" && (
                // <CardMedia
                //   image={openAdd.MediaUrl}
                //   title="Contemplative Reptile"
                //   id="image-element"
                //   ref={cardMediaRef}
                // />
                <ImageZoom src={openAdd.MediaUrl} zoom={scale}></ImageZoom>
              )}
              {openAdd.MediaType === "video" && (
                <Player
                  playsInline
                  //poster="static/img/samples/videoPoster.jpg"
                  src={openAdd.MediaUrl}
                  autoPlay
                />
              )}
              {openAdd.MediaType === "audio" && (
                <AudioPlayer
                  autoPlay={true}
                  src={openAdd.MediaUrl}
                  onPlay={(e) => console.log("onPlay")}
                />
              )}
            </Card>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {openAdd.MediaType === "image" && (
            <>
              <Button onClick={handleZoomIn} color="primary">
                <Tooltip title="Zoom-In" placement="left">
                  <AddIcon />
                </Tooltip>
              </Button>
              <Button onClick={handleZoomOut} color="primary">
                <Tooltip title="Zoom-Out" placement="left">
                  <RemoveIcon />
                </Tooltip>
              </Button>
            </>
          )}

          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatMediaDialog;
