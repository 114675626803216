import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import ConversationsTab from "./ConversationsTab";
import IncomingMessagesTab from "./IncomingMessagesTab";
import OutgoingMessagesTab from "./OutgoingMessagesTab";
import FirstResponseTimeTab from "./FirstResponseTimeTab";
import ResolutionTimeTab from "./ResolutionTimeTab";
import ResolutionCountTab from "./ResolutionCountTab";

const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
const TabTables = ({ reportData }) => {
  const [key, setKey] = React.useState("Conversations");
  return (
    <>
      <div className="chatinput-section">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          {/* Conversations Tab */}
          <Tab
            eventKey="Conversations"
            title={
              !!reportData && !!reportData.total_coversationCount
                ? `Conversations (${reportData.total_coversationCount})`
                : `Conversations (0)`
            }
            style={{ backgroundColor: "#fff", padding: "5px" }}
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <ConversationsTab reportData={reportData} />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>

          {/* Incoming Messages Tab */}
          <Tab
            eventKey="Incoming"
            title={
              !!reportData && !!reportData.total_incommngMsgCount
                ? `Incoming Messages (${reportData.total_incommngMsgCount})`
                : `Incoming Messages (0)`
            }
            style={{ backgroundColor: "#fff", padding: "5px" }}
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <IncomingMessagesTab reportData={reportData} />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>

          {/* Outgoing Messages Tab */}
          <Tab
            eventKey="Outgoing"
            title={
              !!reportData && !!reportData.total_outgoingMsgCount
                ? `Outgoing Messages (${reportData.total_outgoingMsgCount})`
                : `Outgoing Messages (0)`
            }
            style={{ backgroundColor: "#fff", padding: "5px" }}
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <OutgoingMessagesTab reportData={reportData} />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>

          {/*First response time Messages Tab */}
          <Tab
            eventKey="FirstrResponseTime"
            title={
              !!reportData && !!reportData.total_firstResponceTimeCount
                ? `First response time (${reportData.total_firstResponceTimeCount})`
                : `First response time (00:00:00)`
            }
            style={{ backgroundColor: "#fff", padding: "5px" }}
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <FirstResponseTimeTab reportData={reportData} />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>

          {/*Resolution Time Messages Tab */}
          <Tab
            eventKey="ResolutionTime"
            title={
              !!reportData && !!reportData.total_resolutionTimeCount
                ? `Resolution Time (${reportData.total_resolutionTimeCount})`
                : `Resolution Time (00:00:00)`
            }
            style={{ backgroundColor: "#fff", padding: "5px" }}
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <ResolutionTimeTab reportData={reportData} />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>

          {/*Resolution Count Messages Tab */}
          <Tab
            eventKey="ResolutionCount"
            title={
              !!reportData && !!reportData.total_resolutionCount
                ? `Resolution Count (${reportData.total_resolutionCount})`
                : `Resolution Count (0)`
            }
            style={{ backgroundColor: "#fff", padding: "5px" }}
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <ResolutionCountTab reportData={reportData} />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default TabTables;
