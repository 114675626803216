const errorHandler = async (error) => {
  var ErrorData;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log("error.response.status == ", error.response.status);
    // console.log("error.response.headers == ", error.response.headers);

    if (parseInt(error.response.status) === 404) {
      ErrorData = { message: error.response.statusText, status: 404 };
    } else {
      ErrorData = {
        message: error.response.data.message,
        status: error.response.data.status,
      };
    }
  }
  //   else if (error.request) {
  //     // The request was made but no response was received
  //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //     // http.ClientRequest in node.js
  //     console.log("else if == ", error.request);
  //   }
  else {
    // Something happened in setting up the request that triggered an Error
    ErrorData = {
      message: error.message,
      status: 400,
    };
  }
  return await ErrorData;
};
export default errorHandler;
