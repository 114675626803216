import {
  Layout,
  //Sliders,
  Users,
  Settings,
  FileMinus,
} from "react-feather";

// const role = localStorage.getItem("atur");
// console.log("role == ", role);

var pagesSection = [];

pagesSection = [
  {
    href: "/chat",
    icon: Layout,
  },
  {
    href: "/contact",
    icon: Users,
  },
  {
    href: "/settings",
    icon: Settings,
  },
  {
    href: "/reports",
    icon: FileMinus,
  },
];

const navItems = [
  {
    // title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
