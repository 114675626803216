import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

export const fetchTodayschat = createAsyncThunk(
  "todayschatData/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        var payloadData = {
          account_id: accountID,
        };
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/todayschat`,
          payloadData,
          config
        );

        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

export const sockets = createSlice({
  name: "socketsSlice",
  initialState: {
    todaysChat: [],
    chatFromoneSpocData: [],
    loginLogoutData: [],
    contactUpdateData: [],
    openedChat: [],
    resolvedChat: [],
  },
  reducers: {
    fun_resetChatFromoneSpocData: (state, action) => {
      state.chatFromoneSpocData = [];
    },
    fun_TodaysChat: (state, action) => {
      state.todaysChat = [];
      state.todaysChat = action.payload;
    },
    fun_ChatFromoneSpoc: (state, action) => {
      state.chatFromoneSpocData = action.payload;
    },
    fun_LoginLogout: (state, action) => {
      state.loginLogoutData = action.payload;
    },
    fun_Contact_Upate: (state, action) => {
      state.contactUpdateData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTodayschat.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchTodayschat.fulfilled, (state, action) => {
        state.todaysChat = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchTodayschat.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const {
  setTodaysChat,
  setChatFromoneSpocData,
  setOpenedChat,
  setLoginLogoutData,
  setContactUpdateData,
  fun_TodaysChat,
  fun_ChatFromoneSpoc,
  fun_resetChatFromoneSpocData,
  fun_LoginLogout,
  fun_Contact_Upate,
} = sockets.actions;
export default sockets.reducer;
