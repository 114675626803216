import React from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography as MuiTypography,
  CircularProgress as MuiCircularProgress,
  Button,
  Select,
  MenuItem,
  FormControl,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAgentStatus } from "../../../../../redux/slices/conversationalActions";
import { fun_LoginLogout } from "../../../../../redux/slices/socketsSlice";
import QueueIcon from "@mui/icons-material/Queue";
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const ListContainer = styled(List)`
  overflow-y: scroll;
  height: calc(80vh - 94px);
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const NoConversation = styled(`div`)`
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 1rem;
`;
const MessageIcons = styled(QueueIcon)`
  font-size: 4rem;
  color: ${(props) => props.theme.sidebar.footer.online.background};
`;
const QueueList = ({ QueueChatsList, socket }) => {
  const dispatch = useDispatch();
  const { agentStatus } = useSelector((state) => state.conversationalAction);
  const { loginLogoutData, todaysChat } = useSelector(
    (state) => state.socketsSlice
  );
  const roleID = localStorage.getItem("atur");
  const user = JSON.parse(localStorage.getItem("user"));
  var [loginStatus, setLoginStatus] = React.useState([]);
  var [chatListData, setChatListData] = React.useState([]);
  const [selfQueue, setSelfQueue] = React.useState(false);
  const [selfQueueClose, setSelfQueueClose] = React.useState(true);
  var [role, setRole] = React.useState(1);
  const [selAgent, setSelAgent] = React.useState("");

  React.useEffect(() => {
    !!roleID && setRole(roleID === "638f1032a1c0997b2085c2a6" ? 1 : 0);
  }, [roleID]);

  React.useEffect(() => {
    !!QueueChatsList && setChatListData(QueueChatsList);
  }, [QueueChatsList]);
  React.useEffect(() => {
    dispatch(fetchAllAgentStatus());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(fun_LoginLogout());
  }, [dispatch]);

  React.useEffect(() => {
    !!agentStatus && setLoginStatus(agentStatus);
  }, [agentStatus]);

  React.useEffect(() => {
    !!loginLogoutData && setLoginStatus(loginLogoutData);
  }, [loginLogoutData]);

  React.useEffect(() => {
    setChatListData([]);
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          !!item.chatAssign &&
          parseInt(item.is_queue_chat) === 1 &&
          parseInt(item.is_queue_confirm) === 0 &&
          item.chatAssign.map((chatitem) =>
            setChatListData((chatListData) => [...chatListData, item])
          )
      );
  }, [todaysChat, user.uid]);

  const handleChange = (event) => {
    setSelAgent(event.target.value);
  };

  const handleSelfAssign = (item, user) => {
    setSelfQueue(true);
    setSelfQueueClose(false);
    var msg = `This conversation is selfed assign by ${user.displayname}`;
    var messagePayload = {
      data: {
        flag: "note",
        channel_id: !!item && item.channel_id,
        bot_id: !!item && item.bot_id,
        org_id: !!item && item.org_id,
        account_id: !!item && item.account_id,
        conversation_id:
          !!item && item.chatAssign[0] && item.chatAssign[0].conversation_id,
        agent_id: user.uid,
        agentName: "",
        assign_id: user.uid,
        contact_id: !!item && item.contact_id,
        contact: !!item && item.contactDetails[0],
        label_id: "",
        role: role,
        message: [
          {
            content_type: 14,
            content: msg,
            media: {
              media_type: "",
              media_path: "",
            },
            message_type: "text",
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };
    socket.current.emit("chatSentFromApp", messagePayload);
    setTimeout(() => {
      setSelfQueueClose(true);
      setSelfQueue(false);
    }, 2000);
  };

  const handleAssignToOther = (item, user) => {
    setSelfQueue(true);
    setSelfQueueClose(false);
    var msg = `This conversation is assigned by ${user.displayname}`;
    var messagePayload = {
      data: {
        flag: "note",
        channel_id: !!item && item.channel_id,
        bot_id: !!item && item.bot_id,
        org_id: !!item && item.org_id,
        account_id: !!item && item.account_id,
        conversation_id:
          !!item && item.chatAssign[0] && item.chatAssign[0].conversation_id,
        agent_id: user.uid,
        agentName: "",
        assign_id: selAgent,
        contact_id: !!item && item.contact_id,
        contact: !!item && item.contactDetails[0],
        label_id: "",
        role: role,
        message: [
          {
            content_type: 14,
            content: msg,
            media: {
              media_type: "",
              media_path: "",
            },
            message_type: "text",
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };
    socket.current.emit("chatSentFromApp", messagePayload);
    setTimeout(() => {
      setSelfQueueClose(true);
      setSelfQueue(false);
    }, 2000);
  };

  return (
    <>
      <Dialog
        open={selfQueue}
        onClose={selfQueueClose}
        fullWidth={false}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <CircularProgress m={2} />
        </DialogContent>
      </Dialog>

      <Card mb={5}>
        <CardContent>
          {!!chatListData && chatListData.length !== 0 ? (
            <>
              <FormControl m={2} fullWidth>
                {role === 0 && (
                  <Select
                    value={selAgent}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    fullWidth
                  >
                    <MenuItem value="">Select agent for chat assign</MenuItem>
                    {!!loginStatus &&
                    loginStatus.length !== 0 &&
                    !!loginStatus.data
                      ? loginStatus.data.map(
                          (item, j) =>
                            !!item.signinsignout[0] &&
                            !!item.signinsignout[0].availability[0] &&
                            item.signinsignout[0].availability[0].isAvailable >
                              0 &&
                            item._id !== user.uid && (
                              <MenuItem key={j} value={item._id}>
                                {item.displayname}
                              </MenuItem>
                            )
                        )
                      : []}
                  </Select>
                )}
              </FormControl>
              <Divider />
              <ListContainer>
                {!!chatListData &&
                  chatListData.map((item, i) => (
                    <ListItem button key={i}>
                      <ListItemIcon>
                        <Online
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={item.contactDetails[0].name}
                            src={item.contactDetails[0].photo}
                          />
                        </Online>
                      </ListItemIcon>
                      <ListItemText
                        primary={item.contactDetails[0].name}
                        secondary={
                          item.channel_id &&
                          item.channel_id.split("_")[0].length > 10
                            ? item.channel_id
                                .split("_")[0]
                                .toString()
                                .substring(0, 15) + "..."
                            : item.channel_id.split("_")[0]
                        }
                      />
                      <Button
                        color="primary"
                        style={{ marginRight: "5px" }}
                        variant="contained"
                        onClick={() => handleSelfAssign(item, user)}
                        disabled={selfQueue}
                      >
                        Self Assign
                      </Button>
                      {!!selAgent && selAgent.length > 0 && (
                        <Button
                          color="success"
                          ml={2}
                          variant="contained"
                          disabled={selfQueue}
                          onClick={() => handleAssignToOther(item, user)}
                        >
                          Assign to agent
                        </Button>
                      )}
                    </ListItem>
                  ))}
              </ListContainer>
            </>
          ) : (
            <NoConversation>
              <MessageIcons />
              Uh oh! Looks like there are no messages in queue.
            </NoConversation>
          )}
          {/* <FormControl m={2} fullWidth>
            {role === 0 && (
              <Select
                value={selAgent}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                fullWidth
              >
                <MenuItem value="">Select agent for chat assign</MenuItem>
                {!!loginStatus && loginStatus.length !== 0 && !!loginStatus.data
                  ? loginStatus.data.map(
                      (item, j) =>
                        !!item.signinsignout[0] &&
                        !!item.signinsignout[0].availability[0] &&
                        item.signinsignout[0].availability[0].isAvailable > 0 &&
                        item._id !== user.uid && (
                          <MenuItem key={j} value={item._id}>
                            {item.displayname}
                          </MenuItem>
                        )
                    )
                  : []}
              </Select>
            )}
          </FormControl>
          <Divider />
          <ListContainer>
            {!!chatListData &&
              chatListData.map((item, i) => (
                <ListItem button key={i}>
                  <ListItemIcon>
                    <Online
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      variant="dot"
                    >
                      <Avatar
                        alt={item.contactDetails[0].name}
                        src={item.contactDetails[0].photo}
                      />
                    </Online>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.contactDetails[0].name}
                    secondary={item.channel_id}
                  />
                  <Button
                    color="primary"
                    style={{ marginRight: "5px" }}
                    variant="contained"
                    onClick={() => handleSelfAssign(item, user)}
                  >
                    Self Assign
                  </Button>
                  {!!selAgent && selAgent.length > 0 && (
                    <Button
                      color="success"
                      ml={2}
                      variant="contained"
                      onClick={() => handleAssignToOther(item, user)}
                    >
                      Assign to agent
                    </Button>
                  )}
                </ListItem>
              ))}
          </ListContainer> */}
        </CardContent>
      </Card>
    </>
  );
};
const QueueChats = ({ openAdd, handleAddClose, socket }) => {
  return (
    <>
      <Dialog
        open={openAdd.Open}
        onClose={handleAddClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">
          <DialogContentText>
            <Typography gutterBottom>
              <CloseIcon
                onClick={handleAddClose}
                style={{ cursor: "pointer" }}
              />
              Queue Chats
            </Typography>
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <QueueList QueueChatsList={openAdd.data} socket={socket} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QueueChats;
