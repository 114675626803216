import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
//Create Other messages
export const createOtherMessage = createAsyncThunk(
  "othermessage/create",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      const user = JSON.parse(localStorage.getItem("user"));
      const agentId = user.uid;
      var body = {
        message_type: payload.message_type,
        media_path: payload.media_path,
        content: payload.content,
        is_private: 1,
        content_type: payload.content_type,
        sender_type: 0,
      };
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/conversation/${payload.conversationID}/agent/${agentId}/createOtherMessage`,
          body,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const UpdateLabelID = createAsyncThunk(
  "updateLabel/create",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      var body = {
        label_id: payload.labelId,
      };
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.put(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/contact/${payload.contactID}`,
          body,
          config
        );

        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

export const otherMessageSlice = createSlice({
  name: "otherMessages",
  initialState: {
    data: [],
    updateData: [],
    status: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOtherMessage.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(createOtherMessage.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(createOtherMessage.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(UpdateLabelID.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(UpdateLabelID.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(UpdateLabelID.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});
export const { setData, setStatus, setUpdateData } = otherMessageSlice.actions;
export default otherMessageSlice.reducer;
