import { createSlice } from "@reduxjs/toolkit";

export const reportListSlice = createSlice({
  name: "reportList",
  initialState: {
    reportList: "overview_report",
    reportListData: [],
  },
  reducers: {
    reportListMenu: (state, action) => {
      state.reportList = action.payload.list;
      state.reportListData = action.payload.listData;
    },
  },
});
export const { setrReportList, setReportListData, reportListMenu } =
  reportListSlice.actions;
export default reportListSlice.reducer;
