import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Update Profile
export const updateProfile = createAsyncThunk(
  "updateProfile/post",
  async (payload) => {
    const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
    const accountID = localStorage.getItem("atua");
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      var bodyData = {
        name: payload.name,
        displayname: payload.displayname,
        avatar: payload.avatar,
        role: payload.role,
        email: payload.email,
      };
      const res = await axios.put(
        `${basePathConfig.baseURL}/app/account_agent/${user.uid}/account/${accountID}`,
        bodyData,
        config
      );
      //console.log("updateProfile res.data == ", JSON.stringify(res.data));
      var userData = {
        uid: res.data.data._id,
        email: res.data.data.email,
        name: res.data.data.name,
        displayname: res.data.data.displayname,
        avatar: res.data.data.avatar,
      };
      localStorage.removeItem("user"); // deletes user from storage
      localStorage.setItem("user", JSON.stringify(userData));
      return await res.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      //console.log("updateProfile ErrorData == ", JSON.stringify(ErrorData));
      return await ErrorData;
    }
  }
);

// delete File
export const deleteProfile = createAsyncThunk(
  "deleteProfile/post",
  async (selectedFile) => {
    //console.log("deleteProfile selectedFile == ", JSON.stringify(selectedFile));
    try {
      var bodyData = {
        location: selectedFile,
      };
      const res = await axios.post(
        `${basePathConfig.baseURL}/deletemedia`,
        bodyData
      );
      // console.log("deleteProfile res.data == ", JSON.stringify(res.data));
      return await res.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      console.log("deleteProfile ErrorData == ", JSON.stringify(ErrorData));
      return await ErrorData;
    }
  }
);

// upload File
// fetch  Current Chat Details Data
export const uploadProfile = createAsyncThunk(
  "uploadProfile/post",
  async (selectedFile) => {
    //console.log("uploadProfile selectedFile == ", JSON.stringify(selectedFile));
    try {
      const formData = new FormData();

      formData.append("profile", selectedFile);
      const res = await axios.post(
        `${basePathConfig.baseURL}/uploadprofile`,
        formData
      );
      //console.log("uploadProfile res.data == ", JSON.stringify(res.data));
      return await res.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

var initialState = {
  status: STATUSES.SUCCESS,
  uploadData: [],
  uploadImage: "",
  success: "",
  error: "",
  loading: "",
};
export const profileDetailsSlice = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProfile.pending, (state, action) => {
        state.loading = "loading";
      })
      .addCase(deleteProfile.fulfilled, (state, action) => {
        state.uploadImage = action.payload;
        state.success = "success";
      })
      .addCase(deleteProfile.rejected, (state, action) => {
        state.error = "error";
      })
      .addCase(uploadProfile.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(uploadProfile.fulfilled, (state, action) => {
        state.uploadImage = action.payload;
        state.success = "success";
      })
      .addCase(uploadProfile.rejected, (state, action) => {
        state.loading = "loading";
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.uploadData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});
export const { setUploadImage, setStatus, setUploadData, reset } =
  profileDetailsSlice.actions;
export default profileDetailsSlice.reducer;
