import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// fetch  Current Chat Details Data
export const fetchCurrentChat = createAsyncThunk(
  "currentChat/fetch",
  async (conID) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      var payload = {
        account_id: accountID,
        conversation_id: conID,
      };
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/currentchatdetails`,
          payload,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// upload File
// fetch  Current Chat Details Data
export const uploadFileData = createAsyncThunk(
  "uploadfile/post",
  async (selectedFile, { rejectWithValue }) => {
    // console.log("selectedFile == ", selectedFile);
    // console.log("selectedFile[0].file == ", selectedFile.file);
    // console.log("selectedFile[0].channel_id == ", selectedFile.channel_id);
    try {
      const formData = new FormData();

      formData.append("file", selectedFile.file);
      const res = await axios.post(
        `${basePathConfig.baseURL}/uploadmedia/` + selectedFile.channel_id,
        formData
      );
      return await res.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await rejectWithValue(ErrorData);
    }
  }
);

export const uploadPrivateFileData = createAsyncThunk(
  "uploadPrivatefile/post",
  async (selectedFile) => {
    try {
      const formData = new FormData();

      formData.append("file", selectedFile);
      const res = await axios.post(
        `${basePathConfig.baseURL}/uploadmedia`,
        formData
      );
      return await res.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);
export const resolveCurrentChat = createAsyncThunk(
  "resolveChat/post",
  async (conID) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const user = JSON.parse(localStorage.getItem("user"));
      const accountID = localStorage.getItem("atua");
      const agentID = user.uid;
      var payload = {
        account_id: accountID,
        conversation_id: conID,
        agent_id: agentID,
      };
      if (!!bearer_token && !!accountID && !!agentID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/resolve_conversation`,
          payload,
          config
        );

        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

export const chatSettingsSlice = createSlice({
  name: "chatSettings",
  initialState: {
    chatList: "Current",
    chatListData: [],
    conversationId: "",
    conStatus: 0,
    botId: "",
    orgId: "",
    agentId: "",
    channelId: "",
    contactId: "",
    recipientID: "",
    contactData: [],
    conversationData: [],
    mute: false,
    fileData: [],
    filePrivateData: [],
    resolveData: [],
    status: STATUSES.SUCCESS,
    uploadFilestatus: STATUSES.SUCCESS,
    uploadPrivateFilestatus: STATUSES.SUCCESS,
  },
  reducers: {
    chatConversationIdClear: (state, action) => {
      state.conversationId = "";
    },
    chatConversatioStatus: (state, action) => {
      state.conStatus = action.payload.conStatus;
    },
    chatSettingsList: (state, action) => {
      state.chatList = action.payload.name;
      state.chatListData = action.payload.data;
    },
    chatConversation: (state, action) => {
      state.conversationId = action.payload.conversationId;
      state.conStatus = action.payload.conStatus;
      state.botId = action.payload.botId;
      state.orgId = action.payload.orgId;
      state.agentId = action.payload.agentId;
      state.channelId = action.payload.channelId;
      state.contactId = action.payload.contactId;
      state.contactData = action.payload.contactData;
      state.recipientID = action.payload.recipientID;
    },
    muteUnmute: (state, action) => {
      state.mute = action.payload;
    },
    reset_fileData: (state, action) => {
      state.fileData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentChat.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchCurrentChat.fulfilled, (state, action) => {
        state.conversationData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchCurrentChat.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(uploadFileData.pending, (state, action) => {
        state.uploadFilestatus = STATUSES.LOADING;
      })
      .addCase(uploadFileData.fulfilled, (state, action) => {
        state.fileData = action.payload;
        state.uploadFilestatus = STATUSES.SUCCESS;
      })
      .addCase(uploadFileData.rejected, (state, action) => {
        state.uploadFilestatus = STATUSES.ERROR;
        //state.fileData = [];
        state.fileData = action.payload;
      })
      .addCase(uploadPrivateFileData.pending, (state, action) => {
        state.uploadPrivateFilestatus = STATUSES.LOADING;
      })
      .addCase(uploadPrivateFileData.fulfilled, (state, action) => {
        state.filePrivateData = action.payload;
        state.uploadPrivateFilestatus = STATUSES.SUCCESS;
      })
      .addCase(uploadPrivateFileData.rejected, (state, action) => {
        state.uploadPrivateFilestatus = STATUSES.ERROR;
        state.filePrivateData = [];
      })
      .addCase(resolveCurrentChat.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(resolveCurrentChat.fulfilled, (state, action) => {
        state.resolveData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(resolveCurrentChat.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.resolveData = [];
      });
  },
});
export const {
  setChatList,
  chatSettingsList,
  setChatListData,
  setConversationId,
  setConStatus,
  setChannelId,
  setContactData,
  chatConversation,
  setUploadFilestatus,
  setUploadPrivateFilestatus,
  muteUnmute,
  setMute,
  setFileData,
  setFilePrivateData,
  reset_fileData,
  setResolveData,
  chatConversatioStatus,
  chatConversationIdClear,
} = chatSettingsSlice.actions;
export default chatSettingsSlice.reducer;
