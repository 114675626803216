import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";
// Thunks
// fetch all Canned Responses Data
export const fetchAllCannedResponses = createAsyncThunk(
  "cannedResponses/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.get(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/canned-reponses`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const addCannedResponces = createAsyncThunk(
  "cannedResponses/add",
  async (payloadData) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/canned-reponses`,
          payloadData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const editCannedResponces = createAsyncThunk(
  "cannedResponses/edit",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const payloadData = {
          short_code: payload.short_code,
          content: payload.content,
        };
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.put(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/canned-reponses/${payload.id}`,
          payloadData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const deleteCannedResponces = createAsyncThunk(
  "cannedResponses/delete",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.delete(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/canned-reponses/${payload.id}`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data: [],
  addData: [],
  editData: [],
  deleteData: [],
  status: STATUSES.SUCCESS,
};

export const cannedResponsesSlice = createSlice({
  name: "cannedResponses",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCannedResponses.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllCannedResponses.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAllCannedResponses.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(addCannedResponces.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addCannedResponces.fulfilled, (state, action) => {
        state.addData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(addCannedResponces.rejected, (state, action) => {
        state.addData = [];
        state.status = STATUSES.ERROR;
      })
      .addCase(editCannedResponces.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(editCannedResponces.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(editCannedResponces.rejected, (state, action) => {
        state.editData = [];
        state.status = STATUSES.ERROR;
      })
      .addCase(deleteCannedResponces.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteCannedResponces.fulfilled, (state, action) => {
        state.deleteData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(deleteCannedResponces.rejected, (state, action) => {
        state.deleteData = [];
        state.status = STATUSES.ERROR;
      });
  },
});
export const { setCannedResponses, setStatus, setAddData, reset } =
  cannedResponsesSlice.actions;
export default cannedResponsesSlice.reducer;
