import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
export const updatePasswordAgent = createAsyncThunk(
  "updatePasswordAgent/fetch",
  async (payload) => {
    try {
      const res = await axios.put(
        `${basePathConfig.baseURL}/passwordconfirmation`,
        payload
      );

      return await res.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// fetch Agent Data
export const fetchAgent = createAsyncThunk("agent/fetch", async (Id) => {
  try {
    const res = await axios.get(
      `${basePathConfig.baseURL}/super/account_agent/${Id}/fetch_agent`
    );

    return await res.data.data;
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

export const updatePasswordAgentStatus = createAsyncThunk(
  "updatePasswordAgentStatus/put",
  async (payload) => {
    try {
      const res = await axios.post(
        `${basePathConfig.baseURL}/expirpasswordlink`,
        payload
      );

      return await res.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});
export const passwordConfirmationSlice = createSlice({
  name: "password_Confirmation",
  initialState: {
    data: [],
    agentData: [],
    updateStatusData: [],
    status: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgent.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAgent.fulfilled, (state, action) => {
        state.agentData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAgent.rejected, (state, action) => {
        state.agentData = [];
        state.status = STATUSES.ERROR;
      })
      .addCase(updatePasswordAgent.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updatePasswordAgent.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(updatePasswordAgent.rejected, (state, action) => {
        state.data = [];
        state.status = STATUSES.ERROR;
      })
      .addCase(updatePasswordAgentStatus.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updatePasswordAgentStatus.fulfilled, (state, action) => {
        state.updateStatusData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(updatePasswordAgentStatus.rejected, (state, action) => {
        state.updateStatusData = [];
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setData, setAgentData, setStatus, setUpdateStatusData } =
  passwordConfirmationSlice.actions;
export default passwordConfirmationSlice.reducer;
