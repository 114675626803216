import React from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAgentStatus } from "../../../../../redux/slices/conversationalActions";
import { fun_LoginLogout } from "../../../../../redux/slices/socketsSlice";
import QueueIcon from "@mui/icons-material/Queue";
const Typography = styled(MuiTypography)(spacing);

const ListContainer = styled(List)`
  overflow-y: scroll;
  height: calc(80vh - 94px);
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const NoConversation = styled(`div`)`
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 1rem;
`;
const MessageIcons = styled(QueueIcon)`
  font-size: 4rem;
  color: ${(props) => props.theme.sidebar.footer.online.background};
`;

const QueueConfirmationList = ({ QueueChatsList, socket }) => {
  const dispatch = useDispatch();
  const { todaysChat } = useSelector((state) => state.socketsSlice);
  const user = JSON.parse(localStorage.getItem("user"));
  const role = localStorage.getItem("atur");
  var [chatListData, setChatListData] = React.useState([]);

  React.useEffect(() => {
    !!QueueChatsList && setChatListData(QueueChatsList);
  }, [QueueChatsList]);
  React.useEffect(() => {
    dispatch(fetchAllAgentStatus());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(fun_LoginLogout());
  }, [dispatch]);

  React.useEffect(() => {
    setChatListData([]);
    !!role && role === "638f1029a1c0997b2085c2a2"
      ? !!todaysChat &&
        !!todaysChat.data &&
        todaysChat.data.map(
          (item, i) =>
            !!item.chatAssign &&
            parseInt(item.is_queue_chat) === 1 &&
            parseInt(item.is_queue_confirm) === 1 &&
            //   item.chatAssign[].assignee_id === user.uid &&
            item.chatAssign.map((chatitem) =>
              //chatitem.assignee_id === user.uid &&
              setChatListData((chatListData) => [...chatListData, item])
            )
        )
      : !!todaysChat &&
        !!todaysChat.data &&
        todaysChat.data.map(
          (item, i) =>
            !!item.chatAssign &&
            parseInt(item.is_queue_chat) === 1 &&
            parseInt(item.is_queue_confirm) === 1 &&
            //   item.chatAssign[].assignee_id === user.uid &&
            item.chatAssign.map(
              (chatitem) =>
                chatitem.assignee_id === user.uid &&
                setChatListData((chatListData) => [...chatListData, item])
            )
        );
  }, [role, todaysChat, user.uid]);

  return (
    <>
      <Card mb={5}>
        <CardContent>
          {!!chatListData && chatListData.length !== 0 ? (
            <>
              <ListContainer>
                {!!chatListData &&
                  chatListData.map((item, i) => (
                    <ListItem button key={i}>
                      <ListItemIcon>
                        <Online
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={item.contactDetails[0].name}
                            src={item.contactDetails[0].photo}
                          />
                        </Online>
                      </ListItemIcon>
                      <ListItemText
                        primary={item.contactDetails[0].name}
                        secondary={
                          item.channel_id &&
                          item.channel_id.split("_")[0].length > 10
                            ? item.channel_id
                                .split("_")[0]
                                .toString()
                                .substring(0, 15) + "..."
                            : item.channel_id.split("_")[0]
                        }
                      />
                      <Typography
                        gutterBottom
                        color="primary"
                        style={{ width: "200px" }}
                      >
                        {`This conversation assigned to ${item.chatAssign[0].agentData[0].name}`}
                      </Typography>
                    </ListItem>
                  ))}
              </ListContainer>
            </>
          ) : (
            <NoConversation>
              <MessageIcons />
              Uh oh! Looks like there are no messages in queue.
            </NoConversation>
          )}
        </CardContent>
      </Card>
    </>
  );
};
const QueueConfirmationChats = ({
  openAssigedQueueAdd,
  handleAddAssigedQueueClose,
  socket,
}) => {
  return (
    <>
      <Dialog
        open={openAssigedQueueAdd.Open}
        onClose={handleAddAssigedQueueClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">
          <DialogContentText>
            <Typography gutterBottom>
              <CloseIcon
                onClick={handleAddAssigedQueueClose}
                style={{ cursor: "pointer" }}
              />
              Assigned Queue Chats
            </Typography>
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <QueueConfirmationList
            QueueChatsList={openAssigedQueueAdd.data}
            socket={socket}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QueueConfirmationChats;
