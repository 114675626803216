import React from "react";
import styled from "@emotion/styled";
import {
  Button,
  Card,
  CardMedia as MuiCardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
//import ReactPlayer from "react-player";
import { Player } from "video-react";
import AudioPlayer from "react-h5-audio-player";
const ChatMediaDialog = ({ openAdd, handleClickClose }) => {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const CardMedia = styled(MuiCardMedia)`
    weight: 100%;
    height: 80vh;
    cursor: pointer;
  `;
  return (
    <>
      <Dialog
        open={openAdd.Open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Card>
              {openAdd.MediaType === "image" && (
                <CardMedia
                  image={openAdd.MediaUrl}
                  title="Contemplative Reptile"
                />
              )}
              {openAdd.MediaType === "video" && (
                <Player
                  playsInline
                  //poster="static/img/samples/videoPoster.jpg"
                  src={openAdd.MediaUrl}
                  autoPlay
                />
              )}
              {openAdd.MediaType === "audio" && (
                <AudioPlayer
                  autoPlay={true}
                  src={openAdd.MediaUrl}
                  onPlay={(e) => console.log("onPlay")}
                />
              )}
            </Card>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary">
            Close
          </Button>
          {/* <Button onClick={handleClickClose} color="primary" autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatMediaDialog;
