import React from "react";
import { useSelector } from "react-redux";
import AgentReport from "./Agents/AgentReport";
import ChanelsReport from "./Chanels/ChanelsReport";
import CSATReport from "./CSAT/CSATReport";
import Labels from "./Labels/Labels";
import OverviewReports from "./Overview/OverviewReports";
import SignInSignOutReport from "./SignInSignOut/SignInSignOutReport";

const RightReportsPannel = () => {
  const { reportList } = useSelector((state) => state.reportList);
  const [list, setList] = React.useState("");
  React.useEffect(() => {
    setList(reportList);
  }, [reportList]);

  return (
    <>
      {!!list && list === "overview_report" && <OverviewReports />}
      {!!list && list === "csat_report" && <CSATReport />}
      {!!list && list === "labels" && <Labels />}
      {!!list && list === "agents" && <AgentReport />}
      {!!list && list === "chanels" && <ChanelsReport />}
      {!!list && list === "signin_signout" && <SignInSignOutReport />}
    </>
  );
};

export default RightReportsPannel;
