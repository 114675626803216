import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch Single CSAT_Rating Data
export const fetchCSATRating = createAsyncThunk(
  "csat/fetch",
  async (payload) => {
    try {
      if (!!payload.account_id) {
        const res = await axios.get(
          `${basePathConfig.baseURL}/app/account/${payload.account_id}/csat/${payload.csat_id}`
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// update CSAT_Rating
export const postCSATRating = createAsyncThunk("csat/put", async (payload) => {
  try {
    if (!!payload.account_id) {
      const res = await axios.put(
        `${basePathConfig.baseURL}/app/csat`,
        payload
      );
      return await res.data;
    } else {
      return { message: "Unauthorized", status: 401 };
    }
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

export const csatRatingSlice = createSlice({
  name: "csatRating",
  initialState: {
    fetchData: [],
    data: [],
    status: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCSATRating.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchCSATRating.fulfilled, (state, action) => {
        state.fetchData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchCSATRating.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.fetchData = [];
      })
      .addCase(postCSATRating.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(postCSATRating.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(postCSATRating.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.data = [];
      });
  },
});
export const { setFetchData, setData, setStatus } = csatRatingSlice.actions;
export default csatRatingSlice.reducer;
