import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";

export const fetchBotLogs = createAsyncThunk(
  "botlogs/fetchBotLogs",
  async (dataPayload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const response = await fetch(`${basePathConfig.baseURL}/botlogs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearer_token,
        },
        body: JSON.stringify(dataPayload),
      });

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

const initialState = {
  botLogsFetchData: {
    data: {},
    status: "",
    error: {},
  },
};

export const botlogsSlice = createSlice({
  name: "botlogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBotLogs.pending, (state) => {
        // fetch
        state.botLogsFetchData = {
          status: "loading",
          data: {},
          error: {},
        };
      })
      .addCase(fetchBotLogs.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.botLogsFetchData = {
            status: "failed",
            data: {},
            error: action.payload.error,
          };
        } else {
          state.botLogsFetchData = {
            status: "succeeded",
            data: action.payload,
            error: {},
          };
        }
      })
      .addCase(fetchBotLogs.rejected, (state, action) => {
        state.botLogsFetchData = {
          status: "failed",
          data: {},
          error: action.payload,
        };
      });
  },
});

export default botlogsSlice.reducer;
