import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch report for CSAT Data
export const fetchCsatReport = createAsyncThunk(
  "csatAllReport/fetchAllData",
  async (PayloadData, thunkAPI) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const data = {
          account_id: accountID.toString(),
          day_label: PayloadData.dayID,
          start_date: PayloadData.start_date,
          end_date: PayloadData.end_date,
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/csat-reports`,
          data,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return thunkAPI.rejectWithValue(await ErrorData);
    }
  }
);
export var STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

var initialState = {
  csatReportdata: [],
  csatStatus: STATUSES.SUCCESS,
};
export const reportCSATSlice = createSlice({
  name: "reportCSAT",
  // initialState: {
  //   csatReportdata: [],
  //   csatStatus: STATUSES.SUCCESS,
  // },
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(fetchCsatReport.pending, (state, action) => {
      //   state.csatReportdata = [];
      //   state.csatStatus = STATUSES.LOADING;
      // })
      // .addCase(fetchCsatReport.fulfilled, (state, action) => {
      //   state.csatReportdata = action.payload;
      //   state.csatStatus = STATUSES.SUCCESS;
      // })
      // .addCase(fetchCsatReport.rejected, (state, action) => {
      //   state.csatReportdata = [];
      //   state.csatStatus = STATUSES.ERROR;
      // })
      .addCase(fetchCsatReport.pending, (state, action) => {
        state.csatStatus = STATUSES.LOADING;
      })
      .addCase(fetchCsatReport.fulfilled, (state, action) => {
        state.csatReportdata = action.payload;
        state.csatStatus = STATUSES.SUCCESS;
      })
      .addCase(fetchCsatReport.rejected, (state, action) => {
        state.csatReportdata = [];
        state.csatStatus = STATUSES.ERROR;
      });
  },
});

export const { setCsatReportdata, setCsatStatus } = reportCSATSlice.actions;
export default reportCSATSlice.reducer;
