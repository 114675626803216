import React from "react";
import styled from "@emotion/styled";
import { Popover as MuiPopover } from "@mui/material";

import EmojiPicker from "emoji-picker-react";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const EmojiComponent = ({ isOpen, handleClose, onClick }) => {
  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        //anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        className="App"
      >
        <div className="App">
          <EmojiPicker onEmojiClick={onClick} autoFocusSearch={false} />
        </div>
      </Popover>
    </>
  );
};

export default EmojiComponent;
