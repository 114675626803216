import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settingsList: "canned_responses",
    settingsData: [],
  },
  reducers: {
    settingsMenu: (state, action) => {
      state.settingsList = action.payload.list;
      state.settingsData = action.payload.listData;
    },
  },
});
export const { setSettingsList, setSettingsData, settingsMenu } =
  settingsSlice.actions;
export default settingsSlice.reducer;
