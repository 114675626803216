// export default SettingsList;
import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  List,
  ListItemButton,
  ListItemText as MuiListItemText,
  //ListItemIcon,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CodeIcon from "@mui/icons-material/Code";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useDispatch, useSelector } from "react-redux";
import { settingsMenu } from "../../../../redux/slices/settings";
import { isValidToken } from "../../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../redux/slices/login";
import { useNavigate } from "react-router-dom";

const ListItemText = styled(MuiListItemText)(spacing);
const LiButton = styled(ListItemButton)`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBoldTop};
`;

const SettingsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listName, setListName] = React.useState("canned_responses");
  const { settingsList } = useSelector((state) => state.settings);
  const callList = (name) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      setListName(name);
      dispatch(
        settingsMenu({
          list: name,
          listData: [],
        })
      );
    }
  };

  React.useEffect(() => {
    !!settingsList && setListName(settingsList);
  }, [settingsList]);
  return (
    <>
      <List component="nav" dense={true}>
        {/* //canned_responses */}
        {!!listName && listName === "canned_responses" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("canned_responses");
            }}
          >
            <QuestionAnswerIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Canned Responses" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("canned_responses");
            }}
          >
            <QuestionAnswerIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Canned Responses" />
          </LiButton>
        )}
        {/* //labels */}
        {!!listName && listName === "labels" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("labels");
            }}
          >
            <LocalOfferIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Labels" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("labels");
            }}
          >
            <LocalOfferIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Labels" />
          </LiButton>
        )}
        {/* //companyattributes */}
        {!!listName && listName === "companyattributes" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("companyattributes");
            }}
          >
            <CodeIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Company Attributes" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("companyattributes");
            }}
          >
            <CodeIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Company Attributes" />
          </LiButton>
        )}
        {/* //agents */}
        {!!listName && listName === "agents" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("agents");
            }}
          >
            <PeopleIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Agents" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("agents");
            }}
          >
            <PeopleIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Agents" />
          </LiButton>
        )}
        {/* //accountsettings */}
        {!!listName && listName === "accountsettings" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("accountsettings");
            }}
          >
            <SettingsSuggestIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Account Settings" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("accountsettings");
            }}
          >
            <SettingsSuggestIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Account Settings" />
          </LiButton>
        )}
      </List>
    </>
  );
};

export default SettingsList;
