import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// fetch all labels Data
export const fetchAnswer = createAsyncThunk(
  "answer/fetch",
  async (Question) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const data = {
          keyword: Question,
        };
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/chatgpt`,
          data,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

export const chatGTPSlice = createSlice({
  name: "chatGTP",
  initialState: {
    chatGTPAnswer: [],
    status: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnswer.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAnswer.fulfilled, (state, action) => {
        state.chatGTPAnswer = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAnswer.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});
export const { setChatGTPAnswer, setStatus } = chatGTPSlice.actions;
export default chatGTPSlice.reducer;
