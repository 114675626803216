import React from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Button as MuiButton,
  Typography,
  List,
  ListItem as ListItemListData,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllAgentStatus,
  fetchJoinChatAgentStatus,
  fetchJoinChatStatus,
} from "../../../../../redux/slices/conversationalActions";
import { fun_LoginLogout } from "../../../../../redux/slices/socketsSlice";
const ListItemText = styled(MuiListItemText)(spacing);
const Button = styled(MuiButton)(spacing);
const ListItemList = styled(ListItemListData)`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(2)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  cursor: pointer;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const FooterBadgeBuzy = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.theme.sidebar.footer.buzy.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const FooterBadgePause = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.theme.sidebar.footer.pause.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const JoinCall = ({ socket }) => {
  const { agentStatus, joinChatAgentStatus } = useSelector(
    (state) => state.conversationalAction
  );
  const { conversationId, botId, orgId, channelId, contactId, contactData } =
    useSelector((state) => state.chatSettings);
  const { loginLogoutData, chatFromoneSpocData } = useSelector(
    (state) => state.socketsSlice
  );
  var [loginStatus, setLoginStatus] = React.useState([]);

  const dispatch = useDispatch();
  var [agent, setAgent] = React.useState("");
  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
  }, []);

  React.useEffect(() => {
    dispatch(fetchAllAgentStatus());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(fun_LoginLogout());
  }, [dispatch]);

  React.useEffect(() => {
    !!agentStatus && setLoginStatus(agentStatus);
  }, [agentStatus]);

  React.useEffect(() => {
    !!loginLogoutData && setLoginStatus(loginLogoutData);
  }, [loginLogoutData]);

  React.useEffect(() => {
    !!conversationId && dispatch(fetchJoinChatStatus(conversationId));
  }, [conversationId, dispatch, loginLogoutData]);

  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
  }, []);
  React.useEffect(() => {
    dispatch(fetchJoinChatAgentStatus(conversationId));
  }, [conversationId, dispatch]);
  React.useEffect(() => {
    dispatch(fetchJoinChatAgentStatus(conversationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatFromoneSpocData]);

  const sendChatRemoveAssignMessage = async (agentName, assigneeID) => {
    // var msg = `This conversation is assigned to  ${agentName} by ${agent.displayname}`;
    var msg = `${agent.displayname} remove  ${agentName} from join conversation`;

    var messagePayload = {
      data: {
        flag: "note",
        channel_id: channelId,
        bot_id: botId,
        org_id: orgId,
        account_id: localStorage.getItem("atua"),
        conversation_id: conversationId,
        agent_id: agent.uid,
        agentName: agent.displayname,
        assign_id: assigneeID,
        contact_id: contactId,
        contact: contactData,
        label_id: "",
        message: [
          {
            content_type: 9,
            content: msg,
            media: {
              media_type: "",
              media_path: "",
            },
            message_type: "text",
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };

    socket.current.emit("chatSentFromApp", messagePayload);
  };

  const sendChatAssignMessage = async (agentName, assigneeID) => {
    // var msg = `This conversation is assigned to  ${agentName} by ${agent.displayname}`;
    var msg = `${agent.displayname} added  ${agentName} in join conversation`;
    var messagePayload = {
      data: {
        flag: "note",
        channel_id: channelId,
        bot_id: botId,
        org_id: orgId,
        account_id: localStorage.getItem("atua"),
        conversation_id: conversationId,
        agent_id: agent.uid,
        agentName: agent.displayname,
        assign_id: assigneeID,
        contact_id: contactId,
        contact: contactData,
        label_id: "",
        message: [
          {
            content_type: 10,
            content: msg,
            media: {
              media_type: "",
              media_path: "",
            },
            message_type: "text",
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };

    socket.current.emit("chatSentFromApp", messagePayload);
  };
  return (
    <>
      <List>
        {!!loginStatus && !!loginStatus.data ? (
          loginStatus.data.map(
            (item, i) =>
              !!item.signinsignout[0] &&
              !!item.signinsignout[0].availability[0] &&
              item.signinsignout[0].availability[0].isAvailable > 0 && (
                <ListItemList key={i}>
                  {!!item.signinsignout[0].availability[0] &&
                    item.signinsignout[0].availability[0].isAvailable === 1 && (
                      <>
                        <FooterBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={item.displayname}
                            src={item.avatar}
                            style={{
                              marginRight: "5px",
                              width: "30px",
                              height: "30px",
                            }}
                          ></Avatar>
                        </FooterBadge>
                        <ListItemText
                          primary={item.displayname}
                          secondary="Online"
                        />
                      </>
                    )}

                  {!!item.signinsignout[0].availability[0] &&
                    item.signinsignout[0].availability[0].isAvailable === 2 && (
                      <>
                        <FooterBadgeBuzy
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={item.displayname}
                            src={item.avatar}
                            style={{
                              marginRight: "5px",
                              width: "30px",
                              height: "30px",
                            }}
                          ></Avatar>
                        </FooterBadgeBuzy>
                        <ListItemText
                          primary={item.displayname}
                          secondary="Buzy"
                        />
                      </>
                    )}

                  {!!item.signinsignout[0].availability[0] &&
                    item.signinsignout[0].availability[0].isAvailable === 3 && (
                      <>
                        <FooterBadgePause
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={item.displayname}
                            src={item.avatar}
                            style={{
                              marginRight: "5px",
                              width: "30px",
                              height: "30px",
                            }}
                          ></Avatar>
                        </FooterBadgePause>
                        <ListItemText
                          primary={item.displayname}
                          secondary="Pause"
                        />
                      </>
                    )}

                  {/* {!!agent && agent.uid !== item._id && (
                    <>
                      <Button
                        mr={2}
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={() => {
                          sendChatAssignMessage(item.displayname, item._id);
                        }}
                      >
                        Add
                      </Button>
                      <Button
                        mr={2}
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={() => {
                          sendChatRemoveAssignMessage(
                            item.displayname,
                            item._id
                          );
                        }}
                      >
                        Remove
                      </Button>
                    </>
                  )} */}

                  {!!joinChatAgentStatus &&
                    joinChatAgentStatus.map(
                      (chatItem) =>
                        !!chatItem &&
                        !!agent &&
                        agent.uid !== item._id &&
                        chatItem.agent_id === item._id &&
                        chatItem.isJoinChat === 1 && (
                          <Button
                            mr={2}
                            variant="contained"
                            size="small"
                            color="success"
                            onClick={() => {
                              sendChatRemoveAssignMessage(
                                item.displayname,
                                item._id
                              );
                            }}
                          >
                            Remove
                          </Button>
                        )
                    )}
                  {!!joinChatAgentStatus &&
                    joinChatAgentStatus.map(
                      (chatItem) =>
                        !!chatItem &&
                        !!agent &&
                        agent.uid !== item._id &&
                        chatItem.agent_id === item._id &&
                        chatItem.isJoinChat === 0 && (
                          <Button
                            mr={2}
                            variant="contained"
                            size="small"
                            color="success"
                            onClick={() => {
                              sendChatAssignMessage(item.displayname, item._id);
                            }}
                          >
                            Add
                          </Button>
                        )
                    )}
                </ListItemList>
              )
          )
        ) : (
          <Typography>
            There are no Previous Conversations associated to this Contacts.
          </Typography>
        )}
      </List>
    </>
  );
};

export default JoinCall;
