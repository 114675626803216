import React from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Typography,
  List,
  ListItem as ListItemListData,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchPreviousConversation } from "../../../../../redux/slices/conversationalActions";
import ChatContactHistory from "./ChatContactHistory";
const ListItemText = styled(MuiListItemText)(spacing);
const ListItemList = styled(ListItemListData)`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(2)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  cursor: pointer;
`;

const PreviousConversation = () => {
  const { previousHistory } = useSelector(
    (state) => state.conversationalAction
  );
  const { contactData } = useSelector((state) => state.chatSettings);
  const { chatFromoneSpocData } = useSelector((state) => state.socketsSlice);

  const [contactID, setContactID] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    setContactID(contactData._id);
  }, [contactData]);
  React.useEffect(() => {
    !!contactID && dispatch(fetchPreviousConversation(contactID));
  }, [contactID, dispatch, chatFromoneSpocData, contactData]);

  // show chat history
  const [openAdd, setOpenAdd] = React.useState({
    Open: false,
    data: "",
  });
  const handleAddClickOpen = (item) => {
    setOpenAdd({
      Open: true,
      data: item,
    });
  };

  const handleAddClose = () => {
    setOpenAdd({
      Open: false,
      data: "",
    });
  };

  // Helper Datetime function here
  const formatDate = (createdAt) => {
    const date = moment.utc(createdAt).add(5, "hours").add(30, "minutes");

    return date.isSame(moment(), "day")
      ? date.format("hh:mm A")
      : date.format("DD-MMM-YY");
  };
  return (
    <>
      <List
        sx={{
          padding: "0px", // Padding
          maxHeight: "150px", // Maximum height to trigger scrolling
          overflowY: "auto", // Enable vertical scrollbar when content overflows
          borderRadius: "4px", // Border radius
        }}
      >
        {!!previousHistory ? (
          previousHistory.map((item, i) => (
            <ListItemList
              key={i}
              onClick={() => {
                handleAddClickOpen(item);
              }}
              sx={{
                marginBottom: "2px!important",
                //border: "1px solid rgba(0, 21, 45, 0.3)",
                boxShadow: "0px 4px 6px rgba(0, 0, 1, 0.1)",
                transition: "all 0.3s ease", // Smooth transition for hover effects
                "&:hover": {
                  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
                  backgroundColor: "rgba(231, 242, 255, 0.9)!important",
                },
              }}
            >
              <Avatar
                alt={item.contactData[0].name}
                src={item.contactData[0].photo}
                style={{ marginRight: "15px" }}
              ></Avatar>
              <ListItemText
                primary={item.contactData[0].name}
                secondary={
                  formatDate(item.created_at) +
                  " ( " +
                  item.channel_id.split("_")[0] +
                  " ) "
                }
              />
            </ListItemList>
          ))
        ) : (
          <Typography>
            There are no Previous Conversations associated to this Contacts.
          </Typography>
        )}
      </List>
      <ChatContactHistory
        openAdd={openAdd}
        handleAddClose={handleAddClose}
        handleAddClickOpen={handleAddClickOpen}
      />
    </>
  );
};

export default PreviousConversation;
