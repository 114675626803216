import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch all labels Data
export const fetchAllAgents = createAsyncThunk("agents/fetch", async () => {
  try {
    const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
    const accountID = localStorage.getItem("atua");
    if (!!bearer_token && !!accountID) {
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const res = await axios.get(
        `${basePathConfig.baseURL}/app/account_agent/account/${accountID}`,
        config
      );
      return await res.data;
    } else {
      return { message: "Unauthorized", status: 401 };
    }
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

export const agentsSlice = createSlice({
  name: "agents",
  initialState: {
    data: [],
    status: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAgents.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllAgents.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAllAgents.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});
export const { setAgents, setStatus } = agentsSlice.actions;
export default agentsSlice.reducer;
