import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";

import {
  IconButton as MuiIconButton,
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAgent,
  updatePasswordAgent,
  updatePasswordAgentStatus,
} from "../../redux/slices/passwordConfirmation";
const Alert = styled(MuiAlert)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const timeOut = (time) => new Promise((res) => setTimeout(res, time));

function PasswordConfirmations() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [checkExpireLink, setCheckExpireLink] = React.useState(true);
  const { passwordconfirmation } = useParams();
  const { agentData } = useSelector((state) => state.password_Confirmation);

  const passwordconfirmationData = passwordconfirmation.split(".");
  // console.log("passwordconfirmation split ", passwordconfirmationData);
  var account_id = passwordconfirmationData[1];
  var id = passwordconfirmationData[2];
  var [agent, setAgent] = React.useState([]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  React.useEffect(() => {
    dispatch(fetchAgent(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    !!agentData && setAgent(agentData);
  }, [agentData]);

  //console.log("agentData = ", JSON.stringify(agentData));
  var startTime = moment(agentData.passwordupdate_at);
  var endTime = moment(new Date());

  function calculateDuration(startDate, endDate) {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);

    const duration = moment.duration(endMoment.diff(startMoment));

    const days = duration.asDays();
    const hours = duration.asHours();
    const minutes = duration.asMinutes();
    const seconds = duration.asSeconds();

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  function checkLinkStatus(agentStatus, account_id, agentId) {
    const duration = calculateDuration(startTime, endTime);

    //console.log("duration == " + JSON.stringify(duration));
    //console.log("Test ", agentStatus);
    if (parseInt(agentStatus) === 0) {
      setCheckExpireLink(false);
      if (parseInt(duration.days) > 0) {
        setCheckExpireLink(true);
        dispatch(
          updatePasswordAgentStatus({
            account_id: account_id,
            agent_id: agentId,
          })
        );
      } else {
        if (parseInt(duration.minutes) > 60) {
          setCheckExpireLink(true);
          dispatch(
            updatePasswordAgentStatus({
              account_id: account_id,
              agent_id: agentId,
            })
          );
        }
      }
    } else {
      setCheckExpireLink(true);
    }
  }

  React.useEffect(() => {
    !!agentData && setAgent(agentData);
    !!agentData && checkLinkStatus(agentData.pstatus, account_id, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(6, "Password is too short - should be 6 chars minimum.")
          .max(255)
          .matches(
            /[a-zA-Z]/,
            "Password can only contain alphanumeric characters."
          )
          .required("Required"),
        confirmPassword: Yup.string().when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const bodyData = {
            account_id: account_id,
            agent_id: id,
            email: agent.email,
            password: values.password,
          };
          await timeOut(1500);
          await dispatch(updatePasswordAgent(bodyData));
          resetForm();
          setStatus({ sent: true });
          setSubmitting(false);
          navigate("/");
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}

          {checkExpireLink && (
            <Alert mb={4} severity="error">
              Reset Password Link Is Expired!
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email address"
            value={!!agent && !!agent.email ? agent.email : ""}
            fullWidth
            readOnly={true}
            my={3}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
            my={3}
          />
          <TextField
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={toggleConfirmPasswordVisibility}
                  edge="end"
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            }}
            my={3}
          />
          {checkExpireLink ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled="true"
            >
              Set Password
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Set Password
            </Button>
          )}
        </form>
      )}
    </Formik>
  );
}

export default PasswordConfirmations;
