import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";

const ChartWrapper = styled.div`
  height: 50px;
  width: 100%;
`;

const BarChart = ({ theme, csatReportdata }) => {
  const RatingData =
    !!csatReportdata && !!csatReportdata.rating_Count
      ? csatReportdata.rating_Count
      : [];

  const data = [
    {
      name: "Rating 0",
      data: [!!RatingData ? parseInt(RatingData.rating_0) : 0],
    },
    {
      name: "Rating 1",
      data: [!!RatingData ? parseInt(RatingData.rating_1) : 0],
    },
    {
      name: "Rating 2",
      data: [!!RatingData ? parseInt(RatingData.rating_2) : 0],
    },
    {
      name: "Rating 3",
      data: [!!RatingData ? parseInt(RatingData.rating_3) : 0],
    },
    {
      name: "Rating 4",
      data: [!!RatingData ? parseInt(RatingData.rating_4) : 0],
    },
    {
      name: "Rating 5",
      data: [!!RatingData ? parseInt(RatingData.rating_5) : 0],
    },
  ];

  const options = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 2,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["Ratings"],
      labels: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 50,
    },
    colors: [
      theme.palette.primary.light,
      theme.palette.primary.dark,
      theme.palette.info.light,
      theme.palette.warning.dark,
      theme.palette.error.dark,
      theme.palette.success.dark,
    ],
  };

  return (
    <>
      <ChartWrapper style={{ height: "130px" }}>
        <Chart options={options} series={data} type="bar" height="120" />
      </ChartWrapper>
    </>
  );
};

export default withTheme(BarChart);
