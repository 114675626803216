import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  AlertTitle,
  Box,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount } from "../../../../redux/slices/account";
//import UnAuthenticated from "../../UnAuthenticated";
const Alert = styled(MuiAlert)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)`
  margin-top: 5px;
  margin-right: 5px;
  padding: 11px;
`;
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Ptypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
`;

const AccountSettings = () => {
  const dispatch = useDispatch();
  const { data: account, status } = useSelector((state) => state.account);
  React.useEffect(() => {
    dispatch(fetchAccount());
  }, [dispatch]);
  return (
    <>
      {/* <UnAuthenticated isAuthenticateData={!!account ? account : []} /> */}
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <SettingsSuggestIcon /> Account Settings
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        {!!status && status === "loading" && (
          <CircularProgress m={2} color="primary" />
        )}
        {!!account && !!account.status && parseInt(account.status) !== 200 && (
          <Alert mb={4} severity="error">
            <AlertTitle>Error: (status-code : {account.status})</AlertTitle>
            {account.message} — <strong>check it out!</strong>
          </Alert>
        )}

        {!!status && status === "error" && (
          <Alert mb={4} severity="error">
            <AlertTitle>Error: </AlertTitle>
            <strong>Something went wrong</strong>
          </Alert>
        )}
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Grid container>
                  <Grid item xs={12} lg={4}>
                    <Typography gutterBottom variant="h5" component="h2">
                      General settings
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Ptypography component="p">Account Name</Ptypography>
                    <TextField
                      name="account_name"
                      label=""
                      value={!!account && account.data && account.data.name}
                      fullWidth
                      type="text"
                      variant="filled"
                      readOnly={true}
                      my={2}
                    />
                    <Ptypography component="p">
                      Conversation continuity with emails is enabled for your
                      account.
                    </Ptypography>
                    <Ptypography component="p">
                      Number of days after a ticket should auto resolve if there
                      is no activity
                    </Ptypography>
                    <TextField
                      name="resolve"
                      label=""
                      value={
                        !!account &&
                        account.data &&
                        account.data.auto_resolve_duration
                      }
                      fullWidth
                      type="number"
                      variant="filled"
                      readOnly={true}
                      my={2}
                    />
                  </Grid>
                </Grid>
                <Divider />

                <Grid container my={5}>
                  <Grid item xs={12} lg={4}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Account ID
                    </Typography>
                    <Ptypography component="p">
                      This ID is required if you are building an API based
                      integration
                    </Ptypography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Ptypography component="p">Account ID</Ptypography>
                    <TextField
                      name="AccountID"
                      label=""
                      value={!!account && account.data && account.data._id}
                      fullWidth
                      type="text"
                      variant="filled"
                      readOnly={true}
                      my={2}
                    />
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Ptypography component="p"></Ptypography>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Ptypography component="p"></Ptypography>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    {/* <Ptypography
                      component="p"
                      style={{
                        color: "#073366",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      This account created by 1SPOC.{" "}
                    </Ptypography> */}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                }}
              >
                {/* <Typography gutterBottom variant="h5" component="h2">
                  Account Settings
                </Typography>
                <Ptypography component="p">
                  Canned Responses are saved reply templates which can be used
                  to quickly send out a reply to a conversation
                </Ptypography>
                <Ptypography component="p">
                  For creating a Canned Response, just click on theAdd Canned
                  Response. You can also edit or delete an existing Canned
                  Response by clicking on the Edit or Delete button.
                </Ptypography>
                <Ptypography component="p">
                  Canned responses are used with the help of Short Codes. Agents
                  can access canned responses while on a chat by typing '/'
                  followed by the short code.
                </Ptypography> */}
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default AccountSettings;
