import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch report for agent Data
export const fetchAgentReport = createAsyncThunk(
  "agentReport/fetch",
  async (PayloadData) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const data = {
          account_id: accountID.toString(),
          agent_id: PayloadData.agentID,
          day_label: PayloadData.dayID,
          start_date: !!PayloadData.start_date ? PayloadData.start_date : "",
          end_date: !!PayloadData.end_date ? PayloadData.end_date : "",
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/agent-reports`,
          data,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);
export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});
export const reportAgentSlice = createSlice({
  name: "reportAgent",
  initialState: {
    agentReportdata: [],
    agentStatus: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentReport.pending, (state, action) => {
        state.agentReportdata = [];
        state.agentStatus = STATUSES.LOADING;
      })
      .addCase(fetchAgentReport.fulfilled, (state, action) => {
        state.agentReportdata = action.payload;
        state.agentStatus = STATUSES.SUCCESS;
      })
      .addCase(fetchAgentReport.rejected, (state, action) => {
        state.agentReportdata = [];
        state.agentStatus = STATUSES.ERROR;
      });
  },
});

export const { setAgentReportdata, setAgentStatus } = reportAgentSlice.actions;
export default reportAgentSlice.reducer;
