import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import OverviewTab from "./OverviewTab";
import AgentsSessions from "./AgentsSessions";
import { useState } from "react";
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
const TabTables = ({ loginLogoutReportdata }) => {
  const [key, setKey] = React.useState("OverviewReport");
  const [overview, setOverview] = useState("");
  const [overviewdetail, setOverviewdetail] = useState("");
  const [chartoverview, setChartoverview] = useState("");

  React.useEffect(() => {
    !!loginLogoutReportdata &&
      !!loginLogoutReportdata.overview &&
      setOverview(loginLogoutReportdata.overview);
    !!loginLogoutReportdata &&
      !!loginLogoutReportdata.overviewdetail &&
      setOverviewdetail(loginLogoutReportdata.overviewdetail);
    !!loginLogoutReportdata &&
      !!loginLogoutReportdata.chartoverview &&
      setChartoverview(loginLogoutReportdata.chartoverview);
  }, [loginLogoutReportdata]);

  return (
    <>
      <div className="chatinput-section">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab
            eventKey="OverviewReport"
            title="Overview"
            style={{ backgroundColor: "#fff", padding: "5px" }}
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <OverviewTab
                    overview={overview}
                    chartoverview={chartoverview}
                  />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>

          <Tab
            eventKey="AgentSession"
            title="Agent Sessions"
            style={{ backgroundColor: "#fff", padding: "5px" }}
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <AgentsSessions overviewdetail={overviewdetail} />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default TabTables;
