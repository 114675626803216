import React, { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { basePathConfig } from "../../../config";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { Grid } from "@mui/material";
import LeftChatPannel from "./leftPannel/LeftChatPannel";
import RightChatPannel from "./rightPannel/RightChatPannel";
import { useDispatch } from "react-redux";
import CryptoJS from "crypto-js"; // Import crypto-js for hashing
import {
  fun_ChatFromoneSpoc,
  fun_LoginLogout,
  fun_Contact_Upate,
  fun_TodaysChat,
} from "../../../redux/slices/socketsSlice";
import { isValidToken } from "../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../redux/slices/login";
import { useNavigate } from "react-router-dom";
import useTokenExpirationHandler from "../../../utils/useTokenExpirationHandler";

const TopSpacing = styled("div")(spacing);

const ChatPannel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  const socket = useRef(null);
  const processedMessages = useRef(new Set()); // To track processed messages
  const notificationIcon = localStorage.getItem("awlogo");

  useTokenExpirationHandler();

  useEffect(() => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
      return;
    }

    // Initialize socket connection
    socket.current = io(basePathConfig.socketURL, {
      reconnectionDelayMax: 10000,
    });

    socket.current.on("connect", () => {
      console.info(
        `Successfully connected to socket ${basePathConfig.socketURL}`
      );
    });

    const accountID = window.localStorage.getItem("atua");
    socket.current.emit("joinRoom", accountID);

    socket.current.on("chatFromoneSpoc", (data) => {
      const messageKey = CryptoJS.SHA256(
        `${data?.data?.message[0]?.created_at}-${data?.data?.message[0]?.content}`
      ).toString();

      if (processedMessages.current.has(messageKey)) return;

      processedMessages.current.add(messageKey);
      //console.log("New message:", data);

      // Dispatch the action for new messages
      dispatch(fun_ChatFromoneSpoc(data));

      // Show notification only if the tab is not in focus
      if (document.visibilityState === "hidden") {
        sendNotification(
          data?.data?.contact?.name,
          data?.data?.message[0]?.content
        );
      }
    });

    socket.current.on("todaysChat", (data) => {
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      }
      dispatch(fun_TodaysChat(data));
    });

    socket.current.on("loginlogoutstatus", (data) => {
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      }
      dispatch(fun_LoginLogout(data));
    });

    socket.current.on("contact_update", (data) => {
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      }
      dispatch(fun_Contact_Upate(data));
    });

    // Clean up socket and event listeners
    return () => {
      if (socket.current) {
        socket.current.off("chatFromoneSpoc"); // Remove chat listener
        socket.current.disconnect(); // Disconnect socket
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, dispatch, navigate]);

  const sendNotification = (name, content) => {
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications.");
      return;
    }

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const notification = new Notification("Live-Agent", {
          body: `You have a new message from ${name}: ${content}`,
          icon: `${notificationIcon}`,
        });
        // Handle click event to open the tab
        notification.onclick = () => {
          window.focus(); // Bring the tab to focus if it's already open
        };
      }
    });
  };

  return (
    <>
      <Helmet title="Chats" />
      <Grid container>
        <Grid item xs={12} lg={2} className="conversations-counts">
          <TopSpacing gutterBottom className="fullHeightLeft">
            <LeftChatPannel socket={socket} />
          </TopSpacing>
        </Grid>
        <Grid item xs={12} lg={10}>
          <TopSpacing gutterBottom className="fullHeightRight">
            <RightChatPannel socket={socket} />
          </TopSpacing>
        </Grid>
      </Grid>
    </>
  );
};

export default ChatPannel;
