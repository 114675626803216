import React from "react";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Alert as MuiAlert,
  AlertTitle,
  //Checkbox,
  Grid,
  InputBase,
  IconButton,
  Paper as MuiPaper,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  // FormControlLabel,
  // Switch,
  //Button,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import // Add as AddIcon,
// Delete as DeleteIcon,
// Edit as EditIcon,
"@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import { fetchContacts } from "../../../../redux/slices/contact";
import { useDispatch, useSelector } from "react-redux";
//import UnAuthenticated from "../../UnAuthenticated";
import SearchIcon from "@mui/icons-material/Search";
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);
// const timeOut = (time) => new Promise((res) => setTimeout(res, time));
const Avatar = styled(MuiAvatar)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;
const Spacer = styled.div`
  flex: 1 1 50%;
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.tertiary.main};
    color: ${(props) => props.theme.palette.common.black};
    margin-right: 10px;
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const Search = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e7f2ff;
`;

function createData(
  srno,
  id,
  photo,
  name,
  company_name,
  email,
  label_id,
  title,
  color
) {
  return { srno, id, photo, name, company_name, email, label_id, title, color };
}
var rows = [];
var rowsData = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "color",
    numeric: false,
    disablePadding: true,
    label: "Color",
  },
  { id: "photo", numeric: false, disablePadding: true, label: "Pic" },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: true,
    label: "Company Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
];

const EnhancedTableHead = (props) => {
  const {
    //onSelectAllClick,
    order,
    orderBy,
    //numSelected,
    //rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <CustomTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </CustomTableCell> */}
        {headCells.map((headCell, i) => (
          <CustomTableCell
            // key={headCell.id}
            padding="checkbox"
            key={i}
            align={headCell.numeric ? "right" : "left"}
            //padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ contacts, status }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [search, setSearch] = React.useState("");
  React.useEffect(() => {
    setDense(true);
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  let emptyRows = rowsPerPage - (rowsPerPage + 1);

  // rowsData =
  //   !!contacts &&
  //   !!contacts.data &&
  //   (!!contacts.data.length > 0
  //     ? contacts.data.map((item, i) =>
  //         createData(
  //           ++i,
  //           item._id,
  //           item.photo,
  //           item.name,
  //           item.company_name,
  //           item.email,
  //           item.label_id,
  //           !!item.LabelData && item.LabelData.length !== 0
  //             ? item.LabelData[0].title
  //             : "",
  //           !!item.LabelData && item.LabelData.length !== 0
  //             ? item.LabelData[0].color
  //             : ""
  //         )
  //       )
  //     : []);

  // rows =
  //   search === ""
  //     ? rowsData
  //     : rowsData.filter(
  //         (i) =>
  //           i.name.toLowerCase().includes(search.toLowerCase()) ||
  //           i.title.toLowerCase().includes(search.toLowerCase())
  //       );

  rowsData =
    !!contacts &&
    !!contacts.data &&
    (!!contacts.data.length > 0
      ? contacts.data.map((item, i) =>
          createData(
            ++i,
            item._id,
            item.photo,
            item.name,
            item.company_name,
            item.email,
            item.label_id,
            !!item.LabelData && item.LabelData.length !== 0
              ? item.LabelData[0].title
              : "",
            !!item.LabelData && item.LabelData.length !== 0
              ? item.LabelData[0].color
              : ""
          )
        )
      : []);

  rows =
    search === ""
      ? rowsData
      : rowsData.filter(
          (i) =>
            i.name.toLowerCase().includes(search.toLowerCase()) ||
            i.title.toLowerCase().includes(search.toLowerCase())
        );

  const searchFilter = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };
  return (
    <div>
      <Paper>
        <Search>
          <IconButton type="button" sx={{ p: "4px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            // onChange={(e) => {
            //   setSearch(e.target.value);
            // }}
            onChange={(e) => {
              searchFilter(e);
            }}
          />
        </Search>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <Toolbar>
          <div>
            <Typography variant="h6" id="tableTitle">
              Contacts List
            </Typography>
          </div>
          <Spacer />
          <div>
            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            /> */}
          </div>
        </Toolbar>
        <TableContainer>
          {!!status && status === "loading" && (
            <CircularProgress m={2} color="primary" />
          )}
          {!!contacts &&
            !!contacts.status &&
            parseInt(contacts.status) !== 200 && (
              <Alert mb={4} severity="error">
                <AlertTitle>
                  Error: (status-code : {contacts.status})
                </AlertTitle>
                {contacts.message} — <strong>check it out!</strong>
              </Alert>
            )}

          {!!status && status === "error" && (
            <Alert mb={4} severity="error">
              <AlertTitle>Error: </AlertTitle>
              <strong>Something went wrong</strong>
            </Alert>
          )}

          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={!!rows ? rows.length : 0}
            />
            <TableBody>
              {!!rows &&
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.short_code);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        //key={row._id}
                        key={index}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell> */}
                        <TableCell align="left">
                          {!!row && !!row.color ? (
                            <input
                              type="color"
                              value={row.color}
                              disabled={true}
                              style={{ width: "35px" }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Avatar alt={row.name} src={row.photo} />
                        </TableCell>
                        <TableCell scope="row" padding="none">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.company_name}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 8]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function ContactsTable() {
  const dispatch = useDispatch();
  const { data: contacts, status } = useSelector((state) => state.contact);
  React.useEffect(() => {
    dispatch(fetchContacts());
  }, [dispatch]);
  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ padding: "10px" }}>
        <Grid item xs={12}>
          {/* <UnAuthenticated isAuthenticateData={!!contacts ? contacts : []} /> */}
          <EnhancedTable contacts={contacts} status={status} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ContactsTable;
