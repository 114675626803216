import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

const TheamChartConversation = ({ theme, RowData, reportName }) => {
  // console.log(" TheamChartConversation RowData == ", JSON.stringify(RowData));
  // console.log(
  //   " TheamChartConversation reportName == ",
  //   JSON.stringify(reportName)
  // );

  var dateRangeData = !!RowData ? RowData.dateRange : [];

  var data = !!RowData ? RowData.data : [];
  // console.log("data == ", JSON.stringify(data));
  const options = {
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: dateRangeData,
    },
    yaxis: {
      title: {
        text: "Minutes",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Minutes";
        },
      },
    },
    colors: [
      theme.palette.primary.light,
      theme.palette.success.light,
      theme.palette.warning.light,
      theme.palette.error.light,
      theme.palette.info.light,
    ],
  };

  return (
    <Card mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {!!reportName && reportName}
        </Typography>
        {/* <Typography variant="body2" gutterBottom>
          A column chart uses vertical bars to display data and is used to
          compare values across categories.
        </Typography> */}

        <Spacer mb={6} />

        <ChartWrapper>
          <Chart options={options} series={data} type="bar" height="375" />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(TheamChartConversation);
