import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";
// Thunks
// fetch all labels Data
export const fetchAllLabels = createAsyncThunk("labels/fetch", async () => {
  try {
    const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
    const accountID = localStorage.getItem("atua");
    if (!!bearer_token && !!accountID) {
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const res = await axios.get(
        `${basePathConfig.baseURL}/app/accounts/${accountID}/label`,
        config
      );
      return await res.data;
    } else {
      return { message: "Unauthorized", status: 401 };
    }
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

// add labels Data
export const addLabels = createAsyncThunk("labels/add", async (payload) => {
  try {
    const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
    const accountID = localStorage.getItem("atua");
    if (!!bearer_token && !!accountID) {
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const res = await axios.post(
        `${basePathConfig.baseURL}/app/accounts/${accountID}/label`,
        payload,
        config
      );
      return await res.data;
    } else {
      return { message: "Unauthorized", status: 401 };
    }
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

// edit labels Data
export const editLabels = createAsyncThunk("labels/edit", async (payload) => {
  try {
    const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
    const accountID = localStorage.getItem("atua");
    if (!!bearer_token && !!accountID) {
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      var payloadData = {
        title: payload.title,
        description: payload.description,
        color: payload.color,
        show_on_sid: payload.show_on_sid,
      };
      const res = await axios.put(
        `${basePathConfig.baseURL}/app/accounts/${accountID}/label/${payload.id}`,
        payloadData,
        config
      );
      return await res.data;
    } else {
      return { message: "Unauthorized", status: 401 };
    }
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

// delete labels Data
export const deleteLabels = createAsyncThunk(
  "labels/delete",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.delete(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/label/${payload.id}`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);
export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data: [],
  addData: [],
  editData: [],
  deleteData: [],
  status: STATUSES.SUCCESS,
};

export const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLabels.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllLabels.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAllLabels.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(addLabels.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addLabels.fulfilled, (state, action) => {
        state.addData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(addLabels.rejected, (state, action) => {
        state.addData = [];
        state.status = STATUSES.ERROR;
      })
      .addCase(editLabels.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(editLabels.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(editLabels.rejected, (state, action) => {
        state.editData = [];
        state.status = STATUSES.ERROR;
      })
      .addCase(deleteLabels.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteLabels.fulfilled, (state, action) => {
        state.deleteData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(deleteLabels.rejected, (state, action) => {
        state.deleteData = [];
        state.status = STATUSES.ERROR;
      });
  },
});
export const {
  setLabels,
  setStatus,
  setAddData,
  setEditData,
  reset,
  setDeleteData,
} = labelsSlice.actions;
export default labelsSlice.reducer;
