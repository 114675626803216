import React from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Typography,
  List,
  ListItem as ListItemListData,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import { fetchAllAgentStatus } from "../../../../../redux/slices/conversationalActions";
import CheckIcon from "@mui/icons-material/Check";
import { fun_LoginLogout } from "../../../../../redux/slices/socketsSlice";
const ListItemText = styled(MuiListItemText)(spacing);
const ListItemList = styled(ListItemListData)`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(2)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  cursor: pointer;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const FooterBadgeBuzy = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.theme.sidebar.footer.buzy.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const FooterBadgePause = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.theme.sidebar.footer.pause.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const ChatAssignedAgent = ({ socket }) => {
  const { agentStatus } = useSelector((state) => state.conversationalAction);
  const { conversationId, botId, orgId, channelId, contactId, contactData } =
    useSelector((state) => state.chatSettings);
  const { loginLogoutData } = useSelector((state) => state.socketsSlice);
  var [agent, setAgent] = React.useState("");
  var [loginStatus, setLoginStatus] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
  }, []);

  React.useEffect(() => {
    dispatch(fetchAllAgentStatus());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(fun_LoginLogout());
  }, [dispatch]);

  React.useEffect(() => {
    !!agentStatus && setLoginStatus(agentStatus);
  }, [agentStatus]);

  React.useEffect(() => {
    !!loginLogoutData && setLoginStatus(loginLogoutData);
  }, [loginLogoutData]);

  // setLoginLogoutData

  const sendChatAssignMessage = async (agentName, assigneeID) => {
    var msg = `This conversation is assigned to  ${agentName} by ${agent.displayname}`;
    //console.log("sendChatAssignMessage  == ", msg);
    var messagePayload = {
      data: {
        flag: "note",
        channel_id: channelId,
        bot_id: botId,
        org_id: orgId,
        account_id: localStorage.getItem("atua"),
        conversation_id: conversationId,
        agent_id: agent.uid,
        agentName: agent.displayname,
        assign_id: assigneeID,
        contact_id: contactId,
        contact: contactData,
        label_id: "",
        message: [
          {
            content_type: 6,
            content: msg,
            media: {
              media_type: "",
              media_path: "",
            },
            message_type: "text",
            created_at: Date.now(),
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };
    socket.current.emit("chatSentFromApp", messagePayload);
  };
  return (
    <>
      <List>
        {loginStatus.length !== 0 && !!loginStatus.data ? (
          loginStatus.data.map(
            (item, i) =>
              !!item.signinsignout[0] &&
              !!item.signinsignout[0].availability[0] &&
              item.signinsignout[0].availability[0].isAvailable > 0 && (
                <ListItemList
                  key={i}
                  onClick={() => {
                    sendChatAssignMessage(item.displayname, item._id);
                  }}
                >
                  {!!item.signinsignout[0] &&
                    !!item.signinsignout[0].availability[0] &&
                    item.signinsignout[0].availability[0].isAvailable === 1 && (
                      <>
                        <FooterBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={item.displayname}
                            src={item.avatar}
                            style={{
                              marginRight: "5px",
                              width: "30px",
                              height: "30px",
                            }}
                          ></Avatar>
                        </FooterBadge>
                        <ListItemText
                          primary={item.displayname}
                          secondary="Online"
                        />
                      </>
                    )}

                  {!!item.signinsignout[0] &&
                    !!item.signinsignout[0].availability[0] &&
                    item.signinsignout[0].availability[0].isAvailable === 2 && (
                      <>
                        <FooterBadgeBuzy
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={item.displayname}
                            src={item.avatar}
                            style={{
                              marginRight: "5px",
                              width: "30px",
                              height: "30px",
                            }}
                          ></Avatar>
                        </FooterBadgeBuzy>
                        <ListItemText
                          primary={item.displayname}
                          secondary="Buzy"
                        />
                      </>
                    )}

                  {!!item.signinsignout[0] &&
                    !!item.signinsignout[0].availability[0] &&
                    item.signinsignout[0].availability[0].isAvailable === 3 && (
                      <>
                        <FooterBadgePause
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={item.displayname}
                            src={item.avatar}
                            style={{
                              marginRight: "5px",
                              width: "30px",
                              height: "30px",
                            }}
                          ></Avatar>
                        </FooterBadgePause>
                        <ListItemText
                          primary={item.displayname}
                          secondary="Pause"
                        />
                      </>
                    )}

                  {!!agent && agent.uid === item._id && (
                    <>
                      <CheckIcon color="primary" />
                      <ListItemText primary="Assigned to me" />
                    </>
                  )}
                </ListItemList>
              )
          )
        ) : (
          <Typography>
            There are no Previous Conversations associated to this Contacts.
          </Typography>
        )}
      </List>
    </>
  );
};

export default ChatAssignedAgent;
