import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/slices/login";
const UnAuthenticated = ({ isAuthenticateData }) => {
  // for unAuthenticated user access

  const navigate = useNavigate();
  const dispatch = useDispatch();
  var [isAuthenticated, setIsAuthenticated] = React.useState(true);
  React.useEffect(() => {
    !!isAuthenticateData &&
      parseInt(isAuthenticateData.status) === 401 &&
      setIsAuthenticated(false);
  }, [isAuthenticateData]);

  if (!isAuthenticated) {
    dispatch(logout());
    navigate("/");
  }
  // for unAuthenticated user access close
  return <></>;
};

export default UnAuthenticated;
