import React from "react";
import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Paper,
  CircularProgress as MuiCircularProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
const CircularProgress = styled(MuiCircularProgress)(spacing);
const LogoutProcess = ({ openAdd, handleAddClose, handleAddClickOpen }) => {
  return (
    <Card mb={12}>
      <CardContent>
        <Paper mt={1}>
          <Dialog
            open={openAdd.Open}
            onClose={handleAddClose}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                <CircularProgress m={2} /> Please wait for Logout Processing
              </Typography>
            </DialogContent>
          </Dialog>
        </Paper>
      </CardContent>
    </Card>
  );
};

export default LogoutProcess;
