import React from "react";
import styled from "@emotion/styled";
import { Chip as MuiChip, Table, TableBody, TableCell } from "@mui/material";
const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const HederLabelDetails = ({ labeldetails }) => {
  return (
    <>
      {!!labeldetails && (
        <Table>
          <TableBody>
            <TableCell>
              <Chip
                color="primary"
                style={{
                  backgroundColor:
                    !!labeldetails &&
                    !!labeldetails.color &&
                    labeldetails.color,
                }}
                // label={
                //   !!labeldetails && !!labeldetails.title && labeldetails.title
                // }
              />
            </TableCell>
            <TableCell>
              {!!labeldetails && !!labeldetails.title && labeldetails.title}
            </TableCell>
            <TableCell>
              Descriptions:{" "}
              {!!labeldetails &&
                !!labeldetails.description &&
                labeldetails.description}{" "}
            </TableCell>
          </TableBody>
        </Table>
      )}
    </>
  );
};
export default HederLabelDetails;
