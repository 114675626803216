import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import {
  Alert as MuiAlert,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Toolbar,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { settingsMenu } from "../../../../../redux/slices/settings";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { deleteLabels, reset } from "../../../../../redux/slices/labels";
import { isValidToken } from "../../../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../../redux/slices/login";
import { useNavigate } from "react-router-dom";
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Ptypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
`;

const Spacer = styled.div`
  flex: 1 1 20%;
`;
const timeOut = (time) => new Promise((res) => setTimeout(res, time));

var initialValues = {
  id: "",
};

const validationSchema = Yup.object().shape({});

const DeleteCannedResponses = ({ dispatch, data }) => {
  const { deleteData } = useSelector((state) => state.cannedResponses);
  //console.log("deleteData == ", JSON.stringify(deleteData));
  const accessToken = window.localStorage.getItem("accessToken");
  const navigate = useNavigate();
  initialValues = {
    id: data.id,
  };
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      } else {
        await timeOut(1500);
        await dispatch(deleteLabels(values));
        setStatus({ sent: true });
        setSubmitting(false);
        await timeOut(1500);
        dispatch(
          settingsMenu({
            list: "labels",
            listData: [],
          })
        );
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleBack = (row) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(
        settingsMenu({
          list: "labels",
          listData: [],
        })
      );
    }
  };
  return (
    <>
      <div>
        <Paper>
          <Toolbar>
            <div>
              <Typography variant="h6" id="tableTitle">
                Delete Labels
              </Typography>
            </div>
            <Spacer />
            <div>
              <Button
                mr={2}
                variant="contained"
                color="primary"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  handleBack();
                }}
              >
                <NavigateBeforeIcon />
                Back
              </Button>
            </div>
          </Toolbar>
        </Paper>
      </div>
      <Divider my={1} />
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {errors && errors.sent && (
                  <Alert severity="error" my={3}>
                    {errors.message}
                  </Alert>
                )}
                {!!deleteData &&
                  (!!deleteData.status &&
                  parseInt(deleteData.status) !== 201 ? (
                    <Alert severity="error" my={3}>
                      {!!deleteData && deleteData.message}
                    </Alert>
                  ) : (
                    status &&
                    status.sent && (
                      <Alert severity="success" my={3}>
                        Your data has been Deleted successfully!
                      </Alert>
                    )
                  ))}
                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <CardContent>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{
                                  fontWeight: "bold",
                                  width: "150px",
                                  color: "#376FD0",
                                }}
                              >
                                Color
                              </TableCell>
                              <TableCell align="left">
                                <span
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    display: "block",
                                    padding: "5px",
                                    backgroundColor: data.color,
                                  }}
                                ></span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{
                                  fontWeight: "bold",
                                  width: "150px",
                                  color: "#376FD0",
                                }}
                              >
                                Title
                              </TableCell>
                              <TableCell align="left">{data.title}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{
                                  fontWeight: "bold",
                                  width: "150px",
                                  color: "#376FD0",
                                }}
                              >
                                Description
                              </TableCell>
                              <TableCell align="left">
                                {data.description}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Card>
                    <Alert mb={4} severity="info">
                      Do you want to delete this record?
                    </Alert>
                    <TextField
                      name="id"
                      value={values.id}
                      error={Boolean(touched.id && errors.id)}
                      helperText={touched.id && errors.id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="hidden"
                      my={2}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                    >
                      Yes
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      style={{ marginLeft: "5px" }}
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      No
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </div>
    </>
  );
};

const LabelsDelete = ({ data }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(reset());
  }, [dispatch]);
  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <LocalOfferIcon /> Labels
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                {/* add  */}
                <DeleteCannedResponses dispatch={dispatch} data={data} />
                {/* close add */}
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Labels
                </Typography>
                <Ptypography component="p">
                  Labels help you to categorize conversations and prioritize
                  them. You can assign label to a conversation from the
                  sidepanel.
                </Ptypography>
                <Ptypography component="p">
                  Labels are tied to the account and can be used to create
                  custom workflows in your organization. You can assign custom
                  color to a label, it makes it easier to identify the label.
                  You will be able to display the label on the sidebar to filter
                  the conversations easily.
                </Ptypography>
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default LabelsDelete;
