import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { Grid } from "@mui/material";
import LeftSettingPannel from "./leftPannel/LeftSettingPannel";
import RightSettingPannel from "./rightPannel/RightSettingPannel";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isValidToken } from "../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../redux/slices/login";

// import RightChatPannel from "./rightPannel/RightChatPannel";
const TopSpacing = styled("div")(spacing);
const Settings = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    !isValidToken(accessToken) && dispatch(fetchLogOut());
    !isValidToken(accessToken) && dispatch(fetchLoginLogOut_Socket());
    !isValidToken(accessToken) && dispatch(logout());
    !isValidToken(accessToken) && navigate("/");
  }, [accessToken, dispatch, navigate]);
  return (
    <>
      <Helmet title="Settings" />
      <Grid container>
        <Grid item xs={12} lg={2}>
          <TopSpacing
            gutterBottom
            style={{
              backgroundColor: "#E3EEFB",
              height: "92vh",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <LeftSettingPannel />
          </TopSpacing>
        </Grid>
        <Grid item xs={12} lg={10}>
          <TopSpacing
            gutterBottom
            style={{ backgroundColor: "#F9F9F9", height: "92vh" }}
          >
            <RightSettingPannel />
          </TopSpacing>
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
