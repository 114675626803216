import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";
// Thunks
// fetch all company Attributes Data
export const fetchAllcompanyAttributes = createAsyncThunk(
  "companyAttributesSlice/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.get(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/custom-attribute/mode/0`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const addCompanyAttributes = createAsyncThunk(
  "companyAttributes/add",
  async (payloadData) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/custom-attribute`,
          payloadData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const editCompanyAttributes = createAsyncThunk(
  "companyAttributes/edit",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const payloadData = {
          display_name: payload.display_name,
          key: payload.key,
          display_type: payload.display_type,
          mode: 0,
          description: payload.description,
        };
        const res = await axios.put(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/custom-attribute/${payload.id}`,
          payloadData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const deleteCompanyAttributes = createAsyncThunk(
  "companyAttributes/delete",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };

        const res = await axios.delete(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/custom-attribute/${payload.id}`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);
export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data: [],
  addData: [],
  status: STATUSES.SUCCESS,
};

export const companyAttributesSlice = createSlice({
  name: "companyAttributes",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllcompanyAttributes.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllcompanyAttributes.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAllcompanyAttributes.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(addCompanyAttributes.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addCompanyAttributes.fulfilled, (state, action) => {
        state.addData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(addCompanyAttributes.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(editCompanyAttributes.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(editCompanyAttributes.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(editCompanyAttributes.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.editData = [];
      })
      .addCase(deleteCompanyAttributes.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteCompanyAttributes.fulfilled, (state, action) => {
        state.deleteData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(deleteCompanyAttributes.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.deleteData = [];
      });
  },
});
export const {
  setCompanyAttributes,
  setStatus,
  reset,
  setEditData,
  setDeleteData,
} = companyAttributesSlice.actions;
export default companyAttributesSlice.reducer;
