import React from "react";
import { Typography, Box } from "@mui/material";

const BotFormInput = ({ data }) => {
  console.log("data == " + JSON.stringify(data));

  return (
    <>
      <Box p={2}>
        test
        {data?.data &&
          Object.entries(data.data).map(([key, value], index) => (
            <Typography
              key={index}
              variant="subtitle3"
              sx={{ whiteSpace: "pre-line" }}
            >
              {`${key}: ${value}`} {"\n"}
            </Typography>
          ))}
      </Box>
    </>
  );
};

export default BotFormInput;
