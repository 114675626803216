import React from "react";
import styled from "@emotion/styled";

import { Badge, Grid, Avatar } from "@mui/material";

import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import {
  fetchLoginLogOut_Socket,
  fetchLogOut,
  logout,
} from "../../redux/slices/login";
import CircleIcon from "@mui/icons-material/Circle";
import { ChangePauseState, fetchPause } from "../../redux/slices/pauseUnpause";
import Profile from "./Profile";
import { fetchTodayschat } from "../../redux/slices/socketsSlice";
import LogoutProcess from "./LogoutProcess";
//import useAuth from "../../hooks/useAuth";

//  background-color: ${(props) =>
// props.theme.sidebar.footer.background} !important;
const Footer = styled.div`
  background-color: #fff !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const CircleIconOnline = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.online.background};
`;

const CircleIconPause = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.pause.background};
`;

// const CircleIconBuzy = styled(CircleIcon)`
//   color: ${(props) => props.theme.sidebar.footer.buzy.background};
// `;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const FooterBadgePause = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) => props.theme.sidebar.footer.pause.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const NotificationsNoneIconIcon = styled(NotificationsNoneIcon)`
  color: ${(props) => props.theme.sidebar.footer.background};
`;

const BadgeIcon = styled(Badge)`
  color: ${(props) => props.theme.sidebar.footer.background};
`;

// const FooterBadgeBuzy = styled(Badge)`
//   margin-right: ${(props) => props.theme.spacing(1)};
//   span {
//     background-color: ${(props) => props.theme.sidebar.footer.buzy.background};
//     border: 1.5px solid ${(props) => props.theme.palette.common.white};
//     height: 12px;
//     width: 12px;
//     border-radius: 50%;
//   }
// `;

const SidebarFooter = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.pauseUnpause);
  const { todaysChat } = useSelector((state) => state.socketsSlice);
  const user = JSON.parse(localStorage.getItem("user"));
  const [current, setCurrent] = React.useState([]);
  const [notification, setNotification] = React.useState(0);
  const [pause, setPause] = React.useState("online");
  const LogOut = async () => {
    // alert("hi");
    handleLogoutAddClickOpen();
    await dispatch(fetchLogOut());
    await dispatch(fetchLoginLogOut_Socket());
    await dispatch(logout());

    navigate("/");
  };

  React.useEffect(() => {
    dispatch(fetchTodayschat());
  }, [dispatch]);

  React.useEffect(() => {
    setCurrent([]);
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          !!item.chatAssign &&
          parseInt(item.is_queue_chat) === 0 &&
          item.chatAssign.map(
            (agentData) =>
              agentData.assignee_id === user.uid &&
              parseInt(item.status) === 0 &&
              setCurrent((current) => [...current, item])
          )
      );
  }, [todaysChat, user.uid]);

  React.useEffect(() => {
    setNotification(0);
    !!current &&
      current.map(
        (item) =>
          !!item &&
          !!item.unReadmessages &&
          item.unReadmessages.length > 0 &&
          setNotification(
            (notification) => notification + item.unReadmessages.length
          )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todaysChat]);

  React.useEffect(() => {
    setNotification(0);
    !!current &&
      current.map(
        (item) =>
          !!item &&
          !!item.unReadmessages &&
          item.unReadmessages.length > 0 &&
          setNotification(
            (notification) => notification + item.unReadmessages.length
          )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todaysChat]);

  // console.log("current == ", JSON.stringify(current));
  // console.log("notification == ", notification);

  React.useEffect(() => {
    dispatch(fetchPause());
  }, [dispatch]);

  React.useEffect(() => {
    var status = !!data && data.isAvailable === 1 ? "online" : "pause";
    setPause(status);
  }, [data]);

  var [users, setAgent] = React.useState("");
  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("user")]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // close menu
  const ChangeStatus = async (paused) => {
    var status = paused === "online" ? "pause" : "online";
    setPause(status);
    await dispatch(ChangePauseState(status));
    await dispatch(fetchLoginLogOut_Socket());
  };

  // show chat Profile
  const [openAdd, setOpenAdd] = React.useState({
    Open: false,
    data: "",
  });
  const handleAddClickOpen = () => {
    setOpenAdd({
      Open: true,
      fileData: "",
    });
  };

  const handleAddClose = () => {
    setOpenAdd({
      Open: false,
      fileData: "",
    });
  };

  // show  logout Process
  const [openLogoutAdd, setOpenLogoutAdd] = React.useState({
    Open: false,
    data: "",
  });
  const handleLogoutAddClickOpen = () => {
    setOpenLogoutAdd({
      Open: true,
      fileData: "",
    });
  };

  // const handleLogoutAddClose = () => {
  //   setOpenAdd({
  //     Open: false,
  //     fileData: "",
  //   });
  // };
  return (
    <Footer {...rest}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Paper>
          <MenuList
            style={{
              backgroundColor: "#F7F9FC",
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            {/* style={{ backgroundColor: "#E7F2FF" }} */}
            <MenuItem
              onClick={() => {
                ChangeStatus(pause);
              }}
            >
              <ListItemIcon>
                {pause === "online" ? (
                  <CircleIconOnline fontSize="small" />
                ) : (
                  <CircleIconPause fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>
                {pause === "online" ? "Online" : "Pause"}
              </ListItemText>
              {/* <ListItemText>Unpause</ListItemText> */}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleAddClickOpen();
              }}
            >
              <ListItemIcon>
                <AccountCircle fontSize="small" style={{ color: "#00518C" }} />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PowerSettingsNew
                  fontSize="small"
                  style={{ color: "#00518C" }}
                />
              </ListItemIcon>
              <ListItemText onClick={() => LogOut()}>Logout</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Menu>
      <Grid container spacing={2} mb={5} ml={1}>
        <Grid
          item
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          //onClick={handleClick}
        >
          {!!notification && parseInt(notification) > 0 ? (
            <BadgeIcon badgeContent={notification} color="primary" mr={2}>
              <NotificationsNoneIconIcon />
            </BadgeIcon>
          ) : (
            <BadgeIcon mr={2} color="primary">
              <NotificationsNoneIconIcon />
            </BadgeIcon>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {pause === "online" ? (
            <FooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                // alt={!!users && users.displayname}
                // src={!!users && users.avatar}
                alt={users.displayname}
                src={users.avatar}
              />
            </FooterBadge>
          ) : (
            <FooterBadgePause
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt={!!users && users.displayname}
                src={!!users && users.avatar}
              />
            </FooterBadgePause>
          )}
        </Grid>
      </Grid>
      <Profile
        openAdd={openAdd}
        handleAddClose={handleAddClose}
        handleAddClickOpen={handleAddClickOpen}
      />
      <LogoutProcess
        openAdd={openLogoutAdd}
        // handleAddClose={handleLogoutAddClose}
        // handleAddClickOpen={handleAddClickOpen}
      />
    </Footer>
  );
};

export default SidebarFooter;
