import React from "react";

import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Box, Grid, Divider as MuiDivider } from "@mui/material";
import ChatSidebar from "../leftPannel/ChatSidebar";
import ChatHeader from "./ChatHeader";
import ContactDetails from "./ContactDetails";
import ChatMessagePannel from "./ChatMessage";
import ChatControlledAccordion from "./ChatControlledAccordion";
import MessageIcon from "@mui/icons-material/Message";
import { useSelector } from "react-redux";
const Divider = styled(MuiDivider)(spacing);

const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const NoConversation = styled(`div`)`
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
  width: 100%;
  height: 95vh;
  text-align: center;
  position: relative;
`;
const NoTitle = styled(`span`)`
  top: 45%;
  left: 20%;
  position: absolute;
`;
const MessageIcons = styled(MessageIcon)`
  font-size: 7rem;
  color: ${(props) => props.theme.sidebar.footer.online.background};
  margin-bottom: 5px;
`;

const RightChatPannel = ({ socket }) => {
  const { conversationId } = useSelector((state) => state.chatSettings);

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Box className="conversations-list">
            <Wrapper>
              <ChatSidebar />
            </Wrapper>
          </Box>
        </Grid>
        {!!conversationId ? (
          <Grid item xs={12} lg={9}>
            <Grid container className="RightMessageCol">
              <Grid item xs={12} lg={8} className="chatMessageBox">
                <Box>
                  <Wrapper className="header-info">
                    <ChatHeader socket={socket} />
                  </Wrapper>
                  <Divider my={2} sx={{ borderBottomWidth: 2 }} />
                  <Wrapper className="messageContent">
                    <ChatMessagePannel socket={socket} />
                  </Wrapper>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box style={{ backgroundColor: "#F9F9F9", height: "92vh" }}>
                  <Wrapper>
                    <ContactDetails socket={socket} />
                  </Wrapper>
                  <Divider my={2} sx={{ borderBottomWidth: 2 }} />
                  <Wrapper style={{ backgroundColor: "#eeeeee" }}>
                    <ChatControlledAccordion socket={socket} />
                  </Wrapper>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} lg={9}>
            <Grid container>
              <NoConversation>
                <NoTitle>
                  <h3>
                    <MessageIcons />
                    Uh oh! Looks like there are no messages.
                  </h3>
                </NoTitle>
              </NoConversation>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RightChatPannel;
