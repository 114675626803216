import { Card, CardContent } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Typography from "@mui/material/Typography";
import ErrorBoundary from "./ErrorBoundary";

const SelectedBotInteractiveButton = ({ data, lastdata }) => {
  // Function to limit words
  const limitWords = (text, limit) => {
    if (!text) return ""; // Return empty if there's no text
    const words = text.split(" "); // Split the text into words
    return words.length > limit
      ? words.slice(0, limit).join(" ") + "..."
      : text; // Limit the words
  };
  return (
    <>
      <Card sx={{ display: "flex" }} variant="outlined">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div">
              {lastdata?.data?.content &&
                lastdata?.data?.content?.bodyText &&
                limitWords(lastdata?.data?.content?.bodyText, 6)}

              {lastdata?.data?.data &&
                lastdata?.data?.data?.title &&
                limitWords(lastdata?.data?.data?.title, 6)}
            </Typography>
          </CardContent>
        </Box>
        {lastdata?.data?.content &&
          lastdata?.data?.content?.mediaUrl &&
          lastdata?.data?.content?.mediaType === "image" && (
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              image={lastdata?.data?.content?.mediaUrl}
              alt="image"
            />
          )}
        {lastdata?.data?.content &&
          lastdata?.data?.content?.mediaUrl &&
          lastdata?.data?.content?.mediaType === "video" && (
            <CardMedia
              component="video"
              controls
              sx={{ width: 50 }}
              src={lastdata?.data?.content?.mediaUrl}
              alt="video"
            />
          )}

        {lastdata?.data?.content &&
          lastdata?.data?.content?.url &&
          lastdata?.data?.content?.mediaType === "video" && (
            <CardMedia
              component="video"
              controls
              sx={{ width: 50 }}
              src={lastdata?.data?.content?.url}
              alt="video"
            />
          )}
        {lastdata?.data?.content &&
          lastdata?.data?.content?.mediaUrl &&
          lastdata?.data?.content?.mediaType === "Audio" && (
            <CardMedia>
              <audio controls>
                <source
                  src={
                    lastdata?.data?.content?.mediaUrl ||
                    "https://via.placeholder.com/355x194"
                  }
                  type="audio/mpeg"
                />
                {lastdata?.data?.content?.mediaAlt ||
                  "Your browser does not support the audio element."}
              </audio>
            </CardMedia>
          )}

        {lastdata?.data?.content &&
          lastdata?.data?.content?.url &&
          lastdata?.data?.content?.mediaType === "Audio" && (
            <CardMedia>
              <audio controls>
                <source
                  src={
                    lastdata?.data?.content?.url ||
                    "https://via.placeholder.com/355x194"
                  }
                  type="audio/mpeg"
                />
                {lastdata?.data?.content?.displayName ||
                  "Your browser does not support the audio element."}
              </audio>
            </CardMedia>
          )}
        {lastdata?.data?.content &&
          lastdata?.data?.content?.mediaType === "document" && (
            <PictureAsPdfIcon sx={{ fontSize: "50px", color: "#B40505" }} />
          )}
      </Card>
      <ErrorBoundary>
        <span>
          {data?.data?.title ?? data?.data ?? data ?? "No Data Available"}
        </span>
      </ErrorBoundary>
    </>
  );
};

export default SelectedBotInteractiveButton;
