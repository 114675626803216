import React from "react";
import styled from "@emotion/styled";
import moment from "moment";
import {
  Alert as MuiAlert,
  // Avatar,
  // Box,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Grid,
  //Typography,
  TextField,
  Divider as MuiDivider,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  sendCurrentChatMail,
  reset,
} from "../../../../../redux/slices/sendMailPreviousChat";
import ChatMessageComponent from "./ContactChatHistory/ChatMessageComponent";

const Divider = styled(MuiDivider)(spacing);
const Alert = styled(MuiAlert)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const ChatContainer = styled(Grid)`
  width: 100%;
  height: calc(68vh - 85px);
`;
const ChatMain = styled(Grid)``;

const ChatMessages = styled.div`
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.6);
  flex-direction: "column";
  gap: 2;
  background-image: url(/static/img/samples/fbe41cd97ada760e7b38de1be18301a5.jpg);
  background-size: 10%;
  background-blend-mode: overlay;
  background-attachment: fixed;
  height: calc(-35px + 62vh);
  overflowy: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const Chat = ({ chatData }) => {
  // Helper Datetime function here
  const formatDate = (createdAt) => {
    const date = moment.utc(createdAt).add(5, "hours").add(30, "minutes");

    return date.isSame(moment(), "day")
      ? date.format("hh:mm A")
      : date.format("MMM-DD hh:mm A");
  };
  return (
    <ChatContainer>
      <ChatMain item xs={12} md={12} lg={12}>
        <ChatMessages>
          {!!chatData &&
            chatData.messagesData.length > 0 &&
            chatData.messagesData.map((items, i) =>
              //items.content_type === 0 &&
              items.sender_type === 1 ? (
                <ChatMessageComponent
                  key={i}
                  name={
                    !!chatData &&
                    chatData.contactData[0] &&
                    (chatData.contactData[0].displayname ||
                      chatData.contactData[0].name)
                  }
                  avatar={
                    !!chatData &&
                    chatData.contactData[0] &&
                    (chatData.contactData[0].photo
                      ? chatData.contactData[0].photo
                      : "")
                  }
                  //avatar="/static/img/avatars/avatar-2.jpg"
                  message={items.content}
                  // time={
                  //   ("0" + new Date(items.created_at).getHours()).slice(-2) +
                  //   ":" +
                  //   ("0" + new Date(items.created_at).getMinutes()).slice(-2)
                  // }
                  time={formatDate(items.created_at)}
                  position="left"
                  sender_type={items.sender_type}
                  content_type={!!items && items.content_type === 0 ? 0 : 1}
                  is_media={
                    !!items &&
                    !!items.media_type &&
                    (items.media_type === "" ? 0 : 1)
                  }
                  media_type={
                    !!items &&
                    !!items.media_type &&
                    (items.media_type === "" ? "" : items.media_type)
                  }
                  media_url={
                    !!items &&
                    !!items.media_type &&
                    !!items.media_path &&
                    (items.media_type === "" ? "" : items.media_path)
                  }
                />
              ) : (
                <ChatMessageComponent
                  key={i}
                  name={
                    (!!items &&
                      items.chatassignsData.length !== 0 &&
                      items.chatassignsData[0].accountagentData[0]
                        .displayname) ||
                    items.chatassignsData[0].accountagentData[0].name
                  }
                  avatar={
                    !!items &&
                    items.chatassignsData.length !== 0 &&
                    !!items.chatassignsData[0].accountagentData[0]
                      ? items.chatassignsData[0].accountagentData[0].avatar
                      : ""
                  }
                  message={items.content}
                  // time={
                  //   ("0" + new Date(items.created_at).getHours()).slice(-2) +
                  //   ":" +
                  //   ("0" + new Date(items.created_at).getMinutes()).slice(-2)
                  // }
                  time={formatDate(items.created_at)}
                  position="right"
                  content_type={!!items && items.content_type === 0 ? 0 : 1}
                  is_media={
                    !!items &&
                    !!items.message_type &&
                    (items.message_type === "" ? 0 : 1)
                  }
                  media_type={
                    !!items &&
                    (items.message_type === "" ? "" : items.media_type)
                  }
                  media_url={
                    !!items &&
                    (items.message_type === "" ? "" : items.media_path)
                  }
                />
              )
            )}
        </ChatMessages>
      </ChatMain>
    </ChatContainer>
  );
};

const ChatContactHistory = ({
  openAdd,
  handleAddClose,
  handleAddClickOpen,
  email,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.sendMail_PreviousChat);

  const [sendEmail, setSendEmail] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  React.useEffect(() => {
    setSendEmail(email);
  }, [email]);

  const SendMail = async (conversation_id) => {
    try {
      setIsLoading(true);
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const smtp_id = localStorage.getItem("awlid");
      var payload = {
        conversation_id: openAdd.data._id,
        email: sendEmail,
        timeZone: timeZone,
        smtp_id: smtp_id,
      };
      await dispatch(sendCurrentChatMail(payload));
      setIsLoading(false);
      setIsError(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrors("Error ");
    }
    await timeOut(4000);
    await dispatch(reset());
    setSendEmail("");
  };
  // Helper Datetime function here
  const formatDate = (createdAt) => {
    const date = moment.utc(createdAt).add(5, "hours").add(30, "minutes");
    // return date.isSame(moment(), "day")
    //   ? date.format("hh:mm A")
    //   : date.format("MMM-DD hh:mm A");
    return date.format("hh:mm A");
  };

  return (
    <Dialog
      open={openAdd.Open}
      onClose={handleAddClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="form-dialog-title">
        {!!openAdd.data.contactData &&
          `The previous chat of ` + openAdd.data.contactData[0].name}
        <DialogContentText>
          {!!openAdd.data &&
            ` Date: ` +
              ("0" + new Date(openAdd.data.created_at).getDate()).slice(-2) +
              "/" +
              ("0" + (new Date(openAdd.data.created_at).getMonth() + 1)).slice(
                -2
              ) +
              "/" +
              new Date(openAdd.data.created_at).getFullYear() +
              " " +
              formatDate(openAdd.data.created_at)}
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <Divider />
        <Chat chatData={!!openAdd.data && openAdd.data} />
        <Divider />
        {isLoading === true && <CircularProgress m={2} />}
        {isError === true && (
          <Alert mb={4} variant="filled" severity="error">
            {!!errors && errors}
          </Alert>
        )}
        {!!data && !!data.status && data.status !== 200 && (
          <Alert variant="filled" severity="error">
            {!!data && !!data.message && data.message}
          </Alert>
        )}
        {!!data && data.status === 200 && (
          <Alert variant="filled" severity="success">
            "Your mail has been sent successfuly"
          </Alert>
        )}
        <DialogContentText mt={3} sx={{ color: "#000" }}>
          Send the transcript to email address
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={sendEmail}
          onChange={(e) => setSendEmail(e.target.value)}
          fullWidth
          mt={3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            SendMail(openAdd.data._id);
          }}
          color="primary"
        >
          Send email
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ChatContactHistory;
