import React from "react";
import { Card, CardMedia } from "@mui/material";

const BotDocument = ({ data }) => {
  return (
    <>
      {data?.type === "document" && data?.mimeType.split("/")[0] === "image" && (
        <Card sx={{ maxWidth: 355 }}>
          <CardMedia
            component="img"
            height="194"
            image={data?.fileUrl || "https://via.placeholder.com/355x194"}
            alt="Image"
          />
        </Card>
      )}
      {data?.type === "document" &&
        data?.mimeType.split("/")[0] === "application" && (
          <CardMedia
            component="iframe"
            height="194"
            src={data?.fileUrl || "https://via.placeholder.com/355x194"}
            alt="document"
          />
        )}
      {data?.type === "document" && data?.mimeType.split("/")[0] === "audio" && (
        <CardMedia>
          <audio controls>
            <source
              src={data?.fileUrl || "https://via.placeholder.com/355x194"}
              type="audio/mpeg"
            />
            {"audio"}
          </audio>
        </CardMedia>
      )}
      {data?.type === "document" && data?.mimeType.split("/")[0] === "video" && (
        <CardMedia>
          <video
            controls
            width="100%"
            height="194"
            src={data?.fileUrl || "https://via.placeholder.com/355x194"}
            alt={"video"}
          >
            Your browser does not support the video tag.
          </video>
        </CardMedia>
      )}
    </>
  );
};

export default BotDocument;
