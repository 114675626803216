import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/1spocbeta.svg";
import Csat from "../../components/auth/Csat";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 250px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function CustomerSatisfactionScore() {
  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title="CSAT Rating" />
        <Csat />
      </Wrapper>
    </React.Fragment>
  );
}
export default CustomerSatisfactionScore;
