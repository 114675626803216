import React from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Typography,
  List,
  ListItemIcon,
  ListItem as ListItemListData,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import { fetchAllAgentStatus } from "../../../../../redux/slices/conversationalActions";
import CircleIcon from "@mui/icons-material/Circle";
import { fun_LoginLogout } from "../../../../../redux/slices/socketsSlice";
const ListItemText = styled(MuiListItemText)(spacing);
const ListItemList = styled(ListItemListData)`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(2)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  cursor: pointer;
`;
const CircleIconOnline = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.online.background};
`;
const CircleIconOffline = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.offline.background};
`;
const CircleIconPause = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.pause.background};
`;

const CircleIconBuzy = styled(CircleIcon)`
  color: ${(props) => props.theme.sidebar.footer.buzy.background};
`;

const AgentStatus = () => {
  const { agentStatus } = useSelector((state) => state.conversationalAction);
  const { loginLogoutData } = useSelector((state) => state.socketsSlice);
  var [loginStatus, setLoginStatus] = React.useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchAllAgentStatus());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(fun_LoginLogout());
  }, [dispatch]);

  React.useEffect(() => {
    !!agentStatus && setLoginStatus(agentStatus);
  }, [agentStatus]);

  React.useEffect(() => {
    !!loginLogoutData && setLoginStatus(loginLogoutData);
  }, [loginLogoutData]);

  return (
    <>
      <List
        sx={{
          padding: "0px", // Padding
          maxHeight: "180px", // Maximum height to trigger scrolling
          overflowY: "auto", // Enable vertical scrollbar when content overflows
          borderRadius: "4px", // Border radius
        }}
      >
        {!!loginStatus && !!loginStatus.data ? (
          loginStatus.data.map((item, i) => {
            const availability =
              item?.signinsignout?.[0]?.availability?.[0]?.isAvailable;
            const availabilityText =
              availability === 1
                ? "Online"
                : availability === 2
                ? "Busy"
                : availability === 3
                ? "Pause"
                : availability === 0
                ? "Offline"
                : "Not Available";

            const IconComponent =
              availability === 1
                ? CircleIconOnline
                : availability === 2
                ? CircleIconBuzy
                : availability === 3
                ? CircleIconPause
                : CircleIconOffline;

            return (
              <ListItemList
                key={i}
                sx={{
                  marginBottom: "2px!important",
                  boxShadow: "0px 4px 6px rgba(0, 0, 1, 0.1)", // Slight shadow
                  transition: "all 0.3s ease", // Smooth hover effects
                  padding: "8px", // Adjust padding
                  display: "flex", // Ensure proper layout
                  alignItems: "center", // Align content vertically
                }}
              >
                <Avatar
                  alt={item.displayname}
                  src={item.avatar}
                  sx={{ marginRight: "15px" }} // Consistent spacing
                />
                <ListItemText
                  primary={item.displayname}
                  secondary={availabilityText}
                />
                <ListItemIcon>
                  <IconComponent />
                </ListItemIcon>
              </ListItemList>
            );
          })
        ) : (
          <Typography sx={{ padding: "8px", textAlign: "center" }}>
            There are no Previous Conversations associated with this Contact.
          </Typography>
        )}
      </List>
    </>
  );
};

export default AgentStatus;
