import React from "react";
//import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";

const BotInteractiveButton = ({ data }) => {
  return (
    <>
      <Card sx={{ maxWidth: 355 }}>
        {data?.content?.headerText && (
          <CardHeader subheader={data?.content?.headerText} />
        )}
        {data?.content?.mediaUrl && data?.content?.mediaType === "image" && (
          <CardMedia
            component="img"
            height="194"
            image={
              data?.content?.mediaUrl || "https://via.placeholder.com/355x194"
            }
            alt={data?.content?.mediaAlt || "Image"}
          />
        )}
        {data?.content?.url && data?.content?.mediaType === "image" && (
          <CardMedia
            component="img"
            height="194"
            image={data?.content?.url || "https://via.placeholder.com/355x194"}
            alt={data?.content?.displayName || "Image"}
          />
        )}
        {data?.content?.mediaUrl && data?.content?.mediaType === "document" && (
          <CardMedia
            component="iframe"
            height="194"
            src={
              data?.content?.mediaUrl || "https://via.placeholder.com/355x194"
            }
            alt={data?.content?.mediaAlt || "document"}
          />
        )}
        {data?.content?.url && data?.content?.mediaType === "document" && (
          <CardMedia
            component="iframe"
            height="194"
            src={data?.content?.url || "https://via.placeholder.com/355x194"}
            alt={data?.content?.displayName || "document"}
          />
        )}
        {data?.content?.mediaUrl && data?.content?.mediaType === "audio" && (
          <CardMedia>
            <audio controls>
              <source
                src={
                  data?.content?.mediaUrl ||
                  "https://via.placeholder.com/355x194"
                }
                type="audio/mpeg"
              />
              {data?.content?.mediaAlt ||
                "Your browser does not support the audio element."}
            </audio>
          </CardMedia>
        )}

        {data?.content?.url && data?.content?.mediaType === "audio" && (
          <CardMedia>
            <audio controls>
              <source
                src={
                  data?.content?.url || "https://via.placeholder.com/355x194"
                }
                type="audio/mpeg"
              />
              {data?.content?.displayName ||
                "Your browser does not support the audio element."}
            </audio>
          </CardMedia>
        )}

        {data?.content?.mediaUrl && data?.content?.mediaType === "video" && (
          <CardMedia>
            <video
              controls
              width="100%"
              height="194"
              src={
                data?.content?.mediaUrl || "https://via.placeholder.com/355x194"
              }
              alt={data?.content?.mediaAlt || "video"}
            >
              Your browser does not support the video tag.
            </video>
          </CardMedia>
        )}

        {data?.content?.url && data?.content?.mediaType === "video" && (
          <CardMedia>
            <video
              controls
              width="100%"
              height="194"
              src={data?.content?.url || "https://via.placeholder.com/355x194"}
              alt={data?.content?.displayName || "video"}
            >
              Your browser does not support the video tag.
            </video>
          </CardMedia>
        )}

        {data?.content?.bodyText && (
          <CardContent>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {data?.content?.bodyText}
            </Typography>
          </CardContent>
        )}
        {data?.content?.footerText && (
          <CardActions disableSpacing>{data?.content?.footerText}</CardActions>
        )}
      </Card>

      {data?.content?.buttons && (
        <Box>
          <ButtonGroup
            orientation="vertical"
            aria-label="Vertical button group"
            variant="contained"
            sx={{ width: "100%" }}
          >
            {data?.content?.buttons &&
              data?.content?.buttons?.map((item, index) => (
                <Button key={index} disabled>
                  {item?.title}
                </Button>
              ))}
          </ButtonGroup>
        </Box>
      )}
    </>
  );
};

export default BotInteractiveButton;
