import { createSlice } from "@reduxjs/toolkit";

export const agentOtherDataSlice = createSlice({
  name: "agentOtherData",
  initialState: {
    agentRole: "638f1029a1c0997b2085c2a2",
  },
  reducers: {
    loginRole: (state, action) => {
      state.agentRole = action.payload.role;
    },
  },
  extraReducers: (builder) => {},
});

export const { loginRole, setAgentRole } = agentOtherDataSlice.actions;
export default agentOtherDataSlice.reducer;
