import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import CSATDetailsByDateTab from "./CSATDetailsByDateTab";
import CSATDetailsTab from "./CSATDetailsTab";

const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const TabTables = ({ csatReportdata }) => {
  const [key, setKey] = React.useState("CSATDetails");
  return (
    <>
      <div className="chatinput-section">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          {/* CSAT Details Tab */}
          <Tab
            eventKey="CSATDetails"
            title={
              !!csatReportdata && !!csatReportdata.csatCount
                ? `CSAT Overview Reports (${csatReportdata.csatCount})`
                : `CSAT Overview Reports (0)`
            }
            style={{
              backgroundColor: "#fff",
              padding: "5px",
            }}
            className="tabClass"
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <CSATDetailsTab csatReportdata={csatReportdata} />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>

          {/* CSAT Details by Date */}
          <Tab
            eventKey="CSATDetailsByDate"
            title={
              !!csatReportdata && !!csatReportdata.csatDayWise_yesCount
                ? `CSAT Date wise (${csatReportdata.csatDayWise_yesCount})`
                : `CSAT Date wise (0)`
            }
            style={{ backgroundColor: "#fff", padding: "5px" }}
          >
            <Wrapper>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                  <CSATDetailsByDateTab csatReportdata={csatReportdata} />
                </Grid>
              </Grid>
            </Wrapper>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default TabTables;
