import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  List,
  ListItemButton,
  ListItemText as MuiListItemText,
  ListItemIcon,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
//import useSound from "use-sound";
import { fetchTodayschat } from "../../../../redux/slices/socketsSlice";
import { chatSettingsList } from "../../../../redux/slices/chatSettings";
import QueueChats from "../rightPannel/conversationalActions/QueueChats";
import QueueConfirmationChats from "../rightPannel/conversationalActions/QueueConfirmationChats";
import { isValidToken } from "../../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../redux/slices/login";
import { useNavigate } from "react-router-dom";
//import notificationSound from "../../audio/notification.mp3";
const ListItemText = styled(MuiListItemText)(spacing);
const LiButton = styled(ListItemButton)`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBoldTop};
`;

const LiIcon = styled(ListItemIcon)`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBoldMediam};
`;

const ConversationsList = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  //const [sendingSPlay] = useSound(notificationSound);
  const user = JSON.parse(localStorage.getItem("user"));
  const role = localStorage.getItem("atur");
  // const { mute } = useSelector((state) => state.chatSettings);
  const { todaysChat } = useSelector((state) => state.socketsSlice);

  const [current, setCurrent] = React.useState([]);
  const [resolved, setResolved] = React.useState([]);
  const [allChat, setAllChat] = React.useState([]);
  const [queueChat, setQueueChat] = React.useState([]);
  const [queueConfirmChat, setQueueConfirmChat] = React.useState([]);
  React.useEffect(() => {
    dispatch(fetchTodayschat());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setCurrent([]);
    setResolved([]);
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          !!item.chatAssign &&
          parseInt(item.is_queue_chat) === 0 &&
          item.chatAssign.map(
            (agentItem) =>
              agentItem.assignee_id === user.uid &&
              (parseInt(item.status) === 0
                ? setCurrent((current) => [...current, item])
                : setResolved((resolved) => [...resolved, item]))
          )
      );
    // mute === true && sendingSPlay();
    //sendingSPlay();
  }, [todaysChat, user.uid]);

  React.useEffect(() => {
    setAllChat([]);
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          !!item.chatAssign &&
          parseInt(item.is_queue_chat) === 0 &&
          item.chatAssign.map(
            (chatItem) =>
              chatItem.assignee_id === user.uid &&
              setAllChat((allChat) => [...allChat, item])
          )
      );
  }, [todaysChat, user.uid]);

  React.useEffect(() => {
    setQueueChat([]);
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          !!item.chatAssign &&
          parseInt(item.is_queue_chat) === 1 &&
          parseInt(item.is_queue_confirm) === 0 &&
          item.chatAssign.map((chatItem) =>
            //chatItem.assignee_id === user.uid &&
            setQueueChat((queueChat) => [...queueChat, item])
          )
      );
  }, [todaysChat, user.uid]);

  React.useEffect(() => {
    setQueueConfirmChat([]);

    !!role && role === "638f1029a1c0997b2085c2a2"
      ? !!todaysChat &&
        !!todaysChat.data &&
        todaysChat.data.map(
          (item) =>
            !!item.chatAssign &&
            parseInt(item.is_queue_chat) === 1 &&
            parseInt(item.is_queue_confirm) === 1 &&
            item.chatAssign.map((chatItem) =>
              // chatItem.assignee_id === user.uid &&
              setQueueConfirmChat((queueChat) => [...queueChat, item])
            )
        )
      : !!todaysChat &&
        !!todaysChat.data &&
        todaysChat.data.map(
          (item) =>
            !!item.chatAssign &&
            parseInt(item.is_queue_chat) === 1 &&
            parseInt(item.is_queue_confirm) === 1 &&
            item.chatAssign.map(
              (chatItem) =>
                chatItem.assignee_id === user.uid &&
                setQueueConfirmChat((queueChat) => [...queueChat, item])
            )
        );
  }, [role, todaysChat, user.uid]);

  // show Queue chat
  const [openAdd, setOpenAdd] = React.useState({
    Open: false,
    data: "",
  });
  const handleAddClickOpen = (item) => {
    setOpenAdd({
      Open: true,
      data: item,
    });
  };

  const handleAddClose = () => {
    setOpenAdd({
      Open: false,
      data: "",
    });
  };

  // show Assiged Queue chat
  const [openAssigedQueueAdd, setOpenAssigedQueueAdd] = React.useState({
    Open: false,
    data: "",
  });
  const handleAddClickOpenAssigedQueue = (item) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      setOpenAssigedQueueAdd({
        Open: true,
        data: item,
      });
    }
  };

  const handleAddCloseAssigedQueue = () => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      setOpenAssigedQueueAdd({
        Open: false,
        data: "",
      });
    }
  };
  const handleSettingList = (Name, dataName) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(chatSettingsList({ name: Name, data: dataName }));
    }
  };
  return (
    <>
      <List component="nav" dense={true}>
        <LiButton
          onClick={() => {
            handleSettingList("Current", current);
          }}
        >
          <ListItemText primary="Current" />
          <LiIcon>{!!current ? current.length : 0}</LiIcon>
        </LiButton>
        <LiButton
          onClick={() => {
            handleSettingList("Resolved", resolved);
          }}
        >
          <ListItemText primary="Resolved" />
          <LiIcon>{!!resolved ? resolved.length : 0}</LiIcon>
        </LiButton>
        <LiButton
          onClick={() => {
            handleSettingList("All", allChat);
          }}
        >
          <ListItemText primary="All" />
          <LiIcon>{!!allChat ? allChat.length : 0}</LiIcon>
        </LiButton>

        <LiButton
          onClick={() => {
            handleAddClickOpen(queueChat);
          }}
        >
          <ListItemText primary="Queue" />
          <LiIcon>{!!queueChat ? queueChat.length : 0}</LiIcon>
        </LiButton>
        <LiButton
          onClick={() => {
            handleAddClickOpenAssigedQueue(queueConfirmChat);
          }}
        >
          <ListItemText primary="Assigned Queue" />
          <LiIcon>{!!queueConfirmChat ? queueConfirmChat.length : 0}</LiIcon>
        </LiButton>
      </List>

      <QueueChats
        openAdd={openAdd}
        handleAddClose={handleAddClose}
        handleAddClickOpen={handleAddClickOpen}
        socket={socket}
      />

      <QueueConfirmationChats
        openAssigedQueueAdd={openAssigedQueueAdd}
        handleAddAssigedQueueClose={handleAddCloseAssigedQueue}
        handleAddAssigedQueueClickOpen={handleAddClickOpenAssigedQueue}
        socket={socket}
      />
    </>
  );
};

export default ConversationsList;
