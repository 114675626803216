import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch report for overview Data
export const fetchLoginLoutReport = createAsyncThunk(
  "loginoutReport/fetch",
  async (PayloadData) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const data = {
          account_id: accountID.toString(),
          day_label: PayloadData.dayID,
          start_date: PayloadData.start_date,
          end_date: PayloadData.end_date,
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/signinsignout-reports`,
          data,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);
export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});
export const reportLoginLogoutSlice = createSlice({
  name: "reportLoginLogout",
  initialState: {
    loginLogoutReportdata: [],
    loginLogoutStatus: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginLoutReport.pending, (state, action) => {
        state.loginLogoutReportdata = [];
        state.loginLogoutStatus = STATUSES.LOADING;
      })
      .addCase(fetchLoginLoutReport.fulfilled, (state, action) => {
        state.loginLogoutReportdata = action.payload;
        state.loginLogoutStatus = STATUSES.SUCCESS;
      })
      .addCase(fetchLoginLoutReport.rejected, (state, action) => {
        state.loginLogoutReportdata = [];
        state.loginLogoutStatus = STATUSES.ERROR;
      });
  },
});

export const { setLoginLogoutReportdata, setLoginLogoutStatus } =
  reportLoginLogoutSlice.actions;
export default reportLoginLogoutSlice.reducer;
