import * as React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import { Grid, IconButton as MuiIconButton } from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid item sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={onDrawerToggle}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
