import React from "react";
import styled from "@emotion/styled";
import {
  Typography,
  Card as MuiCard,
  CardActionArea,
  List,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
  CardContent,
  IconButton,
  InputBase,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import { fetchAllLabels } from "../../../../../redux/slices/labels";
import { Info } from "react-feather";
// import {
//   createOtherMessage,
//   UpdateLabelID,
// } from "../../../../../redux/slices/otherMessages";
// import { fetchCurrentChat } from "../../../../../redux/slices/chatSettings";
const Card = styled(MuiCard)(spacing);
const ListItemButton = styled(MuiListItemButton)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);

const Search = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e7f2ff;
`;

function createData(srno, id, title, color, description) {
  return { srno, id, title, color, description };
}
var rows = [];
var rowsData = [];
const LabelAccordion = ({ socket }) => {
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();
  const { data: labels } = useSelector((state) => state.labels);
  const { conversationId, botId, orgId, channelId, contactId, contactData } =
    useSelector((state) => state.chatSettings);
  var [agent, setAgent] = React.useState("");
  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
  }, []);

  React.useEffect(() => {
    dispatch(fetchAllLabels());
  }, [dispatch]);

  rowsData =
    !!labels && !!labels.data
      ? labels.data.map((item, i) =>
          createData(++i, item._id, item.title, item.color, item.description)
        )
      : [];

  rows =
    search === ""
      ? rowsData
      : rowsData.filter((i) =>
          i.title.toLowerCase().includes(search.toLowerCase())
        );

  const sendLabelMessage = async (label, labelId) => {
    var msg = `${agent.displayname} added ${label} label`;
    var messagePayload = {
      data: {
        flag: "note",
        channel_id: channelId,
        bot_id: botId,
        org_id: orgId,
        account_id: localStorage.getItem("atua"),
        conversation_id: conversationId,
        agent_id: agent.uid,
        agentName: agent.displayname,
        assign_id: "",
        contact_id: contactId,
        contact: contactData,
        label_id: labelId,
        message: [
          {
            content_type: 12,
            content: msg,
            media: {
              media_type: "",
              media_path: "",
            },
            message_type: "text",
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };

    socket.current.emit("chatSentFromApp", messagePayload);
  };
  return (
    <>
      <Card mb={6}>
        <CardActionArea>
          <CardContent>
            <Search>
              <IconButton type="button" sx={{ p: "4px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Search>
            <List component="nav" dense={true}>
              {!!rows && !!rows.length > 0 ? (
                rows.map((item, i) => (
                  <Tooltip title={item.description} key={i} placement="left">
                    <ListItemButton
                      mb="3px"
                      style={{
                        backgroundColor: item.color,
                        color: "white",
                        textTransform: "uppercase",
                      }}
                      // onClick={() => {
                      //   addlabel(
                      //     item.title,
                      //     item.id,
                      //     conversationId,
                      //     contactData._id
                      //   );
                      //   sendLabelMessage(item.title,
                      //     item.id,)
                      // }}
                      onClick={() => {
                        sendLabelMessage(item.title, item.id);
                      }}
                    >
                      <Info />
                      <ListItemText ml="5px" primary={item.title} />
                    </ListItemButton>
                  </Tooltip>
                ))
              ) : (
                <Typography>There are no Labels to assign.</Typography>
              )}
            </List>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default LabelAccordion;
