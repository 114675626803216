import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Box,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LabelsTable from "./labels/LabelsTable";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Ptypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
`;

const Labels = () => {
  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <LocalOfferIcon /> Labels
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <LabelsTable />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Labels
                </Typography>
                <Ptypography component="p">
                  Labels help you to categorize conversations and prioritize
                  them. You can assign label to a conversation from the
                  sidepanel.
                </Ptypography>
                <Ptypography component="p">
                  Labels are tied to the account and can be used to create
                  custom workflows in your organization. You can assign custom
                  color to a label, it makes it easier to identify the label.
                  You will be able to display the label on the sidebar to filter
                  the conversations easily.
                </Ptypography>
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default Labels;
