import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch all labels Data
export const fetchContacts = createAsyncThunk("account/fetch", async () => {
  try {
    const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
    const accountID = localStorage.getItem("atua");
    if (!!bearer_token && !!accountID) {
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const res = await axios.get(
        `${basePathConfig.baseURL}/app/accounts/${accountID}/contacts`,
        config
      );
      return await res.data;
    } else {
      return { message: "Unauthorized", status: 401 };
    }
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

// fetch all labels Data
export const UpdateContacts = createAsyncThunk(
  "account/update",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        var bodyData = {
          name: payload.name,
          company_name: payload.company_name,
          email: payload.email,
          phone_number: payload.phone_number,
        };
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.put(
          `${basePathConfig.baseURL}/accounts/${accountID}/contact/${payload._id}/contact_update`,
          bodyData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});
var initialState = {
  data: [],
  updateData: [],
  status: STATUSES.SUCCESS,
};
export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContacts.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(UpdateContacts.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(UpdateContacts.fulfilled, (state, action) => {
        state.updateData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(UpdateContacts.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});
export const { setContacts, setStatus, setUpdateData, reset } =
  contactSlice.actions;
export default contactSlice.reducer;
