import React, { useState, useRef } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import CloseIcon from "@mui/icons-material/Close"; // Import close icon
import SendIcon from "@mui/icons-material/Send";
import ReplayIcon from "@mui/icons-material/Replay";

const VoiceNote = ({ openAdd, handleClickClose, channelId, sendVoiceNote }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [convertedAudio, setConvertedAudio] = useState("");
  //const [recordedAudio, setRecordedAudio] = useState({});
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  // useEffect(() => {
  //   setRecordedAudio(convertedAudio);
  // }, [convertedAudio]);

  // const startRecording = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //     mediaRecorderRef.current = new MediaRecorder(stream);

  //     mediaRecorderRef.current.ondataavailable = (event) => {
  //       console.log("1");
  //       audioChunksRef.current.push(event.data);
  //     };

  //     mediaRecorderRef.current.onstop = () => {
  //       console.log("2");
  //       const audioBlob = new Blob(audioChunksRef.current, {
  //         type: "audio/mpeg",
  //       });
  //       const audioUrl = URL.createObjectURL(audioBlob);
  //       setAudioURL(audioUrl);
  //       audioChunksRef.current = [];
  //     };

  //     mediaRecorderRef.current.start();
  //     setIsRecording(true);
  //   } catch (error) {
  //     console.error("Error accessing microphone: ", error);
  //   }
  // };

  // const stopRecording = () => {
  //   mediaRecorderRef.current.stop();
  //   setIsRecording(false);

  //   mediaRecorderRef.current.onstop = async () => {
  //     const audioBlob = new Blob(audioChunksRef.current, {
  //       type: "audio/mpeg",
  //     });

  //     // Create a URL for the Blob and update state
  //     const audioUrl = URL.createObjectURL(audioBlob);
  //     setAudioURL(audioUrl); // Set the new audio URL

  //     // Convert Blob to File
  //     const file = new File([audioBlob], `audio${Date.now()}.mp3`, {
  //       type: "audio/mpeg", // Keep the MIME type
  //       lastModified: Date.now(), // You can use a timestamp here
  //     });

  //     //console.log(file); // Log the new file
  //     setConvertedAudio(file); // Update the converted audio state
  //     audioChunksRef.current = []; // Clear the audio chunks
  //   };
  // };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Check for Ogg Audio support in the browser
      const mimeType = MediaRecorder.isTypeSupported("audio/ogg; codecs=opus")
        ? "audio/ogg; codecs=opus"
        : "audio/webm; codecs=opus"; // Fallback to WebM if Ogg is unavailable

      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone: ", error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);

    mediaRecorderRef.current.onstop = async () => {
      const mimeType =
        mediaRecorderRef.current.mimeType || "audio/ogg; codecs=opus";
      const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });

      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioURL(audioUrl);

      const file = new File([audioBlob], `audio${Date.now()}.ogg`, {
        type: mimeType,
        lastModified: Date.now(),
      });

      setConvertedAudio(file);
      audioChunksRef.current = []; // Clear the audio chunks
    };
  };

  const handleClose = () => {
    handleClickClose();
  };
  const handleReset = () => {
    setAudioURL("");
  };

  // console.log("\n audioURL == " + audioURL);

  const handleSend = async () => {
    console.log(audioURL);
    if (audioURL) {
      // Convert to MP3 if necessary
      if (convertedAudio) {
        // Send the MP3 file
        sendVoiceNote(convertedAudio, channelId);
      } else {
        // Fallback or handle the raw audio URL
        sendVoiceNote(audioURL, channelId);
      }
    }
    setAudioURL("");
    handleClose();
  };

  // const handleSend = async () => {
  //   console.log(audioURL);
  //   audioURL && sendVoiceNote(recordedAudio, channelId);
  //   setAudioURL("");
  //   handleClose();
  // };

  return (
    <Dialog
      open={openAdd.Open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          backgroundColor: "#000!important",
          color: "#fff",
          textAlign: "center!important",
        }}
      >
        Voice Note Recorder
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: "#000!important",
          color: "#fff",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          <Card className="voice-note-container">
            <Button
              className={isRecording ? "btn stop" : "btn record"}
              onClick={isRecording ? stopRecording : startRecording}
            >
              {isRecording ? (
                <MicOffIcon sx={{ fontSize: "2.2rem" }} />
              ) : (
                <MicIcon sx={{ fontSize: "2.2rem" }} />
              )}
            </Button>

            {isRecording && (
              <div className="recording-status">
                <div className="blink-icon"></div>
                <span>Recording...</span>
              </div>
            )}

            {audioURL && !isRecording && (
              <div className="audio-container">
                <div className="recordedAudio">Recorded Audio</div>
                <div className="custom-audio-player">
                  <audio
                    controls
                    src={audioURL}
                    className="styled-audio-player"
                  ></audio>
                </div>
                <div className="buttonContainer">
                  <Button
                    variant="contained"
                    onClick={handleReset}
                    className="btnReset"
                    sx={{
                      minWidth: "32px!important",
                      padding: "0px !important",
                    }}
                  >
                    <ReplayIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btnSend"
                    onClick={handleSend}
                  >
                    <SendIcon sx={{ marginRight: "2px" }} /> Send
                  </Button>
                </div>
              </div>
            )}
          </Card>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default VoiceNote;
