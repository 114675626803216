import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch  pause status Data
export const fetchPause = createAsyncThunk("pause/fetch", async () => {
  try {
    const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
    const accountID = localStorage.getItem("atua");
    const user = JSON.parse(localStorage.getItem("user"));
    const agentID = user.uid;
    if (!!bearer_token && !!accountID) {
      var bodyData = {
        account_id: accountID,
        agent_id: agentID,
      };
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const res = await axios.post(
        `${basePathConfig.baseURL}/app/getavailability`,
        bodyData,
        config
      );
      return await res.data.data;
    } else {
      return { message: "Unauthorized", status: 401 };
    }
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

export const ChangePauseState = createAsyncThunk(
  "pause/update",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      const user = JSON.parse(localStorage.getItem("user"));
      const agentID = user.uid;
      if (!!bearer_token && !!accountID) {
        var bodyData = {
          account_id: accountID,
          agent_id: agentID,
          isAvailable: payload === "online" ? 1 : 3,
        };
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.put(
          `${basePathConfig.baseURL}/app/putpause_status`,
          bodyData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

export const pauseSlice = createSlice({
  name: "pauseUnpause",
  initialState: {
    data: [],
    updateData: [],
    status: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPause.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchPause.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchPause.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(ChangePauseState.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(ChangePauseState.fulfilled, (state, action) => {
        state.updateData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(ChangePauseState.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setData, setStatus } = pauseSlice.actions;
export default pauseSlice.reducer;
