import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  // Avatar,
  Paper,
  ImageListItem,
  //Typography,
} from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/1spoc-logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleDomainData } from "../../redux/slices/whitelistsolutionSlice";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 250px;
//   height: 64px;
//   margin-bottom: 32px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

// const BigAvatar = styled(Avatar)`
//   width: 92px;
//   height: 92px;
//   text-align: center;
//   margin: 0 auto ${(props) => props.theme.spacing(5)};
// `;

function SignIn() {
  const dispatch = useDispatch();
  const domainFetchSingleDataViaDomain = useSelector(
    (state) => state.whitelistsolution.domainFetchSingleDataViaDomain
  );

  const domainName = window.location.hostname;
  console.log("\n\n Dashboard domainName  == " + domainName);

  React.useEffect(() => {
    dispatch(fetchSingleDomainData(domainName));
  }, [dispatch, domainName]);

  React.useEffect(() => {
    localStorage.setItem("awlid", domainFetchSingleDataViaDomain?.data?._id);
  }, [domainFetchSingleDataViaDomain, domainName]);

  // console.log(
  //   "domainFetchSingleDataViaDomain == " +
  //     JSON.stringify(domainFetchSingleDataViaDomain)
  // );

  // const [user, setUser] = React.useState([]);

  // React.useEffect(() => {
  //   const items = JSON.parse(localStorage.getItem("user"));
  //   if (items) {
  //     setUser(items);
  //   }
  // }, []);
  return (
    <React.Fragment>
      {/* <Brand /> */}

      {/* <Brand /> */}

      {domainFetchSingleDataViaDomain?.data?.logo_url &&
        domainFetchSingleDataViaDomain?.data?.signIn_logo_url && (
          <ImageListItem>
            <img
              src={
                domainFetchSingleDataViaDomain?.data?.signIn_logo_url ??
                domainFetchSingleDataViaDomain?.data?.logo_url
              }
              alt="logo"
              loading="lazy"
              style={{
                maxWidth: "250px",
                // eslint-disable-next-line no-template-curly-in-string
                marginBottom: "32px",
              }}
            />
          </ImageListItem>
        )}

      <Wrapper>
        <Helmet title="Sign In" />
        {/* {!!user && <BigAvatar alt={user.displayname} src={user.avatar} />}
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome back {!!user && user.displayname}!
        </Typography> */}
        {/* <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography> */}

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
