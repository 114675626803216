import React from "react";
import styled from "@emotion/styled";
import {
  Alert,
  Avatar as MuiAvatar,
  Button as MuiButton,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography as MuiTypography,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { UpdateContacts, reset } from "../../../../../redux/slices/contact";
import { fetchCurrentChat } from "../../../../../redux/slices/chatSettings";
const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Centered = styled.div`
  text-align: center;
`;
const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 80px;
  width: 80px;
`;
const timeOut = (time) => new Promise((res) => setTimeout(res, time));
const EditContactDetails = ({ onClose, selectedValue, open }) => {
  const { updateData } = useSelector((state) => state.contact);
  const [name, setName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [photo, setPhoto] = React.useState("");
  const {
    conversationId,
    //reset_fileData,
  } = useSelector((state) => state.chatSettings);

  React.useEffect(() => {
    !!selectedValue && setName(selectedValue.name);
    !!selectedValue && setCompanyName(selectedValue.company_name);
    !!selectedValue && setEmail(selectedValue.email);
    !!selectedValue && setPhoneNo(selectedValue.phone_number);
    !!selectedValue && setPhoto(selectedValue.photo);
  }, [selectedValue]);
  const handleClose = () => {
    onClose();
  };

  const dispatch = useDispatch();
  const fun_update = async () => {
    var payload = {
      _id: !!selectedValue && selectedValue._id,
      name: name,
      company_name: companyName,
      email: email,
      phone_number: phoneNo,
    };

    await dispatch(UpdateContacts(payload));
    !!conversationId && dispatch(fetchCurrentChat(conversationId));
    await timeOut(3000);
    await dispatch(reset());
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} /> Edit
        Contact Details
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Private info
              </Typography>
              {!!updateData &&
                !!updateData.status &&
                parseInt(updateData.status) !== 201 && (
                  <Alert severity="error" my={3}>
                    {!!updateData && updateData.message}
                  </Alert>
                )}

              {!!updateData &&
                !!updateData.status &&
                parseInt(updateData.status) === 201 && (
                  <Alert severity="success" my={3}>
                    Your data has been updated successfully!
                  </Alert>
                )}
              <Centered>
                <Avatar alt={!!name ? name : ""} src={!!photo ? photo : ""} />
              </Centered>

              <TextField
                id="name"
                label="Name"
                variant="outlined"
                //defaultValue={name}
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                my={2}
              />
              <TextField
                id="companyName"
                label="Company Name"
                variant="outlined"
                //defaultValue={displayname}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                fullWidth
                my={2}
              />
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                //defaultValue={displayname}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                my={2}
              />
              <TextField
                id="phoneNo"
                label="Contact No"
                variant="outlined"
                //defaultValue={displayname}
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                fullWidth
                my={2}
              />
              <Button
                variant="contained"
                color="primary"
                mt={3}
                onClick={fun_update}
              >
                Save changes
              </Button>
            </CardContent>
          </Card>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default EditContactDetails;
