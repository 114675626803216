import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  IconButton as MuiIconButton,
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { fetchLogin, fetchLoginLogOut_Socket } from "../../redux/slices/login";
import { useDispatch, useSelector } from "react-redux";
import { chatConversationIdClear } from "../../redux/slices/chatSettings";
import { loginRole } from "../../redux/slices/agentOtherDataSlice";

const Alert = styled(MuiAlert)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const { data: login } = useSelector((state) => state.login);
  const role = localStorage.getItem("atur");
  const accessToken = localStorage.getItem("accessToken");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  React.useEffect(() => {
    if (!!accessToken) {
      navigate("/chat");
    } else {
      navigate("/");
    }
  }, [accessToken, navigate]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(6, "Password is too short - should be 6 chars minimum.")
          .max(255)
          .matches(
            /[a-zA-Z]/,
            "Password can only contain alphanumeric characters."
          )
          .required("Required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          var palyload = {
            email: values.email,
            password: values.password,
          };
          await dispatch(fetchLogin(palyload));
          await dispatch(fetchLoginLogOut_Socket());
          await dispatch(chatConversationIdClear());
          await dispatch(loginRole({ role: role }));
          //navigate("/chat");
          //await (parseInt(login.status) === 200 && navigate("/chat"));
        } catch (error) {
          const message = error.message || "Something went wrong";
          alert(message);
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{ padding: "10px!important" }}
        >
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {!!login.status && parseInt(login.status) !== 200 && (
            <Alert mt={2} mb={3} severity="warning">
              {login.message}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
            my={2}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign in
          </Button>
          <Button
            component={Link}
            to="/resetpassword"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
