import React from "react";
import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import ChatAssignedAgent from "./ChatAssignedAgent";
import LabelAccordion from "./LabelAccordion";
import JoinCall from "./JoinCall";
const AccordionPannel = styled(`div`)`
  background-color: #e9f0fb;
`;

const ConversationalActions = ({ socket }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <AccordionPannel style={{ backgroundColor: "#e9f0fb" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{ backgroundColor: "#C0D9F5" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Chat Assigned Agent</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChatAssignedAgent socket={socket} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          style={{ backgroundColor: "#C0D9F5" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Join Chat</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <JoinCall socket={socket} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          style={{ backgroundColor: "#C0D9F5" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Labels </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LabelAccordion socket={socket} />
          </AccordionDetails>
        </Accordion>
      </AccordionPannel>
    </>
  );
};

export default ConversationalActions;
