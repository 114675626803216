// export default ReportsList;

import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  List,
  ListItemButton,
  ListItemText as MuiListItemText,
  //ListItemIcon,
} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PeopleIcon from "@mui/icons-material/People";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { reportListMenu } from "../../../../redux/slices/reportList";
import { isValidToken } from "../../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../redux/slices/login";
import { useNavigate } from "react-router-dom";

const ListItemText = styled(MuiListItemText)(spacing);
const LiButton = styled(ListItemButton)`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBoldTop};
`;
//const hover = { background: "#cfe6ff", color: "#1180ff", borderRadius: "8px" };

const ReportsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reportList } = useSelector((state) => state.reportList);
  const [listName, setListName] = React.useState("overview_report");
  const callList = (name) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      setListName(name);
      dispatch(
        reportListMenu({
          list: name,
          listData: [],
        })
      );
    }
  };

  React.useEffect(() => {
    !!reportList && setListName(reportList);
  }, [reportList]);

  return (
    <>
      <List component="nav" dense={true}>
        {/* //overview_report */}
        {!!listName && listName === "overview_report" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("overview_report");
            }}
          >
            <TrendingUpIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Overview" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("overview_report");
            }}
          >
            <TrendingUpIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Overview" />
          </LiButton>
        )}
        {/* //csat_report */}
        {!!listName && listName === "csat_report" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("csat_report");
            }}
          >
            <TagFacesIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="CSAT" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("csat_report");
            }}
          >
            <TagFacesIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="CSAT" />
          </LiButton>
        )}
        {/* //labels */}
        {!!listName && listName === "labels" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("labels");
            }}
          >
            <LocalOfferIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Labels" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("labels");
            }}
          >
            <LocalOfferIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Labels" />
          </LiButton>
        )}

        {/* //agents */}
        {!!listName && listName === "agents" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("agents");
            }}
          >
            <PeopleIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Agents" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("agents");
            }}
          >
            <PeopleIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Agents" />
          </LiButton>
        )}

        {/* //chanels */}
        {!!listName && listName === "chanels" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
              borderRadius: "8px",
            }}
            onClick={() => {
              callList("chanels");
            }}
          >
            <WhatshotIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Chanels" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("chanels");
            }}
          >
            <WhatshotIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Chanels" />
          </LiButton>
        )}
        {!!listName && listName === "signin_signout" ? (
          <LiButton
            style={{
              background: "#cfe6ff",
              color: "#1180ff",
            }}
            onClick={() => {
              callList("signin_signout");
            }}
          >
            <LogoutIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Agent Login-Logout" />
          </LiButton>
        ) : (
          <LiButton
            onClick={() => {
              callList("signin_signout");
            }}
          >
            <LogoutIcon style={{ marginRight: "5px" }} />
            <ListItemText primary="Agent Login-Logout" />
          </LiButton>
        )}
      </List>
    </>
  );
};

export default ReportsList;
