import React from "react";
import { useSelector } from "react-redux";
import AccountSettings from "./AccountSettings";
import Agents from "./Agents";
import CannedResponses from "./CannedResponses";
import CannedResponsesAdd from "./cannedResponses/CannedResponsesAdd";
import CannedResponsesDelete from "./cannedResponses/CannedResponsesDelete";
import CannedResponsesEdit from "./cannedResponses/CannedResponsesEdit";
import CompanyAttributes from "./CompanyAttributes";
import CompanyAttributesAdd from "./companyAttributes/CompanyAttributesAdd";
import CompanyAttributesDelete from "./companyAttributes/CompanyAttributesDelete";
import CompanyAttributesEdit from "./companyAttributes/CompanyAttributesEdit";
import Labels from "./Labels";
import LabelsAdd from "./labels/LabelAdd";
import LabelsDelete from "./labels/LabelDelete";
import LabelsEdit from "./labels/LabelEdit";

const RightSettingPannel = () => {
  const { settingsList, settingsData } = useSelector((state) => state.settings);
  const [list, setList] = React.useState("");
  React.useEffect(() => {
    setList(settingsList);
  }, [settingsList]);
  return (
    <>
      {!!list && list === "canned_responses" && <CannedResponses />}
      {!!list && list === "canned_responses_add" && <CannedResponsesAdd />}
      {!!list && list === "canned_responses_edit" && (
        <CannedResponsesEdit data={settingsData} />
      )}
      {!!list && list === "canned_responses_delete" && (
        <CannedResponsesDelete data={settingsData} />
      )}
      {!!list && list === "labels" && <Labels />}
      {!!list && list === "labels_add" && <LabelsAdd />}
      {!!list && list === "labels_edit" && <LabelsEdit data={settingsData} />}
      {!!list && list === "labels_delete" && (
        <LabelsDelete data={settingsData} />
      )}
      {!!list && list === "companyattributes" && <CompanyAttributes />}
      {!!list && list === "companyattributes_add" && <CompanyAttributesAdd />}
      {!!list && list === "companyattributes_edit" && (
        <CompanyAttributesEdit data={settingsData} />
      )}
      {!!list && list === "companyattributes_delete" && (
        <CompanyAttributesDelete data={settingsData} />
      )}
      {!!list && list === "agents" && <Agents />}
      {!!list && list === "accountsettings" && <AccountSettings />}
    </>
  );
};

export default RightSettingPannel;
