import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch report for Channel Data
export const fetchChannelReport = createAsyncThunk(
  "channelReport/fetch",
  async (PayloadData) => {
    // console.log("PayloadData == ", JSON.stringify(PayloadData));
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const data = {
          account_id: accountID.toString(),
          day_label: PayloadData.dayID,
          channel_id: PayloadData.channel_id,
          start_date: PayloadData.start_date,
          end_date: PayloadData.end_date,
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/channel-reports`,
          data,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);
export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});
export const reportChannelSlice = createSlice({
  name: "reportChannel",
  initialState: {
    channelReportdata: [],
    channelStatus: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChannelReport.pending, (state, action) => {
        state.channelReportdata = [];
        state.channelStatus = STATUSES.LOADING;
      })
      .addCase(fetchChannelReport.fulfilled, (state, action) => {
        state.channelReportdata = action.payload;
        state.channelStatus = STATUSES.SUCCESS;
      })
      .addCase(fetchChannelReport.rejected, (state, action) => {
        state.channelReportdata = [];
        state.channelStatus = STATUSES.ERROR;
      });
  },
});

export const { setChannelReportdata, setChannelStatus } =
  reportChannelSlice.actions;
export default reportChannelSlice.reducer;
