import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
export const resetPasswordFun = createAsyncThunk(
  "resetPasswordFun/post",
  async (payload) => {
    try {
      const res = await axios.post(
        `${basePathConfig.baseURL}/resetpasswordmail`,
        payload
      );

      return await res.data;
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

var initialState = {
  data: [],
  status: STATUSES.SUCCESS,
};

export const resetPasswordSlice = createSlice({
  name: "reset_password",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordFun.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(resetPasswordFun.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(resetPasswordFun.rejected, (state, action) => {
        state.data = [];
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setData, setStatus, reset } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
