import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Box as MuiBox,
  Chip,
  Divider as MuiDivider,
  ListItemButton,
  Typography as MuiTypography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import ConversationsList from "./ConversationsList";

const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
const Box = styled(MuiBox)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 40px;
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  flex-grow: 0;
  margin-bottom: -14px;
  padding-top: 14px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 40px;
  }
`;
const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const LeftChatPannel = ({ socket }) => {
  return (
    <>
      <Wrapper className="LiveAgentVersion">
        <Brand to="/chat">
          <Box ml={1}>
            Live-Agent <BrandChip label="v1.0.0" />
          </Box>
        </Brand>
      </Wrapper>
      <Divider my={6} sx={{ borderBottomWidth: 2 }} />
      <Wrapper className="conversations-counts">
        <Typography variant="h4" gutterBottom>
          Today`s conversations
        </Typography>

        <ConversationsList socket={socket} />
      </Wrapper>
    </>
  );
};

export default LeftChatPannel;
