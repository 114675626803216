import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
//import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import { Divider as MuiDivider } from "@mui/material";

const BotInteractiveList = ({ data }) => {
  return (
    <>
      {data?.content?.bodyText && <span>{data?.content?.bodyText}</span>}
      <Accordion>
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {data?.content?.openButtonText && (
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                textAlign: "center",
                flex: "center",
              }}
            >
              <ListIcon /> {data?.content?.openButtonText}
            </span>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {/* <MuiDivider
            sx={{
              margin: "1px 0", // Adds margin above and below the divider
              borderColor: "primary.main", // Use the primary color for the divider
              height: 2, // Thickness of the divider
            }}
          /> */}
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              //defaultValue="female"
              name="radio-buttons-group"
            >
              {data?.content?.list?.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item?.listTitle}
                  control={<Radio />}
                  label={item?.listTitle}
                  labelPlacement="start"
                  disabled
                />
              ))}
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BotInteractiveList;
