import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  Drawer as MuiDrawer,
  ListItemButton,
  ImageListItem,
} from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/favicon.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { io } from "socket.io-client";
import { basePathConfig } from "../../config";
import { fun_TodaysChat } from "../../redux/slices/socketsSlice";
import { useDispatch } from "react-redux";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;
//background - color: ${ (props) => props.theme.sidebar.header.background };
// color: ${ (props) => props.theme.sidebar.header.color };
// background - color: ${ (props) => props.theme.sidebar.header.background };

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: "#000";
  background-color: "#fff";
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: "#fff";
  }
`;

// const BrandIcon = styled(Logo)`
//   margin-right: ${(props) => props.theme.spacing(2)};
//   color: ${(props) => props.theme.sidebar.header.brand.color};
//   fill: ${(props) => props.theme.sidebar.header.brand.color};
//   width: 55px;
//   height: 55px;
// `;

const Sidebar = ({ user, items, domainFetch, showFooter = true, ...rest }) => {
  const dispatch = useDispatch();
  var socket = React.useRef(null);
  React.useEffect(() => {
    socket.current = io(basePathConfig.socketURL, {
      reconnectionDelayMax: 10000,
    });
    socket.current.on("connect", () => {
      console.info(
        `Successfully connected to socket ${basePathConfig.socketURL}`
      );
    });

    socket.current.on("todaysChat", (data) => {
      //console.log("todaysChat == data == ", JSON.stringify(data));
      dispatch(fun_TodaysChat(data));
    });

    return () => {
      socket.current.disconnect();
    };
  }, [dispatch, socket]);

  return (
    <Drawer variant="permanent" {...rest}>
      {/* <Brand component={NavLink} to="/">
        <BrandIcon />
      </Brand> */}
      <Brand component={NavLink} to="">
        {domainFetch?.data?.logo_url && (
          <ImageListItem component={NavLink} to="/">
            <img src={domainFetch?.data?.logo_url} alt="logo" loading="lazy" />
          </ImageListItem>
        )}
      </Brand>

      <SidebarNav items={items} />
      {!!showFooter && <Footer user={user} />}
    </Drawer>
  );
};

export default Sidebar;
