import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch all days labels Data
export const fetchDaysList = createAsyncThunk("reportDays/fetch", async () => {
  try {
    const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
    const accountID = localStorage.getItem("atua");
    if (!!bearer_token && !!accountID) {
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const res = await axios.get(
        `${basePathConfig.baseURL}/app/daysList`,
        config
      );
      return await res.data.data;
    } else {
      return { message: "Unauthorized", status: 401 };
    }
  } catch (error) {
    var ErrorData = await errorHandler(error);
    return await ErrorData;
  }
});

// fetch all agents  Data
export const fetchAgentsList = createAsyncThunk(
  "reportAgentList/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        var data = {
          account_id: accountID,
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/agentList`,
          data,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// fetch all labels  Data
export const fetchlabelsList = createAsyncThunk(
  "reportLabelList/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        var data = {
          account_id: accountID,
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/labelList`,
          data,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// fetch all Channel  Data
export const fetchchannelList = createAsyncThunk(
  "reportChannelList/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        var data = {
          account_id: accountID,
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/app/channelList`,
          data,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

export const reportOtherFunctionSlice = createSlice({
  name: "reportOtherFunction",
  initialState: {
    dayList: [],
    agentsList: [],
    labelsList: [],
    channelList: [],
    status: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDaysList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchDaysList.fulfilled, (state, action) => {
        state.dayList = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchDaysList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchAgentsList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAgentsList.fulfilled, (state, action) => {
        state.agentsList = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAgentsList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchlabelsList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchlabelsList.fulfilled, (state, action) => {
        state.labelsList = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchlabelsList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(fetchchannelList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchchannelList.fulfilled, (state, action) => {
        state.channelList = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchchannelList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});
export const {
  setDayList,
  setStatus,
  setAgentsList,
  setLabelsList,
  setChannelList,
} = reportOtherFunctionSlice.actions;
export default reportOtherFunctionSlice.reducer;
