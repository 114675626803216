import { useEffect } from "react";
import jwtDecode from "jwt-decode";

let expiredTimer;

const handleTokenExpired = (exp) => {
  // Clear any existing timer
  window.clearTimeout(expiredTimer);

  // Get the current time in milliseconds
  const currentTime = Date.now();

  // Calculate the time left until the token expires
  const timeLeft = exp * 1000 - currentTime;

  // Set a new timer if the token has not yet expired
  if (timeLeft > 0) {
    expiredTimer = window.setTimeout(() => {
      // Redirect to home page when the token expires
      window.location.href = "/";
    }, timeLeft);
  } else {
    // Immediately redirect if the token has already expired
    window.location.href = "/";
  }
};

const useTokenExpirationHandler = () => {
  useEffect(() => {
    const handleTokenFromLocalStorage = () => {
      // Retrieve the token from local storage
      const token = localStorage.getItem("accessToken");

      if (token) {
        try {
          // Decode the token
          const decoded = jwtDecode(token);

          // Check if the token has an exp field
          if (decoded && decoded.exp) {
            // Handle token expiration
            handleTokenExpired(decoded.exp);
          } else {
            // Redirect immediately if the token is invalid
            window.location.href = "/";
          }
        } catch (error) {
          console.error("Failed to decode token:", error);
          // Redirect immediately if decoding fails
          window.location.href = "/";
        }
      } else {
        // Redirect immediately if no token is found
        window.location.href = "/";
      }
    };

    handleTokenFromLocalStorage();
  }, []); // Empty dependency array means this effect runs once when the component mounts
};

export default useTokenExpirationHandler;
