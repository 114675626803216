import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Box,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import AgentsTable from "./agents/AgentsTable";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Ptypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
`;
const Agents = () => {
  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <PeopleIcon /> Agents
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <AgentsTable />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Agents
                </Typography>
                <Ptypography component="p">
                  An Agent is a member of your Customer Support team.
                </Ptypography>
                <Ptypography component="p">
                  Agents will be able to view and reply to messages from your
                  users. The list shows all agents currently in your account.
                </Ptypography>
                <Ptypography component="p">
                  Click on Add Agent to add a new agent. Agent you add will
                  receive an email with a confirmation link to activate their
                  account, after which they can access live-agent and respond to
                  messages.
                </Ptypography>
                <Ptypography component="p">
                  Access to live-agent's features are based on following roles.
                </Ptypography>
                <Ptypography component="p">
                  Agent - Agents with this role can only access inboxes, reports
                  and conversations. They can assign conversations to other
                  agents or themselves and resolve conversations.
                </Ptypography>

                <Ptypography component="p">
                  Administrator - Administrator will have access to all
                  live-agent features enabled for your account, including
                  settings, along with all of a normal agents' privileges.
                </Ptypography>
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default Agents;
