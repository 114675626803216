import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Box,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import ContactsTable from "./ContactsTable";
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const RightContactPannel = () => {
  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <PeopleIcon /> Contacts
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Divider my={2} sx={{ borderBottomWidth: 2 }} />
                <ContactsTable />
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default RightContactPannel;
