import React from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import Rating from "@mui/material/Rating";
// import Rating from "react-rating";
import { useParams } from "react-router-dom";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { fetchCSATRating, postCSATRating } from "../../redux/slices/csatRating";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);
const timeOut = (time) => new Promise((res) => setTimeout(res, time));

function Csat() {
  const dispatch = useDispatch();
  const { data, fetchData } = useSelector((state) => state.csatRating);
  const { csat } = useParams();
  const csatData = csat.split(".");

  var accountID = csatData[1];
  var conversationID = csatData[2];
  var csatID = csatData[3];
  const [ratingValue, setRatingValue] = React.useState(3);
  const [csatStatus, setCsatStatus] = React.useState(0);
  const [csatFeedBack, setCsatFeedBack] = React.useState(0);

  React.useEffect(() => {
    var payload = {
      account_id: accountID,
      csat_id: csatID,
    };
    dispatch(fetchCSATRating(payload));
  }, [accountID, csatID, dispatch]);

  React.useEffect(() => {
    !!fetchData && !!fetchData.data && setCsatStatus(fetchData.data.status);
    !!fetchData && !!fetchData.data && setRatingValue(fetchData.data.rating);
    !!fetchData && !!fetchData.data && setCsatFeedBack(fetchData.data.feedback);
  }, [fetchData, csatStatus]);

  React.useEffect(() => {
    !!data && !!data.data && setCsatStatus(data.data.status);
    !!data && !!data.data && setRatingValue(data.data.rating);
    !!data && !!data.data && setCsatFeedBack(data.data.feedback);
  }, [csatStatus, data]);

  return (
    <>
      <div style={{ padding: "20px" }}>
        {parseInt(csatStatus) === 0 ? (
          <>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              CSAT Feedback
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              Set your rating
            </Typography>
          </>
        ) : (
          ""
        )}
        <Formik
          initialValues={{
            feedback: csatFeedBack,
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            feedback: Yup.string().required("Feedback is required"),
          })}
          onSubmit={async (values, { setErrors, setSubmitting, setStatus }) => {
            try {
              var payload = {
                account_id: accountID,
                conversation_id: conversationID,
                csat_id: csatID,
                rating: ratingValue,
                feedback: csatFeedBack,
                csat_link: csat,
              };
              await timeOut(1500);
              await dispatch(postCSATRating(payload));
              setStatus({ sent: true });
              setSubmitting(false);
            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ sent: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <>
              {parseInt(csatStatus) === 1 ? (
                <h5
                  style={{
                    padding: "25px",
                    textAlign: "center",
                    color: "#3F58AD",
                    textShadow: "1px 1px #C0C0C0",
                  }}
                >
                  Thank you for submitting the survey. Your feedback is
                  valuable.
                </h5>
              ) : (
                <form noValidate onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}

                  {!!data && !!data.status && parseInt(data.status) !== 200 && (
                    <Alert severity="error" my={3}>
                      {!!data && data.message}
                    </Alert>
                  )}

                  {!!data && !!data.status && parseInt(data.status) === 200 && (
                    <Alert severity="success" my={3}>
                      Your data has been submitted successfully!
                    </Alert>
                  )}
                  <Typography
                    component="h1"
                    variant="h4"
                    mt={5}
                    mb={5}
                    align="center"
                    gutterBottom
                  >
                    <Rating
                      name="simple-controlled"
                      value={ratingValue}
                      onChange={(event, newValue) => {
                        setRatingValue(newValue);
                      }}
                      style={{ fontSize: "50px" }}
                      disabled={
                        !!csatStatus && (csatStatus === 1 ? true : false)
                      }
                    />
                  </Typography>

                  <TextField
                    type="text"
                    name="feedback"
                    label="Enter your feedback"
                    value={csatFeedBack}
                    error={Boolean(touched.feedback && errors.feedback)}
                    fullWidth
                    helperText={touched.feedback && errors.feedback}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setCsatFeedBack(e.target.value);
                    }}
                    my={3}
                    multiline={true}
                    disabled={!!csatStatus && (csatStatus === 1 ? true : false)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!!csatStatus && (csatStatus === 1 ? true : false)}
                  >
                    Submit
                  </Button>
                </form>
              )}
            </>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Csat;
