import React from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import * as Icon from "react-feather";
import Check from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import {
  muteUnmute,
  chatConversatioStatus,
  //resolveCurrentChat,
} from "../../../../redux/slices/chatSettings";
import SendMail from "./conversationalActions/SendMail";
import { fetchTodayschat } from "../../../../redux/slices/socketsSlice";
import { fetchBotLogs } from "../../../../redux/slices/botLogsSlice";
import BotLogsHistory from "./conversationalActions/BotHistory/BotLogsHistory";
import ThreePIcon from "@mui/icons-material/ThreeP";

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const LItemIcon = styled(ListItemIcon)`
  cursor: pointer;
`;

const ChatHeader = ({ socket }) => {
  const dispatch = useDispatch();
  const {
    conversationId,
    botId,
    orgId,
    channelId,
    contactId,
    mute,
    contactData,
    conStatus,
    recipientID,
  } = useSelector((state) => state.chatSettings);

  const { todaysChat } = useSelector((state) => state.socketsSlice);
  const user = JSON.parse(localStorage.getItem("user"));
  var [chatAssignee, setChatAssignee] = React.useState("");
  var [chatAssigneeCount, setChatAssigneeCount] = React.useState(0);
  var [agent, setAgent] = React.useState("");
  var [resolveData, setResolveData] = React.useState("resolve");
  var [recipntID, setRecipntID] = React.useState("");

  React.useEffect(() => {
    !!recipientID && setRecipntID(recipientID);
  }, [recipientID]);

  const handleMuteUnmute = (muteData) => {
    dispatch(muteUnmute(muteData));
  };

  React.useEffect(() => {
    dispatch(fetchTodayschat());
  }, [dispatch]);

  React.useEffect(() => {
    setChatAssignee("");
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          item._id === conversationId &&
          dispatch(chatConversatioStatus({ conStatus: item.status }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todaysChat]);

  React.useEffect(() => {
    setChatAssignee("");
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          item._id === conversationId &&
          setChatAssignee(item.chatAssign[0].assignee_id)
      );
  }, [todaysChat, conversationId]);
  //console.log("contactData == ", contactData);
  // check chat asignee Count
  React.useEffect(() => {
    setChatAssigneeCount([]);
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          item._id === conversationId &&
          setChatAssigneeCount(item.chatAssign.length)
      );
  }, [todaysChat, conversationId]);

  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
  }, []);

  // Resolved
  React.useEffect(() => {
    var status = conStatus > 0 ? "resolved" : "resolve";
    setResolveData(status);
  }, [conStatus, conversationId]);

  const sendResolvedMessage = async () => {
    var msg = `This conversation is resolved by ${agent.displayname} `;

    var messagePayload = {
      data: {
        flag: "end",
        channel_id: channelId,
        bot_id: botId,
        org_id: orgId,
        account_id: localStorage.getItem("atua"),
        conversation_id: conversationId,
        agent_id: agent.uid,
        assign_id: "",
        contact_id: contactId,
        contact: contactData,
        message: [
          {
            content_type: 1,
            content: msg,
            media: {
              media_type: "",
              media_path: "",
            },
            message_type: "text",
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };

    socket.current.emit("chatSentFromApp", messagePayload);
  };

  const ChangeResolveStatus = async (resolved) => {
    var status = resolved === "resolve" ? "resolved" : "resolve";
    setResolveData(status);
    await sendResolvedMessage();
  };
  // Resolved Close

  // send Mail
  const [openAdd, setOpenAdd] = React.useState({
    conversation_id: "",
    Open: false,
  });
  const handleAddClickOpen = (conversation_id) => {
    setOpenAdd({
      conversation_id: conversation_id,
      Open: true,
    });
  };

  const handleAddClose = () => {
    setOpenAdd({
      conversation_id: "",
      Open: false,
    });
  };

  // Bot Logs History
  const [openBotAdd, setOpenBotAdd] = React.useState({
    conversation_id: "",
    channelId: "",
    contactData: {},
    Open: false,
  });

  const handleAddBotClickOpen = (
    conversation_id,
    recipient_id,
    bot_Id,
    org_Id,
    channel_Id
  ) => {
    const payload = {
      conversationID: conversation_id,
      recipientID: recipient_id,
      accountID: localStorage.getItem("atua"),
      orgID: org_Id,
      channelID: channel_Id,
      botID: bot_Id,
    };
    dispatch(fetchBotLogs(payload));
    setOpenBotAdd({
      conversation_id: conversation_id,
      channelId: channelId,
      contactData: contactData,
      Open: true,
    });
  };

  const handleAddBotClose = () => {
    setOpenBotAdd({
      conversation_id: "",
      channelId: "",
      contactData: {},
      Open: false,
    });
  };
  return (
    <>
      <ListItem>
        <ListItemIcon>
          <Online
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={!!contactData ? contactData.name : ""}
              src={!!contactData ? contactData.photo : ""}
              sx={{ width: 57, height: 57 }}
            />
          </Online>
        </ListItemIcon>
        <ListItemText
          primary={!!contactData ? contactData.name : ""}
          secondary={!!channelId ? `from ${channelId.split("_")[0]}` : ""}
        />
        {/* <ListItemText primary="0 mins ago" secondary="" /> */}
        {!!mute && mute === true ? (
          <LItemIcon
            onClick={() => {
              handleMuteUnmute(false);
            }}
          >
            <Icon.Volume2 />
          </LItemIcon>
        ) : (
          <LItemIcon
            onClick={() => {
              handleMuteUnmute(true);
            }}
          >
            <Icon.VolumeX />
          </LItemIcon>
        )}

        <LItemIcon>
          <Icon.Mail onClick={() => handleAddClickOpen(conversationId)} />
        </LItemIcon>

        <LItemIcon>
          {/* <Icon.Airplay
          // onClick={() => {
          //   dispatch(chatConversatioStatus({ conStatus: 1 }));
          // }}
          /> */}
          <ThreePIcon
            onClick={() =>
              handleAddBotClickOpen(
                conversationId,
                recipntID,
                botId,
                orgId,
                channelId
              )
            }
          />
        </LItemIcon>

        {!!chatAssigneeCount && chatAssigneeCount > 1 ? (
          !!resolveData && resolveData === "resolved" ? (
            <Button
              variant="contained"
              color="warning"
              style={{ marginRight: "5px" }}
              startIcon={<Check />}
            >
              Resolved
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: "5px" }}
              startIcon={<Check />}
              onClick={() => {
                ChangeResolveStatus(resolveData);
              }}
            >
              Resolve
            </Button>
          )
        ) : (
          !!chatAssignee &&
          chatAssignee === user.uid &&
          (!!resolveData && resolveData === "resolved" ? (
            <Button
              variant="contained"
              color="warning"
              style={{ marginRight: "5px" }}
              startIcon={<Check />}
            >
              Resolved
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: "5px" }}
              startIcon={<Check />}
              onClick={() => {
                ChangeResolveStatus(resolveData);
              }}
            >
              Resolve
            </Button>
          ))
        )}
      </ListItem>

      <SendMail
        openAdd={openAdd}
        handleAddClose={handleAddClose}
        handleAddClickOpen={handleAddClickOpen}
      />
      <BotLogsHistory
        openBotAdd={openBotAdd}
        handleAddBotClose={handleAddBotClose}
        handleAddBotClickOpen={handleAddBotClickOpen}
      />
    </>
  );
};

export default ChatHeader;
