import React from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AlertTitle,
  //Checkbox,
  IconButton,
  InputBase,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  // FormControlLabel,
  // Switch,
  Button,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
//import { useNavigate } from "react-router-dom";
import { fetchAllCannedResponses } from "../../../../../redux/slices/cannedResponses";
import { useDispatch, useSelector } from "react-redux";
//import UnAuthenticated from "../../../UnAuthenticated";
import { settingsMenu } from "../../../../../redux/slices/settings";
import { isValidToken } from "../../../../../utils/jwt";
import { useNavigate } from "react-router-dom";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../../redux/slices/login";
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);

const Search = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e7f2ff;
`;

const Spacer = styled.div`
  flex: 1 1 20%;
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.tertiary.main};
    color: ${(props) => props.theme.palette.common.black};
    margin-right: 10px;
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
function createData(srno, id, short_code, content) {
  return { srno, id, short_code, content };
}
var rows = [];
var rowsData = [];
// var rows = [
//   createData("Cupcake", "305", "3.7"),
//   createData("Donut", "452", "25.0"),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "short_code",
    numeric: false,
    disablePadding: true,
    label: "Short Code",
  },
  { id: "content", numeric: false, disablePadding: true, label: "Content" },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Actions",
  },
];

const EnhancedTableHead = (props) => {
  const {
    //onSelectAllClick,
    order,
    orderBy,
    //numSelected,
    //rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <CustomTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </CustomTableCell> */}
        {headCells.map((headCell) => (
          <CustomTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            //padding={headCell.disablePadding ? "none" : "normal"}
            padding="checkbox"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ cannedResponses, status, dispatch }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [search, setSearch] = React.useState("");
  const accessToken = window.localStorage.getItem("accessToken");
  const navigate = useNavigate();
  React.useEffect(() => {
    setDense(true);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const handleEdit = (row) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(
        settingsMenu({
          list: "canned_responses_edit",
          listData: row,
        })
      );
    }
  };

  const handleDelete = (row) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(
        settingsMenu({
          list: "canned_responses_delete",
          listData: row,
        })
      );
    }
  };

  const handleAdd = () => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(
        settingsMenu({
          list: "canned_responses_add",
          listData: [],
        })
      );
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  let emptyRows = rowsPerPage - (rowsPerPage + 1);
  rowsData =
    !!cannedResponses && !!cannedResponses.data
      ? cannedResponses.data.map((item, i) =>
          createData(++i, item._id, item.short_code, item.content)
        )
      : [];
  rows =
    search === ""
      ? rowsData
      : rowsData.filter(
          (i) =>
            i.short_code.toLowerCase().includes(search.toLowerCase()) ||
            i.content.toLowerCase().includes(search.toLowerCase())
        );

  const searchFilter = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };
  return (
    <div>
      <Paper>
        <Toolbar>
          <div>
            <Typography variant="h6" id="tableTitle">
              Canned Responses List
            </Typography>
          </div>
          <Spacer />
          <div>
            <Button
              mr={2}
              variant="contained"
              color="primary"
              style={{ marginRight: "5px" }}
              onClick={() => {
                handleAdd();
              }}
            >
              Add
              <AddIcon />
            </Button>
            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            /> */}
          </div>
        </Toolbar>
        <Search>
          <IconButton type="button" sx={{ p: "4px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            //onChange={(e) => setSearch(e.target.value)}
            onChange={(e) => {
              searchFilter(e);
            }}
          />
        </Search>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <TableContainer>
          {!!status && status === "loading" && (
            <CircularProgress m={2} color="primary" />
          )}
          {!!cannedResponses &&
            !!cannedResponses.status &&
            parseInt(cannedResponses.status) !== 200 && (
              <Alert mb={4} severity="error">
                <AlertTitle>
                  Error: (status-code : {cannedResponses.status})
                </AlertTitle>
                {cannedResponses.message} — <strong>check it out!</strong>
              </Alert>
            )}

          {!!status && status === "error" && (
            <Alert mb={4} severity="error">
              <AlertTitle>Error: </AlertTitle>
              <strong>Something went wrong</strong>
            </Alert>
          )}

          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!!rows &&
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.short_code);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.short_code)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.srno}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          //padding="none"
                          padding="checkbox"
                        >
                          {row.short_code}
                        </TableCell>
                        <TableCell align="left">{row.content}</TableCell>
                        <TableCell align="left">
                          <EditIcon
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "5px", cursor: "pointer" }}
                            onClick={() => {
                              handleEdit(row);
                            }}
                          />
                          <DeleteIcon
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "5px", cursor: "pointer" }}
                            onClick={() => {
                              handleDelete(row);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 8]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function CannedResponsesTable() {
  const dispatch = useDispatch();

  const { data: cannedResponses, status } = useSelector(
    (state) => state.cannedResponses
  );
  React.useEffect(() => {
    dispatch(fetchAllCannedResponses());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ padding: "10px" }}>
        <Grid item xs={12}>
          {/* <UnAuthenticated
            isAuthenticateData={!!cannedResponses ? cannedResponses : []}
          /> */}
          <EnhancedTable
            cannedResponses={cannedResponses}
            status={status}
            dispatch={dispatch}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CannedResponsesTable;
