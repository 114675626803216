import React from "react";

import async from "./components/Async";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Auth components
import SignIn from "./pages/auth/SignIn";
// import SignUp from "./pages/auth/SignUp";
// import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import ChatPannel from "./pages/app/conversations/ChatPannel";
import Contacts from "./pages/app/contacts/Contacts";
import Settings from "./pages/app/settings/Settings";
import ResetPassword from "./pages/auth/ResetPassword";
import PasswordConfirmation from "./pages/auth/PasswordConfirmation";
import CustomerSatisfactionScore from "./pages/auth/CustomerSatisfactionScore";
import Reports from "./pages/app/reports/Reports";
//import Page500 from "./pages/auth/Page500";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/resetpassword",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/passwordconfirmation/:passwordconfirmation",
        element: <PasswordConfirmation />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/csat/:csat",
        element: <CustomerSatisfactionScore />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },
  {
    path: "chat",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ChatPannel />,
      },
    ],
  },
  {
    path: "contact",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Contacts />,
      },
    ],
  },
  {
    path: "settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Settings />,
      },
    ],
  },
  {
    path: "reports",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Reports />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
