import React from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  // FormControlLabel,
  // Switch,
  Button,
} from "@mui/material";

import { spacing } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import AddchartIcon from "@mui/icons-material/Addchart";
import ChartOutgoing from "./charts/ChartOutgoing";
const Alert = styled(MuiAlert)(spacing);
const timeOut = (time) => new Promise((res) => setTimeout(res, time));
const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 50%;
`;

function createData(
  Date,
  channel,
  assignee,
  contact,
  contactNo,
  outgoingMsgCount
) {
  return { Date, channel, assignee, contact, contactNo, outgoingMsgCount };
}

var rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
// date, channel, assignee, contact, contactNo
const headCells = [
  {
    id: "srno",
    numeric: false,
    disablePadding: false,
    label: "Sr.No.",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  { id: "channel", numeric: false, disablePadding: false, label: "Channel" },
  { id: "assignee", numeric: false, disablePadding: false, label: "Assignee" },
  { id: "contact", numeric: false, disablePadding: false, label: "Contact" },
  {
    id: "contactNo",
    numeric: false,
    disablePadding: false,
    label: "Contact No",
  },
  {
    id: "outgoingMsgCount",
    numeric: false,
    disablePadding: false,
    label: "Outgoing Message Count",
  },
];
var HeaderName = [];
headCells.map(
  (item) => !!item.id && item.id !== "srno" && HeaderName.push(item.id)
);
const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"checkbox"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const [showAlert, setShowAlert] = React.useState(false);
  //const { handleChangeDense, dense } = props;
  var [timeStamp, setTimeStamp] = React.useState(Number(new Date()));
  // show chart Conversation
  const [openAdd, setOpenAdd] = React.useState({
    Open: false,
    data: "",
    report: "",
    name: "",
  });
  const handleAddClickOpen = async () => {
    if (!!props.rows && props.rows.length > 0) {
      setOpenAdd({
        Open: true,
        data: props.ChartRows,
        report: "Overview Outgoing Message",
        name: "",
      });
    } else {
      setShowAlert(true);
      await timeOut(1500);
      setShowAlert(false);
    }
  };

  const handleAddClose = () => {
    setOpenAdd({
      Open: false,
      data: "",
      report: "",
      name: "",
    });
  };

  return (
    <>
      <Toolbar>
        <div>
          <Typography variant="h6" id="tableTitle" color="primary">
            Outgoing Messages
          </Typography>
        </div>
        <Spacer />
        <div>
          <Button
            variant="contained"
            color="success"
            style={{ marginRight: "20px" }}
            onClick={() => {
              handleAddClickOpen();
            }}
          >
            <AddchartIcon />
          </Button>
          <Button variant="contained" color="primary" mr={10}>
            <DownloadIcon />
            <CSVLink
              data={props.rows}
              headers={HeaderName}
              className="whiteColor"
              onClick={() => {
                setTimeStamp(Number(new Date()));
              }}
              filename={`OverviewOutgoingMessageReports${timeStamp}.csv`}
            >
              Download Report
            </CSVLink>
          </Button>
          {/* <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
            ml={10}
          /> */}
        </div>
        <ChartOutgoing
          openAdd={openAdd}
          handleAddClose={handleAddClose}
          handleAddClickOpen={handleAddClickOpen}
        />
      </Toolbar>
      {!!showAlert && (
        <Alert severity="error" my={3}>
          No Data Found
        </Alert>
      )}
    </>
  );
};

function EnhancedTable({ overviewReportdata }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  var [conversationData, setConversationData] = React.useState([]);
  React.useEffect(() => {
    !!overviewReportdata && !!overviewReportdata.conversationData
      ? setConversationData(overviewReportdata.conversationData)
      : setConversationData([]);
    setDense(true);
  }, [overviewReportdata]);

  rows = !!conversationData
    ? conversationData.map((item, i) =>
        createData(
          !!item.created_at ? item.created_at.split("T")[0] : "",
          !!item.channel_id ? item.channel_id.split("_")[0] : "",
          item.chatAssign.length > 0 && item.chatAssign[0].agentData.length > 0
            ? item.chatAssign[0].agentData[0].name
            : "",
          item.contactDetails.length > 0 ? item.contactDetails[0].name : "",
          item.contactDetails.length > 0
            ? item.contactDetails[0].phone_number
            : "",
          item.outGoingMsgCount.length > 0 ? item.outGoingMsgCount[0].count : 0
        )
      )
    : [];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  let emptyRows = rowsPerPage - (rowsPerPage + 1);
  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleChangeDense={handleChangeDense}
          dense={dense}
          rows={!!rows ? rows : []}
          ChartRows={
            !!overviewReportdata && !!overviewReportdata.chart_Outgoing
              ? overviewReportdata.chart_Outgoing
              : []
          }
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.date);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // date, channel, assignee, contact, contactNo
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.date)}
                      key={row.date}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="checkbox"
                      >
                        {/* {index + 1} */}
                        {page === 0
                          ? index + 1
                          : index + 1 + rowsPerPage * page}
                      </TableCell>
                      <TableCell align="left">{row.Date} </TableCell>
                      <TableCell align="left">{row.channel}</TableCell>
                      <TableCell align="left">{row.assignee}</TableCell>
                      <TableCell align="left">{row.contact}</TableCell>
                      <TableCell align="left">{row.contactNo}</TableCell>
                      <TableCell align="left">{row.outgoingMsgCount}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function OutgoingMessagesTab({ overviewReportdata }) {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable overviewReportdata={overviewReportdata} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OutgoingMessagesTab;
