import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";

// Thunks
// fetch all labels Data
export const fetchPreviousConversation = createAsyncThunk(
  "previousConversation/fetch",
  async (contactID) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      var payload = {
        account_id: accountID,
        contact_id: contactID,
      };
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/contactPreviousHistory`,
          payload,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// fetch all company Attributes Data
export const fetchAllcompanyAttributes = createAsyncThunk(
  "companyAttribute/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.get(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/custom-attribute/mode/0`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// fetch all company Attributes Data
export const fetchAllAgentStatus = createAsyncThunk(
  "AgentStatus/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.get(
          `${basePathConfig.baseURL}/app/account_agent/account/${accountID}/available_status`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// fetch all company Attributes Data
export const fetchJoinChatStatus = createAsyncThunk(
  "chatStatus/post",
  async (conversationId) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.get(
          `${basePathConfig.baseURL}/app/accounts/${accountID}/conversation/${conversationId}/chat-assign`,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);
// fetch join chat agent details Data
export const fetchJoinChatAgentStatus = createAsyncThunk(
  "chatAgentStatus/fetch",
  async (conversationId) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      var bodyPayload = {
        conversation_id: conversationId,
        account_id: accountID,
      };
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/agent_status_joincall`,
          bodyPayload,
          config
        );
        return await res.data.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});
export const conversationalActionsSlice = createSlice({
  name: "conversationalAction",
  initialState: {
    previousHistory: [],
    companyAttribute: [],
    agentStatus: [],
    joinChatStatus: [],
    joinChatAgentStatus: [],
    status: STATUSES.SUCCESS,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreviousConversation.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchPreviousConversation.fulfilled, (state, action) => {
        state.previousHistory = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchPreviousConversation.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.previousHistory = [];
      })
      .addCase(fetchAllcompanyAttributes.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllcompanyAttributes.fulfilled, (state, action) => {
        state.companyAttribute = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAllcompanyAttributes.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.companyAttribute = [];
      })
      .addCase(fetchAllAgentStatus.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllAgentStatus.fulfilled, (state, action) => {
        state.agentStatus = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAllAgentStatus.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.agentStatus = [];
      })
      .addCase(fetchJoinChatStatus.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchJoinChatStatus.fulfilled, (state, action) => {
        state.joinChatStatus = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchJoinChatStatus.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.joinChatStatus = [];
      })
      .addCase(fetchJoinChatAgentStatus.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchJoinChatAgentStatus.fulfilled, (state, action) => {
        state.joinChatAgentStatus = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchJoinChatAgentStatus.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.joinChatAgentStatus = [];
      });
  },
});

export const {
  setPreviousHistory,
  setStatus,
  setCompanyAttribute,
  setAgentStatus,
  setJoinChatStatus,
  setJoinChatAgentStatus,
} = conversationalActionsSlice.actions;
export default conversationalActionsSlice.reducer;
