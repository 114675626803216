import React from "react";
import BotInteractiveList from "./BotInteractiveList";
import BotInteractiveButton from "./BotInteractiveButton";
//import SelectedBotInteractiveList from "./SelectedBotInteractiveList";
import SelectedBotInteractiveButton from "./SelectedBotInteractiveButton";
import BotFormInput from "./BotFormInput";
import BotDocument from "./BotDocument";

const BotContainer = ({ type, data, lastdata }) => {
  return (
    <>
      {type === "listNode" && <BotInteractiveList data={data} />}
      {type === "mediaNode" && <BotInteractiveButton data={data} />}
      {type === "whatsAppFlowsNode" && <BotInteractiveButton data={data} />}
      {type === "document" && <BotDocument data={data} />}
      {type === "text" && data?.data}
      {type === "documentCognitionNode" && data?.content}
      {type === "flows-input" && <BotFormInput data={data} />}
      {type === "plainMessageTextNode" && data?.content}
      {type === "liveAgentNode" && data?.content}
      {type === "interactive" && (
        <SelectedBotInteractiveButton data={data} lastdata={lastdata} />
      )}
      {type === "buttonNode" && <BotInteractiveButton data={data} />}
      {type !== "listNode" &&
        type !== "text" &&
        type !== "document" &&
        type !== "plainMessageTextNode" &&
        type !== "interactive" &&
        type !== "liveAgentNode" &&
        type !== "buttonNode" &&
        type !== "mediaNode" &&
        type !== "flows-input" &&
        type !== "documentCognitionNode" &&
        type !== "whatsAppFlowsNode" &&
        type}
    </>
  );
};

export default BotContainer;
