import React from "react";
import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import PreviousConversation from "./conversationalActions/PreviousConversation";
import CompanyAttributes from "./conversationalActions/CompanyAttributes";
import AgentStatus from "./conversationalActions/AgentStatus";
import ConversationalActions from "./conversationalActions/ConversationalActions";

const AccordionPannel = styled(`div`)`
  background-color: #eeeeee;
`;
const AccordionDetailsHistory = styled(AccordionDetails)`
  // overflow-y: scroll;
  // height: 30vh;
`;

const ChatControlledAccordion = ({ socket }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <AccordionPannel>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{ backgroundColor: "#eeeeee" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Agents Status</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AgentStatus />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          style={{ backgroundColor: "#eeeeee" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Conversational Actions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ConversationalActions socket={socket} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          style={{ backgroundColor: "#eeeeee" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Company Attributes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CompanyAttributes socket={socket} />
          </AccordionDetails>
        </Accordion>
        {/* <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          style={{ backgroundColor: "#eeeeee" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Contact Attributes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          style={{ backgroundColor: "#eeeeee" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Previous Conversation</Typography>
          </AccordionSummary>
          <AccordionDetailsHistory>
            <PreviousConversation socket={socket} />
          </AccordionDetailsHistory>
        </Accordion>
      </AccordionPannel>
    </>
  );
};

export default ChatControlledAccordion;
