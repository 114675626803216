import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { ImageListItem, Paper, Typography } from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/1spoc-logo.svg";
import PasswordConfirmations from "../../components/auth/PasswordConfirmations";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleDomainData } from "../../redux/slices/whitelistsolutionSlice";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 250px;
//   height: 64px;
//   margin-bottom: 32px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function PasswordConfirmation() {
  const dispatch = useDispatch();
  const domainFetchSingleDataViaDomain = useSelector(
    (state) => state.whitelistsolution.domainFetchSingleDataViaDomain
  );

  const domainName = window.location.hostname;
  React.useEffect(() => {
    dispatch(fetchSingleDomainData(domainName));
  }, [dispatch, domainName]);

  React.useEffect(() => {
    localStorage.setItem("awlid", domainFetchSingleDataViaDomain?.data?._id);
  }, [domainFetchSingleDataViaDomain, domainName]);

  return (
    <React.Fragment>
      {/* <Brand /> */}

      {domainFetchSingleDataViaDomain?.data?.logo_url &&
        domainFetchSingleDataViaDomain?.data?.signIn_logo_url && (
          <ImageListItem>
            <img
              src={
                domainFetchSingleDataViaDomain?.data?.signIn_logo_url ??
                domainFetchSingleDataViaDomain?.data?.logo_url
              }
              alt="logo"
              loading="lazy"
              style={{
                maxWidth: "250px",
                // eslint-disable-next-line no-template-curly-in-string
                marginBottom: "32px",
              }}
            />
          </ImageListItem>
        )}
      <Wrapper>
        <Helmet title="Reset Password" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Password confirmation
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Set your password
        </Typography>

        <PasswordConfirmations />
      </Wrapper>
    </React.Fragment>
  );
}

export default PasswordConfirmation;
